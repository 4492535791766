import React, { useEffect, useState } from "react";
import "./Login.css";
import { Formik } from "formik";
import {
  TextField,
  Link,
  Divider,
  Typography,
  InputAdornment,
  IconButton,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import logo from "../../../assets/formulr-logo.svg";
import LoginFrame from "../../../assets/login-frame.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { loginUser, reset } from "../../../redux/User/UserSlice";
import xero from "../../../assets/xero.svg";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import {
  Visibility,
  VisibilityOff,
  EmailOutlined,
  LockOutlined,
} from "@mui/icons-material";
import { ColorPalette } from "../../../constants/colorPalette";
import { ButtonComponent } from "../../../components/Button/Button";

export default function Login() {
  const { REACT_APP_BASE_API_URL } = process.env;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [submitting, setSubmitting] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const { isSuccess, isError } = useSelector((state) => state.user);

  const location = useLocation();
  const data = location.state;
  const onSubmit = (data) => {
    dispatch(loginUser(data));
    setSubmitting(true);
  };

  useEffect(() => {
    dispatch(reset());
    if (data && data.isLoggedOut) {
      window.history.replaceState({}, "", location.pathname);
    }
    const handleVisibilityChange = () => {
      if (!document.hidden) {
        const accessToken = localStorage.getItem("accessToken");
        if (accessToken) {
          navigate("/");
        }
      }
    };
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [data, dispatch, location.pathname, navigate]);

  useEffect(() => {
    if (isError) {
      dispatch(reset());
      setSubmitting(false);
    }
    if (isSuccess) {
      navigate("/");
    }
    // eslint-disable-next-line
  }, [isSuccess, isError]);

  const xeroConnect = async () => {
    const response = await axios({
      method: "post",
      url: REACT_APP_BASE_API_URL + "/auth/xero-connect",
    });
    window.location.href = response.data.url;
  };
  return (
    <div>
      <Grid container sx={{ height: "100vh" }}>
        <Grid
          item
          xs={5}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Formik
            initialValues={{
              email: localStorage.getItem("email") ?? "",
              password: "",
            }}
            validate={(values) => {
              const errors = {};
              if (!values.email) {
                errors.isEmailEmpty = true;
                errors.email = "Required Field";
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = "Invalid email address";
              }
              if (!values.password) {
                errors.isPassowrdEmpty = true;
                errors.password = "Required Field";
              }
              if (
                values.password.length !== 0 &&
                !/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/i.test(values.password)
              ) {
                errors.password =
                  "Invalid Password Pattern! Your Password must be at least one number and one uppercase and lowercase letter, and at least 8 or more characters";
              }
              return errors;
            }}
            onSubmit={(data, { setSubmitting }) => {
              onSubmit(data, setSubmitting);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit,
              isSubmitting,
            }) => (
              <form
                onSubmit={handleSubmit}
                style={{ width: "100%", maxWidth: "400px" }}
              >
                <div
                  style={{
                    paddingBottom: "5%",
                    alignContent: "center",
                  }}
                >
                  <img
                    maxHeight={"150px"}
                    width={"150px"}
                    src={logo}
                    alt="Logo"
                  />
                </div>

                <Grid container justifyContent="center" spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <EmailOutlined />
                          </InputAdornment>
                        ),
                      }}
                      error={!!errors.email && !!touched.email}
                      id="input-emailAddress"
                      variant="outlined"
                      label="Email"
                      fullWidth
                      size="medium"
                      name="email"
                      onChange={handleChange}
                      value={values.email}
                      helperText={
                        errors.email &&
                        touched.email &&
                        !errors.isEmailEmpty &&
                        errors.email
                      }
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid item xs={12}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        name="password"
                        label="Password"
                        type={showPassword ? "text" : "password"}
                        value={values.password}
                        onChange={handleChange}
                        error={!!errors.password && !!touched.password}
                        helperText={
                          errors.password && touched.password && errors.password
                        }
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <LockOutlined />
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => setShowPassword((show) => !show)}
                                edge="end"
                              >
                                {showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>

                    <Typography
                      id="error-groupRequiredField"
                      variant="caption"
                      color={ColorPalette.danger}
                      pl={1}
                    >
                      {errors.password && !!touched.password && errors.password}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Link
                    id="link-forgotPassword"
                    underline="none"
                    onClick={() => {
                      navigate("../forgot-password");
                    }}
                  >
                    <Typography
                      variant="body2"
                      fontWeight={"bold"}
                      sx={{
                        mt: "5px",
                        color: ColorPalette.primary,
                        "&:hover": {
                          cursor: "pointer",
                        },
                      }}
                    >
                      Forgot your password?
                    </Typography>
                  </Link>
                </Grid>
                <Grid container justifyContent="center" my={4}>
                  <Grid item xs={12}>
                    <Grid container justifyContent="center" spacing={2}>
                      <Grid item xs={12}>
                        <ButtonComponent
                          type={"submit"}
                          title={submitting ? "Loading..." : "Login"}
                          contained={true}
                          fullwidth
                          disabled={submitting}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <Divider sx={{ flexGrow: 1 }} />
                        <Typography
                          variant="body2"
                          sx={{ mx: 2, color: "gray" }}
                        >
                          Or
                        </Typography>
                        <Divider sx={{ flexGrow: 1 }} />
                      </Grid>
                      <Grid item xs={12}>
                        <ButtonComponent
                          fullwidth
                          title={"Sign In With Xero"}
                          startIcon={
                            <img
                              style={{ height: "25px", width: "25px" }}
                              alt="xero"
                              src={xero}
                            />
                          }
                          onClick={xeroConnect}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </Grid>

        {/* Image Part (6/12) */}
        <Grid
          p={2}
          item
          xs={7}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            overflow: "hidden",
          }}
        >
          <img
            src={LoginFrame}
            alt="Login Frame"
            style={{
              maxHeight: "96vh",
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
}

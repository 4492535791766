import * as React from "react";

import { Outlet } from "react-router-dom";
import AppbarCustom from "../components/AppbarCustom/AppbarCustom";
import SidebarCustom from "../components/SidebarCustom/SidebarCustom";
import useMediaQuery from "../hooks/MediaQuery";
export default function CustomLayout() {
  const [open, setOpen] = React.useState(false);
  const isMobile = !useMediaQuery("(min-width: 600px)");

  return (
    <div
      style={{
        overflow: "auto",
        // textAlign: "center",
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        backgroundColor: "rgba(230,232,246, 0.4)",
      }}
    >
      <AppbarCustom setOpen={setOpen} open={open} />
      <div style={{ flex: 1, display: "flex" }}>
        <SidebarCustom open={open} setOpen={setOpen} />
        <main
          style={{
            flex: 1,
            marginTop: "60px",
            marginLeft: isMobile ? "0px" : "64px",
          }}
          onClick={() => {
            setOpen(false);
          }}
        >
          <div
            style={{ margin: `10px 15px 0px ${isMobile ? "10px" : "15px"} ` }}
          >
            <Outlet context={{ open }} />
          </div>
        </main>
      </div>
    </div>
  );
}

import {
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { userProfile } from "../../../redux/User/UserSlice";
import { EditOutlined, Visibility, VisibilityOff } from "@mui/icons-material";
import userProfileIcon from "../../../assets/user-profile.svg";
import ConfirmModal from "../../../components/CustomModal/ConfirmModal";
import { checkDataAvailibility } from "../../../helper/utils";
import Loading from "../../../components/LoadingIndicator/Loading";
import { TabContext, TabPanel } from "@mui/lab";
import { TabContainer } from "../../../components/TabContainer/TabContainer";
import { ButtonComponent } from "../../../components/Button/Button";
import { ColorPalette } from "../../../constants/colorPalette";

const UserProfile = () => {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.user);
  const fileInputRef = useRef();
  const formikRef = useRef(null);
  const { accountingFirmId } = useParams();
  const [value, setValue] = useState("1");
  const [formValues, setFormValues] = useState(null);
  const [passwordValue, setPasswordValue] = useState({
    currentPassword: false,
    newPassword: false,
    confirmPassword: false,
  });
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(users.isFetching);
  const fileSize = 2097152;
  const stateData = checkDataAvailibility(users);
  const [initialValues] = useState({
    firstName: stateData.name?.split(" ")[0],
    lastName: stateData.name?.split(" ")[1],
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
    // file: null,
  });
  const [resetFormValue, setResetFormValue] = useState(null);

  useEffect(() => {
    if (formikRef.current) {
      formikRef.current.setValues({
        firstName: stateData.name?.split(" ")[0],
        lastName: stateData.name?.split(" ")[1],
        currentPassword: "",
        newPassword: "",
        confirmNewPassword: "",
        // file: null,
      });
    }
  }, [stateData]);

  useEffect(() => {
    setIsLoading(users.isFetching);
  }, [users.isFetching]);

  useEffect(() => {
    setResetFormValue(() => () => {
      formikRef.current?.resetForm();
    });
  }, []);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
    if (resetFormValue) {
      resetFormValue();
    }
  };
  const handleSubmit = async () => {
    const formData = new FormData();
    if (formValues) {
      formData.append("profilePic", formValues.file);
      formData.append("id", stateData.userId);
      formData.append("firstName", formValues.firstName);
      formData.append("lastName", formValues.lastName);
      formData.append("currentPassword", formValues.currentPassword);
      formData.append("password", formValues.newPassword);
      formData.append("passwordConfirm", formValues.confirmNewPassword);
      dispatch(userProfile({ formData, accountingFirmId })).finally(() => {
        if (resetFormValue) {
          resetFormValue();
        }
      });

      setConfirmModalOpen(false);
    }
  };

  const tabs = [
    { label: "User Settings", value: "1" },
    { label: "Change Password", value: "2" },
  ];
  return (
    <div style={{ display: "flex", margin: "2%" }}>
      {confirmModalOpen && (
        <ConfirmModal
          message={"Are you sure you want to save the changed inputs?"}
          confirmFunction={handleSubmit}
          declineFunction={() => setConfirmModalOpen(false)}
          modalOpen={confirmModalOpen}
          setModalOpen={setConfirmModalOpen}
        />
      )}
      <Formik
        initialValues={initialValues}
        innerRef={formikRef}
        validate={(values) => {
          const errors = {};

          // File validation
          if (value === "1") {
            if (values.file) {
              if (values.file.size >= fileSize) {
                errors.file = "Please upload an image smaller than 2MB";
              }
            }
            if (!values.firstName) {
              errors.firstName = "Please enter first name";
            }

            if (!values.lastName) {
              errors.lastName = "Please enter last name";
            }
            if (
              values.firstName.length !== 0 &&
              /[^a-zA-Z0-9]/g.test(values.firstName)
            ) {
              errors.firstName = "Special characters not allowed";
            }
            if (
              values.lastName.length !== 0 &&
              /[^a-zA-Z0-9]/g.test(values.lastName)
            ) {
              errors.lastName = "Special characters not allowed";
            }
          }

          if (value === "2") {
            if (!values.currentPassword) {
              errors.currentPassword = "Please enter your current password";
            }
            if (!values.newPassword) {
              errors.newPassword = "Please enter your new Password";
            }
            if (
              values.currentPassword &&
              values.currentPassword === values.newPassword
            ) {
              errors.newPassword =
                "The current password and new password cannot be same";
            }
            if (
              values.currentPassword &&
              !values.newPassword &&
              !values.confirmNewPassword
            ) {
              errors.newPassword = "Please enter your new password";
            }
            if (
              values.newPassword.length !== 0 &&
              !/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/i.test(values.newPassword)
            ) {
              errors.newPassword =
                "Your Password must be at least one number and one uppercase and lowercase letter, and at least 8 or more characters";
            }
            if (!errors.currentPassword) {
              if (
                values.currentPassword &&
                values.newPassword &&
                !values.confirmNewPassword
              ) {
                errors.confirmNewPassword = "Please enter password to confirm";
              } else if (
                values.currentPassword &&
                values.newPassword !== values.confirmNewPassword
              ) {
                errors.confirmNewPassword = "Passwords do not match.";
              }
            }
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setFormValues(values);
          setConfirmModalOpen(true);
          setSubmitting(false);
          setResetFormValue(() => resetForm);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          setFieldValue,
          dirty,
          resetForm,
        }) => (
          <form onSubmit={handleSubmit}>
            {isLoading ? (
              <Loading />
            ) : (
              <>
                <TabContext value={value}>
                  <TabContainer
                    tabs={tabs}
                    value={value}
                    onChange={handleTabChange}
                  />
                  <Grid
                    container
                    sx={{
                      width: "700px",
                      backgroundColor: "#FFFFFF",
                      borderRadius: "10px",
                      padding: "20px",
                      marginTop: "20px",
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                      minHeight: "34vh",
                    }}
                  >
                    <TabPanel value="1" sx={{ width: "100%" }}>
                      <Grid container spacing={3}>
                        <Typography variant="h6" color={ColorPalette.black}>
                          User Profile
                        </Typography>
                        <Grid item xs={12} ml={-2}>
                          <InputLabel
                            className="mb-20"
                            sx={{ color: ColorPalette.text }}
                          >
                            Profile Image
                          </InputLabel>
                          {(values.file && !errors.file) || stateData.imgUrl ? (
                            <img
                              src={
                                values.file
                                  ? URL.createObjectURL(values.file)
                                  : stateData.imgUrl || null
                              }
                              alt="User Profile Pic"
                              width={120}
                              height={120}
                              style={{ borderRadius: "50%" }}
                            />
                          ) : (
                            <img
                              src={userProfileIcon}
                              alt="User Profile Icon"
                              width={120}
                              height={120}
                              style={{ borderRadius: "50%" }}
                            />
                          )}
                          <Grid
                            item
                            sx={{
                              position: "relative",
                              bottom: 40,
                              left: 95,
                            }}
                          >
                            <input
                              type="file"
                              ref={fileInputRef}
                              style={{ display: "none" }}
                              onChange={(e) =>
                                setFieldValue("file", e.currentTarget.files[0])
                              }
                            />
                            <div
                              onClick={() => fileInputRef.current.click()}
                              style={{}}
                            >
                              <EditOutlined
                                fontSize="small"
                                sx={{
                                  cursor: "pointer",
                                  boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                                  borderRadius: "6px",
                                  padding: "3px",
                                  "&:hover": {
                                    cursor: "pointer",
                                  },
                                }}
                              />
                            </div>
                          </Grid>
                          {errors.file && (
                            <Typography sx={{ color: "error.main" }}>
                              {errors.file}
                            </Typography>
                          )}
                        </Grid>

                        <Grid container spacing={3}>
                          <Grid item xs={12} sm={6}>
                            <InputLabel className="mb-10">
                              First Name
                            </InputLabel>
                            <TextField
                              fullWidth
                              name="firstName"
                              size="small"
                              placeholder="First name"
                              value={values.firstName}
                              error={!!errors.firstName && !!touched.firstName}
                              onChange={handleChange}
                            />
                            <Typography variant="body2" color="error" mt={0.5}>
                              {!!errors.firstName &&
                                !!touched.firstName &&
                                errors.firstName}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <InputLabel className="mb-10">Last Name</InputLabel>
                            <TextField
                              fullWidth
                              placeholder="Last name"
                              name="lastName"
                              size="small"
                              value={values.lastName}
                              error={!!errors.lastName && !!touched.lastName}
                              onChange={handleChange}
                            />
                            <Typography variant="body2" color="error" mt={0.5}>
                              {!!errors.lastName &&
                                !!touched.lastName &&
                                errors.lastName}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </TabPanel>

                    <TabPanel value="2" sx={{ width: "100%" }}>
                      <Grid container spacing={3}>
                        <Typography variant="h6" paddingLeft={0.8}>
                          Change Password
                        </Typography>
                        <Grid item xs={12} ml={-2}>
                          <InputLabel className="mb-10">
                            Current Password
                          </InputLabel>
                          <OutlinedInput
                            name="currentPassword"
                            size="small"
                            type={
                              passwordValue.currentPassword
                                ? "text"
                                : "password"
                            }
                            value={values.currentPassword}
                            error={
                              !!errors.currentPassword &&
                              !!touched.currentPassword
                            }
                            onChange={handleChange}
                            sx={{ width: "100%", maxWidth: "300px" }}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={() =>
                                    setPasswordValue((state) => ({
                                      ...state,
                                      currentPassword: !state.currentPassword,
                                    }))
                                  }
                                  edge="end"
                                >
                                  {passwordValue.currentPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                          <Typography variant="caption" color="error">
                            {touched.currentPassword && errors.currentPassword}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} ml={-2}>
                          <InputLabel className="mb-10">
                            New Password
                          </InputLabel>
                          <OutlinedInput
                            name="newPassword"
                            size="small"
                            type={
                              passwordValue.newPassword ? "text" : "password"
                            }
                            value={values.newPassword}
                            error={
                              !!errors.newPassword && !!touched.newPassword
                            }
                            onChange={handleChange}
                            sx={{ width: "100%", maxWidth: "300px" }}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={() =>
                                    setPasswordValue((state) => ({
                                      ...state,
                                      newPassword: !state.newPassword,
                                    }))
                                  }
                                  edge="end"
                                >
                                  {passwordValue.newPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                          <Typography variant="caption" color="error">
                            {touched.newPassword && errors.newPassword}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} ml={-2}>
                          <InputLabel className="mb-10">
                            Confirm New Password
                          </InputLabel>
                          <OutlinedInput
                            name="confirmNewPassword"
                            size="small"
                            type={
                              passwordValue.confirmPassword
                                ? "text"
                                : "password"
                            }
                            value={values.confirmNewPassword}
                            error={
                              !!errors.confirmNewPassword &&
                              !!touched.confirmNewPassword
                            }
                            onChange={handleChange}
                            sx={{ width: "100%", maxWidth: "300px" }}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={() =>
                                    setPasswordValue((state) => ({
                                      ...state,
                                      confirmPassword: !state.confirmPassword,
                                    }))
                                  }
                                  edge="end"
                                >
                                  {passwordValue.confirmPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                          <Typography variant="caption" color="error">
                            {touched.confirmNewPassword &&
                              errors.confirmNewPassword}
                          </Typography>
                        </Grid>
                      </Grid>
                    </TabPanel>

                    <Grid
                      container
                      display="flex"
                      justifyContent="flex-end"
                      alignItems="center"
                      gap={3}
                      mt={3}
                    >
                      <ButtonComponent
                        title={"Reset"}
                        onClick={() => resetForm()}
                        disabled={!dirty}
                      />
                      <ButtonComponent
                        title={"Save"}
                        contained={true}
                        type={"submit"}
                        disabled={!dirty}
                      />
                    </Grid>
                  </Grid>
                </TabContext>
              </>
            )}
          </form>
        )}
      </Formik>
    </div>
  );
};

export default UserProfile;

import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import RefreshXeroModal from "../components/modals/refreshXeroModal/RefreshXeroModal";
import moment from "moment/moment";
import ConfirmModal from "../../../../components/CustomModal/ConfirmModal";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  disconnectXero,
  resetData,
} from "../../../../redux/Setting/SettingSlice";
import Loading from "../../../../components/LoadingIndicator/Loading";
import xero from "../../../../assets/xero.svg";
import { ButtonComponent } from "../../../../components/Button/Button";

const XeroSettingsTab = ({ xeroData, entity }) => {
  const { entityId, accountingFirmId, clientSpaceId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [refreshXeroModal, setRefreshXeroModal] = useState(false);
  const [tenantValue] = useState(xeroData || []);
  const [deleteConfirmModal, setDeletConfirmModal] = useState(false);
  const { isDisconnectSuccess, isDisconnectFetching, isXeroRefreshing } =
    useSelector((state) => state.setting);

  const currentTenantName = tenantValue
    ?.filter((val) => val.tenantId === entity.tenantId)
    .map((val) => val.tenantName);

  useEffect(() => {
    if (isDisconnectSuccess) {
      navigate(
        `/accountingFirm/${accountingFirmId}/clientSpace/${clientSpaceId}/entities`
      );
      dispatch(resetData());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDisconnectSuccess, dispatch]);

  return (
    <div>
      {refreshXeroModal && (
        <RefreshXeroModal
          modalOpen={refreshXeroModal}
          setModalOpen={setRefreshXeroModal}
        />
      )}
      {deleteConfirmModal && (
        <ConfirmModal
          message="Are you sure you want to disconnet xero account? By disconnecting it will delete Chart of Accounts, financial settting, xero data, accounts and journal "
          declineFunction={() => setDeletConfirmModal(false)}
          confirmFunction={() => {
            dispatch(disconnectXero({ entityId, accountingFirmId }));
            setDeletConfirmModal(false);
          }}
          setModalOpen={setDeletConfirmModal}
          modalOpen={deleteConfirmModal}
        />
      )}
      {isDisconnectFetching && <Loading wait={true} />}
      <Paper
        sx={{
          border: 0,
          borderRadius: 2,
          boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
          width: "40vw",
          padding: "20px",
        }}
      >
        <Grid container direction="column" sx={{ marginTop: 2 }} px={2}>
          <Grid container display={"flex"} alignItems={"center"} gap={2} mb={4}>
            <Grid item>
              <Typography variant="h5" fontWeight="bold">
                Xero Status
              </Typography>
            </Grid>

            <Grid item>
              <Typography
                variant="subtitle1"
                fontWeight="medium"
                color={"#898989"}
              >
                (Last Xero Refresh:
                {moment(entity?.xeroLastRefresh).format("YYYY/MM/DD hh:mm a")})
              </Typography>
            </Grid>
          </Grid>

          <Grid container item spacing={2}>
            <Grid item xs={12}>
              <Box
                sx={{
                  p: 3,
                  borderRadius: "10px",
                  border: "1px solid #898989",
                  backgroundColor: "#fff",
                }}
              >
                <Typography
                  variant="subtitle1"
                  fontWeight="medium"
                  color={"#898989"}
                >
                  Connected Tenant: {currentTenantName}
                </Typography>
              </Box>
            </Grid>
          </Grid>

          <Grid container className="mt-10" justifyContent="space-between">
            <Grid item xs={3}>
              <Button
                fullWidth
                variant="outlined"
                sx={{
                  borderRadius: 1,
                }}
                onClick={() => setDeletConfirmModal(true)}
                startIcon={
                  <img
                    style={{ height: "25px", width: "25px" }}
                    alt="xero"
                    src={xero}
                  />
                }
              >
                <Typography variant="subtitle1" className="text-normal">
                  Disconnect From Xero
                </Typography>
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Grid container justifyContent={"flex-end"}>
                <ButtonComponent
                  fullWidth
                  onClick={() => setRefreshXeroModal(true)}
                  disabled={isXeroRefreshing}
                  contained
                  sx={{
                    p: 1,
                    borderRadius: 1,
                  }}
                  title={"Refresh Data"}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default XeroSettingsTab;

import React, { useEffect, useRef, useState } from "react";
import DOMPurify from "dompurify";
import {
  Button,
  Divider,
  Grid,
  IconButton,
  InputBase,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { ColorPalette } from "../../constants/colorPalette";
import { useDispatch, useSelector } from "react-redux";
import { getHistory } from "../../redux/AuditTrail/AuditTrailSlice";
import { useParams } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import Loading from "../LoadingIndicator/Loading";
import CustomModal from "../CustomModal/CustomModal";

const HistoryTableModal = ({
  modalOpen,
  setModalOpen,
  pageType,
  operations,
}) => {
  const dispatch = useDispatch();
  const { accountingFirmId } = useParams();
  const timer = useRef(null);
  const { history, isFetching } = useSelector((state) => state.auditTrail);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const [isDelayLoading, setIsDelayLoading] = useState(false);
  const [filterValue, setFilterValue] = useState("");

  useEffect(() => {
    clearTimeout(timer.current);
    setIsDelayLoading(true);
    timer.current = setTimeout(
      () => {
        dispatch(
          getHistory({
            accountingFirmId,
            page: page + 1,
            pageSize: rowsPerPage,
            pageType: pageType,
            searchQuery,
            filterQuery: filterValue,
          })
        ).finally(() => setIsDelayLoading(false));
      },
      searchQuery === "" ? 0 : 1000
    );
  }, [
    searchQuery,
    rowsPerPage,
    accountingFirmId,
    page,
    dispatch,
    pageType,
    filterValue,
  ]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  return (
    <div>
      <CustomModal
        onClose={handleModalClose}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        overflow="hidden"
      >
        {isFetching && !searchQuery ? (
          <Loading />
        ) : (
          <React.Fragment>
            <Grid container flexDirection={"column"} pb={3}>
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginBottom: "10px",
                }}
              >
                <Typography fontSize={20} fontWeight="bold">
                  History
                </Typography>
                <IconButton
                  size="small"
                  onClick={() => {
                    handleModalClose();
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>

              <Divider sx={{ width: "100%", color: ColorPalette.danger }} />
            </Grid>
            {history?.data?.length === 0 && !searchQuery && !filterValue ? (
              <Grid
                container
                justifyContent={"center"}
                sx={{ paddingTop: "30px" }}
              >
                <Grid item xs={6}>
                  <Grid container justifyContent={"center"}>
                    <Typography variant="h6">No History Found</Typography>
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <React.Fragment>
                <Grid container gap={2}>
                  <Grid
                    item
                    xs={6.5}
                    sx={{
                      display: "flex",
                      width: "20rem",
                      maxWidth: "28rem",
                      justifyContent: "space-between",
                      borderRadius: "0.5rem",
                      border: "1px solid #d1d5db",
                      padding: "0.125rem",
                      paddingLeft: "0.75rem",
                    }}
                  >
                    <IconButton size="small" type="button" aria-label="search">
                      <SearchIcon />
                    </IconButton>
                    <InputBase
                      value={searchQuery}
                      onChange={(e) => {
                        setSearchQuery(e.target.value);
                      }}
                      placeholder="Search"
                      inputProps={{ "aria-label": "search" }}
                      style={{ flex: 1, paddingLeft: 8 }}
                    />
                    {searchQuery && (
                      <Button
                        onClick={() => setSearchQuery("")}
                        sx={{ color: "black" }}
                      >
                        <CloseIcon />
                      </Button>
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={4.5}
                    display={"flex"}
                    alignItems={"center"}
                    gap={1}
                    justifyContent={"center"}
                  >
                    <Typography>Filter:</Typography>
                    <Select
                      id="input-entityType"
                      labelId="entity-type"
                      size="small"
                      value={filterValue}
                      name="entityType"
                      fullWidth
                      onChange={(e) => setFilterValue(e.target.value)}
                    >
                      {operations.map((type, index) => (
                        <MenuItem key={index} value={type}>
                          {type}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                </Grid>
                <div>
                  {isDelayLoading || isFetching ? (
                    <Loading />
                  ) : history?.data?.length > 0 ? (
                    <React.Fragment>
                      <TableContainer
                        style={{
                          minWidth: "80px",
                          maxHeight: "65vh",
                          overflow: "auto",
                        }}
                      >
                        <Table>
                          <TableHead
                            style={{
                              position: "sticky",
                              top: "0",
                              zIndex: 1,
                              backgroundColor: ColorPalette.white,
                            }}
                          >
                            <TableRow>
                              <TableCell className="table-header">
                                <Grid>Description</Grid>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {history?.data?.map((row, index) => (
                              <TableRow
                                // key={row.email + index}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell
                                  width={"30%"}
                                  component="th"
                                  scope="row"
                                >
                                  <Grid
                                    container
                                    display={"flex"}
                                    flexDirection={"column"}
                                  >
                                    <Grid item>
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: DOMPurify.sanitize(
                                            row?.description
                                          ),
                                        }}
                                      />
                                    </Grid>
                                    <Grid
                                      item
                                      float={"right"}
                                      display={"block"}
                                      marginLeft={"auto"}
                                      color={"#808080"}
                                    >
                                      {moment(row.createdAt).fromNow()}
                                    </Grid>
                                  </Grid>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <TablePagination
                        // component="div"
                        rowsPerPageOptions={[5, 10, 15, 20]}
                        count={history.total}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </React.Fragment>
                  ) : (
                    <Grid container display={"flex"} alignItems={"center"}>
                      <Grid
                        container
                        justifyContent={"center"}
                        sx={{ paddingTop: "30px" }}
                      >
                        <Grid item xs={6}>
                          <Grid container justifyContent={"center"}>
                            <Typography variant="h6">
                              No matching History
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </div>
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </CustomModal>
    </div>
  );
};

export default HistoryTableModal;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { handleRequest } from "../../helper/utils";

export const loginUser = createAsyncThunk(
  "users/login",
  async ({ email, password }, thunkAPI) => {
    const endpoint = `/auth/login`;
    const requestData = { email, password };
    const toastIdPrefix = "login";

    return handleRequest(
      endpoint,
      requestData,
      toastIdPrefix,
      thunkAPI,
      (data) => {
        localStorage.setItem("accessToken", data.user.token);
        localStorage.setItem("expiryTime", data.user.expiryTime);
        localStorage.setItem("firstName", data.user.first_name);
        localStorage.setItem("email", data.user.email);
        localStorage.setItem("accountingFirmId", data.user.accountingFirmId);
        localStorage.setItem("role", data.user.role);
        localStorage.setItem(
          "reportList",
          JSON.stringify(data?.reportList || [])
        );
        localStorage.setItem(
          "user_data",
          JSON.stringify({
            name: `${data.user.first_name} ${data.user.last_name}`,
            email: data.user.email,
            userId: data.user._id,
            accountingFirmId: data.user.accountingFirmId,
            profileImg: data.profileImg,
          })
        );
        localStorage.setItem(
          "accFirm_data",
          JSON.stringify({
            accFirmName: data.client.name,
            accFirmImg: data.accFirmImg,
          })
        );
      }
    );
  }
);

export const registerUser = createAsyncThunk(
  "users/register",
  async (
    { email, password, firstName, lastName, passwordConfirm },
    thunkAPI
  ) => {
    const endpoint = `/auth/register`;
    const requestData = {
      email,
      password,
      first_name: firstName,
      last_name: lastName,
      password_confirm: passwordConfirm,
    };
    const toastIdPrefix = "register";

    return handleRequest(endpoint, requestData, toastIdPrefix, thunkAPI, () => {
      localStorage.setItem("email", email);
    });
  }
);

export const registerXeroUser = createAsyncThunk(
  "users/xeroRegister",
  async ({ formData }, thunkAPI) => {
    const toastIdPrefix = "Xero Register";
    return handleRequest(
      "/auth/xero-register",
      formData,
      toastIdPrefix,
      thunkAPI,
      (data) => {
        localStorage.setItem("firstName", data?.first_name);
        localStorage.setItem("lastName", data?.last_name);
        localStorage.setItem("email", data?.email);
        localStorage.setItem("accessToken", data?.token);
        localStorage.setItem("expiryTime", data?.expiryTime);
        localStorage.setItem("accountingFirmId", data?.accountingFirmId);
        localStorage.setItem("role", data?.role);
        localStorage.setItem(
          "reportList",
          JSON.stringify(data?.reportList || [])
        );
        localStorage.setItem(
          "user_data",
          JSON.stringify({
            name: `${data?.first_name} ${data?.last_name}`,
            email: data?.email,
            userId: data?._id,
            accountingFirmId: data?.accountingFirmId,
          })
        );

        localStorage.setItem(
          "accFirm_data",
          JSON.stringify({
            accFirmName: data?.accFirmName,
            accFirmImg: data?.accFirmImg,
          })
        );
      }
    );
  }
);

export const xeroLogin = createAsyncThunk(
  "xero-login",
  async (email, thunkAPI) => {
    const endpoint = `/auth/xero-login`;
    const toastIdPrefix = "login";

    return handleRequest(endpoint, email, toastIdPrefix, thunkAPI, (data) => {
      localStorage.setItem("accessToken", data?.token);
      localStorage.setItem("expiryTime", data?.expiryTime);
      localStorage.setItem("firstName", data?.first_name);
      localStorage.setItem("lastName", data?.last_name);
      localStorage.setItem("email", data?.email);
      localStorage.setItem("accountingFirmId", data?.accountingFirmId);
      localStorage.setItem("role", data?.role);
      // localStorage.setItem(
      //   "reportList",
      //   JSON.stringify(data?.reportList || [])
      // );
      localStorage.setItem(
        "user_data",
        JSON.stringify({
          name: `${data?.first_name} ${data?.last_name}`,
          email: data?.email,
          userId: data?._id,
          accountingFirmId: data.accountingFirmId,
          // imgUrl: data.user.imgUrl,
        })
      );
      localStorage.setItem(
        "accFirm_data",
        JSON.stringify({
          accFirmName: data?.accFirmName,
          accFirmImg: data?.accFirmImg,
        })
      );
    });
  }
);

export const forgotPassword = createAsyncThunk(
  "users/forgotPassword",
  async ({ email }, thunkAPI) => {
    const endpoint = "/auth/forgotPassword";
    const requestData = { email };
    const toastIdPrefix = "forgotPassword";
    return handleRequest(endpoint, requestData, toastIdPrefix, thunkAPI, () => {
      localStorage.setItem("email", email);
    });
  }
);

export const verifyUser = createAsyncThunk(
  "users/verifyUser",
  async ({ email, code }, thunkAPI) => {
    const endpoint = "/auth/verify";
    const requestData = { email, code };
    const toastIdPrefix = "verifyUser";

    return handleRequest(endpoint, requestData, toastIdPrefix, thunkAPI);
  }
);

export const resetPassword = createAsyncThunk(
  "users/resetPassword",
  async ({ password, passwordConfirm, id, token }, thunkAPI) => {
    const endpoint = "/auth/resetPassword";
    const requestData = { password, passwordConfirm, id, token };
    const toastIdPrefix = "resetPassword";

    return handleRequest(endpoint, requestData, toastIdPrefix, thunkAPI);
  }
);

export const inviteUser = createAsyncThunk(
  "users/invite",
  async ({ payload, accountingFirmId }, thunkAPI) => {
    const endpoint = `/accountingFirm/${accountingFirmId}/users/invite`;
    return handleRequest(endpoint, payload, "invite", thunkAPI);
  }
);

export const editInviteUser = createAsyncThunk(
  "users/edit-invitation",
  async ({ payload, accountingFirmId }, thunkAPI) => {
    const endpoint = `/accountingFirm/${accountingFirmId}/users/invite/edit/${payload.id}`;
    return handleRequest(
      endpoint,
      payload,
      "editInvitation",
      thunkAPI,
      null,
      "PATCH"
    );
  }
);

export const addInvitedUser = createAsyncThunk(
  "users/add-invited-user",
  async (
    {
      email,
      password,
      passwordConfirm,
      first_name,
      last_name,
      accountingFirmId,
      role,
    },
    thunkAPI
  ) => {
    const endpoint = `/auth/invite-users`;
    const requestData = {
      email,
      password,
      passwordConfirm,
      first_name,
      last_name,
      accountingFirmId,
      role,
    };
    const toastIdPrefix = "addInvitedUser";

    return handleRequest(
      endpoint,
      requestData,
      toastIdPrefix,
      thunkAPI,
      (data) => {
        localStorage.setItem("accessToken", data.token);
        localStorage.setItem("expiryTime", data.expiryTime);
        localStorage.setItem("email", data.email);
        localStorage.setItem(
          "user_data",
          JSON.stringify({
            name: `${data.first_name} ${data.last_name}`,
            email: data.email,
          })
        );
      }
    );
  }
);

export const getInvitations = createAsyncThunk(
  "invite-user/get-invitations",
  async ({ accountingFirmId }, thunkAPI) => {
    const endpoint = `/accountingFirm/${accountingFirmId}/users/invite-users/invitations`;
    return handleRequest(
      endpoint,
      null,
      "getInvitations",
      thunkAPI,
      null,
      "GET"
    );
  }
);

export const resendInvitation = createAsyncThunk(
  "invite-users/resendInvitation",
  async ({ id, accountingFirmId }, thunkAPI) => {
    const endpoint = `/accountingFirm/${accountingFirmId}/users/invite-users/resendInvitation/${id}`;
    return handleRequest(
      endpoint,
      null,
      "resendInvitation",
      thunkAPI,
      null,
      "PATCH"
    );
  }
);
export const deleteInvitation = createAsyncThunk(
  "invite-user/delete-invitation",
  async ({ id, accountingFirmId }, thunkAPI) => {
    const endpoint = `/accountingFirm/${accountingFirmId}/users/invite-users/invitations/${id}`;

    return handleRequest(
      endpoint,
      null,
      "deleteInvitation",
      thunkAPI,
      null,
      "DELETE"
    );
  }
);

export const userProfile = createAsyncThunk(
  "/userProfile",
  async ({ formData, accountingFirmId }, thunkAPI) => {
    const endpoint = `/accountingFirm/${accountingFirmId}/users/userProfile`;
    return handleRequest(
      endpoint,
      formData,
      "userProfile",
      thunkAPI,
      (data) => {
        localStorage.setItem(
          "user_data",
          JSON.stringify({
            name: `${data.updatedUser.first_name} ${data.updatedUser.last_name}`,
            imgUrl: data.profileImg,
            email: data.updatedUser.email,
            userId: data.updatedUser._id,
            accountingFirmId: data.updatedUser.accountingFirmId,
          })
        );
      },
      "PUT"
    );
  }
);

export const accFirmProfile = createAsyncThunk(
  "/accFrimPorfile",
  async ({ formData, accountingFirmId }, thunkAPI) => {
    const endPoint = `/accountingFirm/${accountingFirmId}/users/accFirmProfile`;
    return handleRequest(
      endPoint,
      formData,
      "accFirmProfile",
      thunkAPI,
      (data) => {
        localStorage.setItem(
          "accFirm_data",
          JSON.stringify({
            accFirmName: data.name,
            accFirmImg: data.accFirmImg,
          })
        );
      },
      "PUT"
    );
  }
);

const initialState = {
  firstName: "",
  lastName: "",
  email: "",
  imgUrl: "",
  role: "",
  id: "",
  isFetching: false,
  isSuccess: false,
  isError: false,
  successMessage: "",
  errorMessage: "",
  tenants: [],
  invitedUsers: [],
  accFirmProfile: [],
  userList: [],
};
export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    reset: () => initialState,
    resetFlags: (state) => {
      state.isFetching = false;
      state.isError = false;
      state.isSuccess = false;
      state.successMessage = "";
      state.errorMessage = "";
    },
  },
  extraReducers: {
    [loginUser.fulfilled]: (state, { payload }) => {
      state.email = payload.user.email;
      state.firstName = payload.user.first_name;
      state.email = payload.user._id;
      state.lastName = payload.user.last_name;
      state.imgUrl = payload.user?.profileImg;
      state.role = payload.user.role;
      state.tenants = payload?.tenants;
      state.accFirmProfile = payload.client;
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    },
    [loginUser.rejected]: (state, { payload }) => {
      state.isError = true;
      state.isFetching = false;
      state.isSuccess = false;
      state.successMessage = "";
      state.errorMessage = payload || "Failed";
      return state;
    },
    [loginUser.pending]: (state) => {
      state.isFetching = true;
      state.isError = false;
      state.isSuccess = false;
      state.successMessage = "";
      state.errorMessage = "";
      return state;
    },
    [xeroLogin.fulfilled]: (state, { payload }) => {
      state.email = payload.user.email;
      state.firstName = payload.user.first_name;
      state.lastName = payload.user.last_name;
      state.imgUrl = payload.user?.profileImg;
      state.role = payload.user.role;
      state.isFetching = false;
      state.isSuccess = true;
      state.isError = false;
      return state;
    },
    [xeroLogin.rejected]: (state, { payload }) => {
      state.isError = true;
      state.isFetching = false;
      state.isSuccess = false;
      state.successMessage = "";
      state.errorMessage = payload || "Failed";
      return state;
    },
    [xeroLogin.pending]: (state) => {
      state.isFetching = true;
      state.isError = false;
      state.isSuccess = false;
      state.successMessage = "";
      state.errorMessage = "";
      return state;
    },
    [registerUser.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.isError = false;
      state.successMessage = "New User Registered";
      state.errorMessage = "";
      return state;
    },
    [registerUser.rejected]: (state, { payload }) => {
      state.isError = true;
      state.isFetching = false;
      state.isSuccess = false;
      state.successMessage = "";
      state.errorMessage = payload || "Failed";
      return state;
    },
    [registerUser.pending]: (state) => {
      state.isFetching = true;
      state.isError = false;
      state.isSuccess = false;
      state.successMessage = "";
      state.errorMessage = "";
      return state;
    },
    [registerXeroUser.fulfilled]: (state, { payload }) => {
      state.email = payload.email;
      state.firstName = payload.last_name;
      state.lastName = payload.last_name;
      state.isFetching = false;
      state.isSuccess = true;
      state.isError = false;
      state.successMessage = "User from Xero Registered";
      state.errorMessage = "";
      // localStorage.setItem("accessToken", payload.token);
      // localStorage.setItem("expiryTime", payload.expiryTime);

      return state;
    },
    [registerXeroUser.rejected]: (state, { payload }) => {
      state.isError = true;
      state.isFetching = false;
      state.isSuccess = false;
      state.successMessage = "";
      state.errorMessage = payload || "Failed";
      return state;
    },
    [registerXeroUser.pending]: (state) => {
      state.isFetching = true;
      state.isError = false;
      state.isSuccess = false;
      state.successMessage = "";
      state.errorMessage = "";
      return state;
    },
    [forgotPassword.fulfilled]: (state) => {
      state.isSuccess = true;
      state.isError = false;
      state.isFetching = false;
      state.successMessage = "";
      state.errorMessage = "";
      return state;
    },
    [forgotPassword.rejected]: (state, { payload }) => {
      state.isError = true;
      state.isFetching = false;
      state.errorMessage = "Forgot Password Rejected";
      return state;
    },
    [forgotPassword.pending]: (state) => {
      state.isFetching = true;
      state.isError = false;
      state.isSuccess = false;
      state.successMessage = "";
      state.errorMessage = "";
      return state;
    },
    [resetPassword.fulfilled]: (state) => {
      state.isSuccess = true;
      state.isError = false;
      state.isFetching = false;
      state.successMessage = "";
      state.errorMessage = "";
      return state;
    },
    [resetPassword.rejected]: (state, { payload }) => {
      state.isError = true;
      state.isFetching = false;
      state.errorMessage = "Reset Password Rejected";
      return state;
    },
    [resetPassword.pending]: (state) => {
      state.isFetching = true;
      state.isError = false;
      state.isSuccess = false;
      state.successMessage = "";
      state.errorMessage = "";
      return state;
    },
    [inviteUser.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.isError = false;
      state.errorMessage = "";
      state.successMessage = "Invitation Sent";
      let newUsers = [...state.invitedUsers, ...payload.newInvitation];
      state.invitedUsers = newUsers;
      return state;
    },
    [inviteUser.rejected]: (state, { payload }) => {
      state.isError = true;
      state.isSuccess = false;
      state.isFetching = false;
      state.errorMessage = payload?.data?.message || "Failed";
      state.successMessage = "";
      return state;
    },
    [inviteUser.pending]: (state, action) => {
      state.isFetching = true;
      state.isError = false;
      state.isSuccess = false;
      state.errorMessage = "";
      state.successMessage = "";
      return state;
    },
    [editInviteUser.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        isFetching: false,
        isSuccess: true,
        isError: false,
        errorMessage: "",
        successMessage: "Changes updated successfully",
        invitedUsers: state.invitedUsers.map((item) => {
          if (item?._id === payload?.editInvitation?._id) {
            localStorage.setItem("role", payload.editInvitation.role);
            return { ...item, ...payload.editInvitation };
          }
          return item;
        }),
      };
    },
    [editInviteUser.rejected]: (state, { payload }) => {
      state.isError = true;
      state.isSuccess = false;
      state.isFetching = false;
      state.errorMessage = payload?.data?.message || "Failed";
      state.successMessage = "";
      return state;
    },
    [editInviteUser.pending]: (state) => {
      state.isFetching = true;
      state.isError = false;
      state.isSuccess = false;
      state.errorMessage = "";
      state.successMessage = "";
      return state;
    },
    [addInvitedUser.fulfilled]: (state, { payload }) => {
      state.email = payload?.data?.newUser?.email;
      state.firstName = payload?.data?.newUser?.first_name;
      state.lastName = payload?.data?.newUser?.last_name;
      state.isSuccess = true;
      state.isError = false;
      state.isFetching = false;
      state.successMessage = "";
      state.errorMessage = "";
      return state;
    },
    [addInvitedUser.rejected]: (state, { payload }) => {
      state.isError = true;
      state.isFetching = false;
      state.isSuccess = false;
      state.errorMessage = payload?.data?.message || "Failed";
      state.successMessage = "";
      return state;
    },
    [addInvitedUser.pending]: (state) => {
      state.isFetching = true;
      state.isError = true;
      state.isSuccess = false;
      state.successMessage = "";
      state.errorMessage = "";
      return state;
    },
    [getInvitations.fulfilled]: (state, { payload }) => {
      state.isSuccess = true;
      state.isError = false;
      state.isFetching = false;
      state.successMessage = "";
      state.errorMessage = "";
      state.invitedUsers = payload?.invitations;
      return state;
    },
    [getInvitations.rejected]: (state, { payload }) => {
      state.isError = true;
      state.isFetching = false;
      state.isSuccess = false;
      state.errorMessage = payload?.data?.message || "Failed";
      state.successMessage = "";
      return state;
    },
    [getInvitations.pending]: (state) => {
      state.isFetching = true;
      state.isError = false;
      state.isSuccess = false;
      state.successMessage = "";
      state.errorMessage = "";
      return state;
    },
    [resendInvitation.fulfilled]: (state, { payload }) => {
      state.isSuccess = true;
      state.isError = false;
      state.isFetching = false;
      state.successMessage = "Invitation Updated";
      state.errorMessage = "";
      state.invitedUsers = state.invitedUsers.map((item) => {
        if (item._id === payload._id) {
          return { ...item, ...payload };
        } else return item;
      });
      return state;
    },
    [resendInvitation.rejected]: (state, { payload }) => {
      state.isError = true;
      state.isSuccess = false;
      state.isFetching = false;
      state.errorMessage = payload?.reason || "Failed";
      state.successMessage = "";
      return state;
    },
    [resendInvitation.pending]: (state, { payload }) => {
      state.isFetching = true;
      state.isError = false;
      state.isSuccess = false;
      state.successMessage = "";
      state.errorMessage = "";
      return state;
    },
    [deleteInvitation.fulfilled]: (state, { payload }) => {
      state.isSuccess = true;
      state.isError = false;
      state.isFetching = false;
      state.successMessage = "Invitation Deleted";
      state.errorMessage = "";
      let index = state.invitedUsers
        .map((val) => val?._id)
        .indexOf(payload.deleteInvitation?._id);
      state.invitedUsers.splice(index, 1);

      return state;
    },
    [deleteInvitation.rejected]: (state, { payload }) => {
      state.isError = true;
      state.isSuccess = false;
      state.isFetching = false;
      state.errorMessage = payload?.reason || "Failed";
      state.successMessage = "";
      return state;
    },
    [deleteInvitation.pending]: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = true;
      state.errorMessage = "";
      state.successMessage = "";
      return state;
    },
    [userProfile.fulfilled]: (state, { payload }) => {
      state.isSuccess = true;
      state.isError = false;
      state.isFetching = false;
      state.successMessage = "User updated successfully";
      state.errorMessage = "";
      state.firstName = payload?.updatedUser?.first_name;
      state.lastName = payload?.updatedUser?.last_name;
      state.imgUrl = payload?.profileImg;
      return state;
    },
    [userProfile.rejected]: (state, { payload }) => {
      state.isError = true;
      state.isSuccess = false;
      state.isFetching = false;
      state.errorMessage = payload?.reason || "Failed";
      state.successMessage = "";
      return state;
    },
    [userProfile.pending]: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = true;
      state.errorMessage = "";
      state.successMessage = "";
      return state;
    },
    [accFirmProfile.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.isError = false;
      state.errorMessage = "";
      state.successMessage = "Invitation Sent";
      state.accFirmProfile = payload;
      return state;
    },
    [accFirmProfile.rejected]: (state, { payload }) => {
      state.isError = true;
      state.isSuccess = false;
      state.isFetching = false;
      state.errorMessage = payload?.data?.message || "Failed";
      state.successMessage = "";
      return state;
    },
    [accFirmProfile.pending]: (state) => {
      state.isFetching = true;
      state.isError = false;
      state.isSuccess = false;
      state.errorMessage = "";
      state.successMessage = "";
      return state;
    },
  },
});
export const { reset, resetFlags } = userSlice.actions;

export default userSlice.reducer;

import React, { Fragment, useEffect, useState } from "react";

import {
  Grid,
  TextField,
  IconButton,
  Typography,
  Divider,
  Select,
  MenuItem,
  Checkbox,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  getInvitations,
  deleteInvitation,
  resendInvitation,
  inviteUser,
} from "../../../redux/User/UserSlice";
import "./Team.css";
import { v4 as uuidv4 } from "uuid";
import ConfirmModal from "../../../components/CustomModal/ConfirmModal";
import Loading from "../../../components/LoadingIndicator/Loading";
import CustomModal from "../../../components/CustomModal/CustomModal";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import { ColorPalette } from "../../../constants/colorPalette";
import { toTitleCase } from "../../../helper/titleCaseConverter";
import { useParams } from "react-router-dom";
import InviteTable from "./InviteTable/InviteTable";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import HistoryTableModal from "../../../components/HistoryTable/HistoryTableModal";
import { ButtonComponent } from "../../../components/Button/Button";
import {
  DeleteOutline,
  KeyboardArrowDown,
  KeyboardArrowRight,
} from "@mui/icons-material";
import Nestable from "react-nestable";
import { getClientSpaces } from "../../../redux/ClientSpace/ClientSpaceSlice";
import EditUserDetailsModal from "./EditUserDetails/EditUserDetailsModal";
import { checkDataAvailibility } from "../../../helper/utils";

export default function Team() {
  const dispatch = useDispatch();
  const { accountingFirmId } = useParams();
  let invitedUsers = useSelector((state) => state.user.invitedUsers);
  const { isSuccess, isFetching } = useSelector(({ user }) => user);
  const [collapseAll, setCollapseAll] = useState(true);
  const { clientSpaces, isFetching: clientSpaceFetching } = useSelector(
    (state) => state.clientSpaces
  );
  const userData = useSelector((state) => state.user);
  const stateData = checkDataAvailibility(userData);

  const [, setType] = useState("add");
  const roles = ["ACCOUNTANT", "ADMIN", "CLIENT"];
  const [selectedUsers, setSelectedUsers] = useState([
    {
      id: uuidv4(),
      email: "",
      role: "ACCOUNTANT",
      error: {
        email: null,
        role: null,
      },
    },
  ]);
  const [selectedIds, setSelectedIds] = useState({
    clientSpaceIds: [],
    entityIds: [],
  });

  const [updateId, setUpdateId] = useState();
  const [deleteId, setDeleteId] = useState();
  const [initialLoadingState, setInitialLoadingState] = useState(false);
  const [hasEmptyUsers, setHasEmptyUsers] = useState(true);

  const [inviteModalOpen, setInviteModalOpen] = useState(false);
  const [editInviteModalOpen, setEditInviteModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [inviteConfirmModal, setInviteConfirmModal] = useState(false);
  const [resendConfirmModal, setResendConfirmModal] = useState(false);
  const [historyModalOpen, setHistoryModalOpen] = useState(false);

  useEffect(() => {
    dispatch(getClientSpaces({ accountingFirmId }));
    dispatch(getInvitations({ accountingFirmId }));
  }, [dispatch, accountingFirmId]);

  useEffect(() => {
    if (!isFetching && !initialLoadingState) {
      setInitialLoadingState(true);
    }
  }, [isFetching, initialLoadingState]);

  useEffect(() => {
    if (isSuccess) {
      setInviteModalOpen(false);
    }
  }, [isSuccess]);

  useEffect(() => {
    setHasEmptyUsers(
      selectedUsers.some(({ email, role }) => !email.trim() || !role.trim())
    );
  }, [selectedUsers, hasEmptyUsers]);

  const validateForm = () => {
    let hasError = false;
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

    // Check for empty fields and invalid emails
    const updatedUsers = selectedUsers.map((user) => {
      const error = { email: null, role: null };

      if (!user.email.trim()) {
        error.email = "Email is empty";
        hasError = true;
      } else if (!emailRegex.test(user.email)) {
        error.email = "Invalid email address";
        hasError = true;
      }

      if (!user.role.trim()) {
        error.role = "Role is empty";
        hasError = true;
      }
      return { ...user, error };
    });

    // Check for duplicate emails
    const emails = updatedUsers.map((user) =>
      user.email.trim().toLocaleLowerCase()
    );
    const duplicates = emails.filter(
      (email, index) => emails.indexOf(email) !== index
    );
    if (duplicates.length > 0) {
      updatedUsers.forEach((user) => {
        if (duplicates.includes(user.email.trim().toLocaleLowerCase())) {
          user.error.email = "Duplicate email";
          hasError = true;
        }
      });
    }

    setSelectedUsers(updatedUsers);
    return !hasError;
  };

  const transformData = (items) => {
    return (
      items?.map((client) => ({
        id: client._id,
        text: client.clientSpaceName,
        children:
          client.clientSpaceEntities?.map((entity) => ({
            id: entity._id,
            text: entity.entityName,
          })) || [],
      })) || []
    );
  };

  const sendInvitation = () => {
    const payload = selectedUsers?.map((item) => ({
      email: item.email,
      role: item.role,
      clientSpaceIds: selectedIds.clientSpaceIds,
      entityIds: selectedIds.entityIds,
    }));

    dispatch(inviteUser({ payload, accountingFirmId })).finally(() => {
      setSelectedUsers([
        {
          id: uuidv4(),
          email: "",
          role: "ACCOUNTANT",
          error: { email: null, role: null },
        },
      ]);
      setSelectedIds({
        clientSpaceIds: [],
        entityIds: [],
      });
    });
    setInviteConfirmModal(false);
  };

  const resendInvitationFunc = () => {
    const id = updateId;
    dispatch(resendInvitation({ id, accountingFirmId }));
    setResendConfirmModal(false);
  };

  const deleteCurrentInvitation = (id) => {
    dispatch(deleteInvitation({ id, accountingFirmId }));
  };

  const confirmDeleteFunction = () => {
    deleteCurrentInvitation(deleteId);
    setDeleteModalOpen(false);
  };

  const handleAddUserChange = (id, fieldName, fieldValue) => {
    const updatedUser = selectedUsers.map((user) => {
      if (user.id === id) {
        return {
          ...user,
          [fieldName]: fieldValue,
          error: {
            ...user.error,
            [fieldName === "email" ? "name" : "type"]: null,
          },
        };
      }
      return user;
    });
    setSelectedUsers(updatedUser);
  };

  const handleDelete = (id) => {
    const updatedUser = selectedUsers.filter((entity) => entity.id !== id);
    setSelectedUsers(updatedUser);
  };

  const handleAddAnother = () => {
    setSelectedUsers([
      ...selectedUsers,
      {
        id: uuidv4(),
        email: "",
        role: "ACCOUNTANT",
        error: { email: null, role: null },
      },
    ]);
  };

  const cancelInviteMembers = () => {
    setInviteModalOpen(false);
    setSelectedUsers([
      {
        id: uuidv4(),
        email: "",
        role: "ACCOUNTANT",
        error: { email: null, role: null },
      },
    ]);
    setSelectedIds({
      clientSpaceIds: [],
      entityIds: [],
    });
  };

  const handleInviteClick = () => {
    const isValid = validateForm();
    if (isValid) {
      setInviteConfirmModal(true);
    }
  };

  const renderItem = ({ item, index, collapseIcon }) => {
    const isClientSpace = item.children && item.children.length > 0;

    // Determine checked state
    let isChecked = isClientSpace
      ? item.children.some((child) => selectedIds.entityIds.includes(child.id))
      : selectedIds.entityIds.includes(item.id);

    // Determine indeterminate state (only for client spaces)
    let isIndeterminate = isClientSpace
      ? isChecked &&
        !item.children.every((child) =>
          selectedIds.entityIds.includes(child.id)
        )
      : false;

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          padding: "8px 0",
          borderBottom: "1px solid #e0e0e0",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <Checkbox
            checked={isChecked}
            indeterminate={isIndeterminate}
            onChange={(e) => handleCheckBoxChange(item, e.target.checked)}
            style={{
              color: ColorPalette.primary,
            }}
          />
          <span>{item.text}</span>
        </div>
        {isClientSpace && (
          <div style={{ marginLeft: "auto" }}>{collapseIcon}</div>
        )}
      </div>
    );
  };

  // Simplified checkbox change handler
  const handleCheckBoxChange = (item, isChecked) => {
    setSelectedIds((prev) => {
      const isClientSpace = item.children && item.children.length > 0;
      let entityIds = new Set(prev.entityIds);
      let clientSpaceIds = new Set(prev.clientSpaceIds);

      if (isClientSpace) {
        item.children.forEach((child) =>
          isChecked ? entityIds.add(child.id) : entityIds.delete(child.id)
        );
        isChecked
          ? clientSpaceIds.add(item.id)
          : clientSpaceIds.delete(item.id);
      } else {
        isChecked ? entityIds.add(item.id) : entityIds.delete(item.id);
        const parentId = findParentClientSpaceId(item.id);
        if (parentId) {
          const hasSelectedSiblings = clientSpaces
            .find((cs) => cs._id === parentId)
            ?.clientSpaceEntities.some(
              (e) => e._id !== item.id && entityIds.has(e._id)
            );
          hasSelectedSiblings || isChecked
            ? clientSpaceIds.add(parentId)
            : clientSpaceIds.delete(parentId);
        }
      }

      return {
        clientSpaceIds: Array.from(clientSpaceIds),
        entityIds: Array.from(entityIds),
      };
    });
  };

  // Helper function to find parent client space ID
  const findParentClientSpaceId = (entityId) => {
    const parentClientSpace = clientSpaces.find((client) =>
      client.clientSpaceEntities.some((entity) => entity._id === entityId)
    );
    return parentClientSpace?._id;
  };

  return (
    <div style={{ margin: "2%" }}>
      <Grid container>
        {/* confirm invitation send */}
        {inviteConfirmModal && (
          <ConfirmModal
            modalOpen={inviteConfirmModal}
            setModalOpen={setInviteConfirmModal}
            onClose={() => setInviteConfirmModal(false)}
            confirmFunction={sendInvitation}
            declineFunction={() => setInviteConfirmModal(false)}
            message="Are you sure you want to send the invite?"
          />
        )}
        {/* delete invitation modal */}
        {deleteModalOpen && (
          <ConfirmModal
            modalOpen={deleteModalOpen}
            setModalOpen={setDeleteModalOpen}
            onClose={() => setDeleteModalOpen(false)}
            confirmFunction={confirmDeleteFunction}
            declineFunction={() => setDeleteModalOpen(false)}
            message="Are you sure you want to delete?"
          />
        )}
        {/* resend invitation modal */}
        {resendConfirmModal && (
          <ConfirmModal
            modalOpen={resendConfirmModal}
            setModalOpen={setResendConfirmModal}
            onClose={() => setResendConfirmModal(false)}
            confirmFunction={resendInvitationFunc}
            declineFunction={() => setResendConfirmModal(false)}
            message="Are you sure you want to resend invitation?"
          />
        )}

        {/* edit inviation modal */}
        {editInviteModalOpen && (
          <EditUserDetailsModal
            modalOpen={editInviteModalOpen}
            setModalOpen={setEditInviteModalOpen}
            editId={updateId}
          />
        )}

        {historyModalOpen && (
          <HistoryTableModal
            modalOpen={historyModalOpen}
            setModalOpen={setHistoryModalOpen}
            pageType={"INVITATION"}
            operations={["Add", "Update", "Delete"]}
          />
        )}

        {/* Add invite modal */}
        <CustomModal
          onClose={cancelInviteMembers}
          modalOpen={inviteModalOpen}
          setModalOpen={setInviteModalOpen}
          overflow="hidden"
          minWidth="50vw"
          maxWidth="md"
        >
          <div>
            {isFetching && (
              <Grid>
                <Loading wait={true} />
              </Grid>
            )}

            <Grid container padding={2}>
              <Grid item xs={12} mb={2}>
                <Grid
                  container
                  xs={12}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginBottom: "15px",
                  }}
                >
                  <Typography fontSize={18} fontWeight="bold">
                    Invite Team Members
                  </Typography>
                  <IconButton
                    id="button-addGroupCloseIcon"
                    style={{ borderRadius: 0 }}
                    size="small"
                    onClick={cancelInviteMembers}
                  >
                    <CloseIcon />
                  </IconButton>
                </Grid>
                <Divider sx={{ width: "100%", color: ColorPalette.danger }} />
              </Grid>
              <Grid item xs={12} marginTop={2} px={2} pt={2}>
                <Grid
                  style={{
                    maxHeight: "20vh",
                    overflowY: "auto",
                  }}
                >
                  {selectedUsers.map((user, index) => (
                    <Grid
                      display="flex"
                      container
                      alignItems="flex-start"
                      justifyContent="space-between"
                      marginTop="10px"
                    >
                      <Grid item xs={5.5}>
                        <Typography
                          id="text-addEmail"
                          className="mb-10"
                          color={ColorPalette.black}
                        >
                          Email
                        </Typography>
                        <TextField
                          size="small"
                          fullWidth
                          id="input-email"
                          name="email"
                          error={user.error.email && !!user.error.email}
                          variant="outlined"
                          value={user?.email}
                          onChange={(e) => {
                            handleAddUserChange(
                              user.id,
                              "email",
                              e.target.value
                            );
                          }}
                        />
                        {user.error.email && (
                          <Typography color={"error"} fontSize={"12px"} my={1}>
                            {user.error.email}
                          </Typography>
                        )}
                      </Grid>

                      <Grid
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                        item
                        xs={5.5}
                      >
                        <Grid items xs={12}>
                          <Typography
                            id="text-addRole"
                            color={ColorPalette.black}
                            className="mb-10"
                          >
                            Role
                          </Typography>
                          <Select
                            size="small"
                            labelId="role"
                            id="input-role"
                            value={user.role}
                            fullWidth
                            error={user.error.role && !!user.error.role}
                            onChange={(e) => {
                              handleAddUserChange(
                                user.id,
                                "role",
                                e.target.value
                              );
                            }}
                            variant="outlined"
                          >
                            {roles.map((type, index) => (
                              <MenuItem key={index} value={type}>
                                {toTitleCase(type)}
                              </MenuItem>
                            ))}
                          </Select>
                          {user.error.role && (
                            <Typography
                              color={"error"}
                              fontSize={"12px"}
                              my={1}
                            >
                              {user.error.role}
                            </Typography>
                          )}
                        </Grid>

                        {/* Delete Button */}
                        {selectedUsers.length > 1 && (
                          <IconButton
                            id="button-deleteInvitationDetails"
                            style={{
                              marginTop: "auto",
                              color: ColorPalette.danger,
                            }}
                            size="mediium"
                            onClick={() => handleDelete(user.id)}
                          >
                            <DeleteOutline />
                          </IconButton>
                        )}
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </Grid>

              {clientSpaces.length > 0 && (
                <Fragment>
                  {clientSpaceFetching ? (
                    <Grid
                      container
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"center"}
                    >
                      <Loading />
                    </Grid>
                  ) : (
                    <Grid
                      container
                      sx={{
                        border: `1px solid ${ColorPalette.borderColor}`,
                        borderRadius: "8px",
                        mt: "20px",
                        maxHeight: "30vh",
                        overflow: "auto",
                      }}
                    >
                      <Grid item xs={12}>
                        <Typography
                          onClick={() => setCollapseAll(!collapseAll)}
                          sx={{
                            backgroundColor: collapseAll
                              ? ColorPalette.backgroundColor
                              : ColorPalette.white,
                            p: "10px",
                            "&:hover": {
                              cursor: "pointer",
                            },
                            fontWeight: "bold",
                          }}
                        >
                          Client Spaces
                        </Typography>
                      </Grid>
                      <Grid item xs={12} p={2}>
                        <Nestable
                          items={clientSpaces && transformData(clientSpaces)}
                          renderItem={renderItem}
                          collapsed={collapseAll}
                          renderCollapseIcon={({ isCollapsed }) => (
                            <div style={{ marginLeft: "8px" }}>
                              {isCollapsed ? (
                                <KeyboardArrowRight />
                              ) : (
                                <KeyboardArrowDown />
                              )}
                            </div>
                          )}
                        />
                      </Grid>
                    </Grid>
                  )}
                </Fragment>
              )}

              <Grid
                container
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Grid item>
                  <ButtonComponent
                    startIcon={<AddIcon />}
                    styles={{ marginTop: "20px" }}
                    title={"Add Another"}
                    onClick={handleAddAnother}
                    disabled={hasEmptyUsers}
                  />
                </Grid>
                <Grid
                  item
                  sx={{
                    display: "flex",
                    gap: "10px",
                    marginTop: "30px",
                  }}
                >
                  <ButtonComponent
                    onClick={cancelInviteMembers}
                    title={"Cancel"}
                  />
                  <ButtonComponent
                    contained={true}
                    onClick={handleInviteClick}
                    disabled={!selectedUsers?.length > 0 || isFetching}
                    title={"Invite"}
                  />
                </Grid>
              </Grid>
            </Grid>
          </div>
        </CustomModal>
        <Grid container>
          <Typography
            id="text-welcomeBack"
            variant="h1"
            sx={{
              fontSize: { xs: 24, sm: 36 },
              fontWeight: "bold",
              color: ColorPalette.text,
              marginTop: "1rem",
              marginBottom: "1rem",
            }}
          >
            Users Detail
          </Typography>
        </Grid>
        <Grid
          container
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"space-between"}
          height={"77vh"}
        >
          {isFetching || !initialLoadingState ? (
            <Loading />
          ) : (
            <div style={{ width: "100%" }}>
              {/* invited users list */}
              <InviteTable
                setInviteModalOpen={setInviteModalOpen}
                inviteModalOpen={inviteModalOpen}
                setEditModalOpen={setEditInviteModalOpen}
                setType={setType}
                invitedUsers={invitedUsers}
                setDeleteModalOpen={setDeleteModalOpen}
                setDeleteId={setDeleteId}
                setUpdateId={setUpdateId}
                setResendConfirmModal={setResendConfirmModal}
                stateData={stateData}
              />
            </div>
          )}
          {
            // stateData.role === "ADMIN" &&
            <Grid
              sx={{
                display: "flex",
                justifyContent: "right",
              }}
            >
              <ButtonComponent
                title={"Activity Log"}
                startIcon={<VisibilityOutlinedIcon />}
                onClick={() => setHistoryModalOpen(true)}
              />
            </Grid>
          }
        </Grid>
      </Grid>
    </div>
  );
}

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomModal from "../../../../components/CustomModal/CustomModal";
import {
  Checkbox,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import Loading from "../../../../components/LoadingIndicator/Loading";
import CloseIcon from "@mui/icons-material/Close";
import { ColorPalette } from "../../../../constants/colorPalette";
import { ButtonComponent } from "../../../../components/Button/Button";
import { KeyboardArrowDown, KeyboardArrowRight } from "@mui/icons-material";
import Nestable from "react-nestable";
import { toTitleCase } from "../../../../helper/titleCaseConverter";
import { editInviteUser } from "../../../../redux/User/UserSlice";
import { useParams } from "react-router-dom";

const EditUserDetailsModal = ({ modalOpen, setModalOpen, editId }) => {
  const dispatch = useDispatch();
  const { accountingFirmId } = useParams();
  const { invitedUsers, isFetching } = useSelector((state) => state.user);
  const [collapseAll, setCollapseAll] = useState(true);
  const { clientSpaces, isFetching: clientSpaceFetching } = useSelector(
    (state) => state.clientSpaces
  );
  const user = invitedUsers.find((user) => user._id === editId);

  const [selectedIds, setSelectedIds] = useState({
    clientSpaceIds: user?.clientSpaceIds || [],
    entityIds: user?.entityIds || [],
  });
  const [selectedRole, setSelectedRole] = useState(user?.role || "ACCOUNTANT");

  const roles = ["ACCOUNTANT", "ADMIN", "CLIENT"];

  const handleClose = () => {
    setModalOpen(false);
  };

  const handleInviteSend = () => {
    const payload = {
      id: user._id,
      email: user.email,
      role: selectedRole,
      clientSpaceIds: selectedIds.clientSpaceIds,
      entityIds: selectedIds.entityIds,
    };
    dispatch(editInviteUser({ accountingFirmId, payload })).finally(() =>
      setModalOpen(false)
    );
  };

  const transformData = (items) => {
    return (
      items?.map((client) => ({
        id: client._id,
        text: client.clientSpaceName,
        children:
          client.clientSpaceEntities?.map((entity) => ({
            id: entity._id,
            text: entity.entityName,
          })) || [],
      })) || []
    );
  };

  const renderItem = ({ item, index, collapseIcon }) => {
    const isClientSpace = item.children && item.children.length > 0;

    // Determine checked state
    let isChecked = isClientSpace
      ? item.children.some((child) => selectedIds.entityIds.includes(child.id))
      : selectedIds.entityIds.includes(item.id);

    // Determine indeterminate state (only for client spaces)
    let isIndeterminate = isClientSpace
      ? isChecked &&
        !item.children.every((child) =>
          selectedIds.entityIds.includes(child.id)
        )
      : false;

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          padding: "8px 0",
          borderBottom: "1px solid #e0e0e0",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <Checkbox
            checked={isChecked}
            indeterminate={isIndeterminate}
            onChange={(e) => handleCheckBoxChange(item, e.target.checked)}
            style={{
              color: ColorPalette.primary,
            }}
          />
          <span>{item.text}</span>
        </div>
        {isClientSpace && (
          <div style={{ marginLeft: "auto" }}>{collapseIcon}</div>
        )}
      </div>
    );
  };

  // Simplified checkbox change handler
  const handleCheckBoxChange = (item, isChecked) => {
    setSelectedIds((prev) => {
      const isClientSpace = item.children && item.children.length > 0;
      let entityIds = new Set(prev.entityIds);
      let clientSpaceIds = new Set(prev.clientSpaceIds);

      if (isClientSpace) {
        item.children.forEach((child) =>
          isChecked ? entityIds.add(child.id) : entityIds.delete(child.id)
        );
        isChecked
          ? clientSpaceIds.add(item.id)
          : clientSpaceIds.delete(item.id);
      } else {
        isChecked ? entityIds.add(item.id) : entityIds.delete(item.id);
        const parentId = findParentClientSpaceId(item.id);
        if (parentId) {
          const hasSelectedSiblings = clientSpaces
            .find((cs) => cs._id === parentId)
            ?.clientSpaceEntities.some(
              (e) => e._id !== item.id && entityIds.has(e._id)
            );
          hasSelectedSiblings || isChecked
            ? clientSpaceIds.add(parentId)
            : clientSpaceIds.delete(parentId);
        }
      }

      return {
        clientSpaceIds: Array.from(clientSpaceIds),
        entityIds: Array.from(entityIds),
      };
    });
  };

  // Helper function to find parent client space ID
  const findParentClientSpaceId = (entityId) => {
    const parentClientSpace = clientSpaces.find((client) =>
      client.clientSpaceEntities.some((entity) => entity._id === entityId)
    );
    return parentClientSpace?._id;
  };

  return (
    <CustomModal
      onClose={handleClose}
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      overflow="hidden"
      minWidth="50vw"
      maxWidth="md"
    >
      <div>
        {(clientSpaceFetching || isFetching) && (
          <Grid>
            <Loading wait={true} />
          </Grid>
        )}

        <Grid container padding={2}>
          <Grid item xs={12} mb={2}>
            <Grid
              container
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: "15px",
              }}
            >
              <Typography fontSize={18} fontWeight="bold">
                Invite Team Members
              </Typography>
              <IconButton
                id="button-addGroupCloseIcon"
                style={{ borderRadius: 0 }}
                size="small"
                onClick={handleClose}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
            <Divider sx={{ width: "100%", color: ColorPalette.danger }} />
          </Grid>
          <Grid item xs={12} marginTop={2} px={2} pt={2}>
            <Grid
              style={{
                maxHeight: "20vh",
                overflowY: "auto",
              }}
            >
              <Grid
                display="flex"
                container
                alignItems="flex-start"
                justifyContent="space-between"
                marginTop="10px"
              >
                <Grid item xs={5.5}>
                  <Typography
                    id="text-addEmail"
                    className="mb-10"
                    color={ColorPalette.black}
                  >
                    Email
                  </Typography>
                  <TextField
                    size="small"
                    fullWidth
                    id="input-email"
                    name="email"
                    value={user?.email || ""}
                    disabled // Makes field non-editable
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>

                <Grid
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                  item
                  xs={5.5}
                >
                  <Grid item xs={12}>
                    <Typography
                      id="text-addRole"
                      color={ColorPalette.black}
                      className="mb-10"
                    >
                      Role
                    </Typography>
                    <Select
                      fullWidth
                      size="small"
                      value={selectedRole}
                      onChange={(e) => setSelectedRole(e.target.value)}
                      variant="outlined"
                    >
                      {roles.map((type, index) => (
                        <MenuItem key={index} value={type}>
                          {toTitleCase(type)}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {clientSpaceFetching ? (
            <Grid
              container
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Loading />
            </Grid>
          ) : (
            <Grid
              container
              sx={{
                border: `1px solid ${ColorPalette.borderColor}`,
                borderRadius: "8px",
                mt: "20px",
                maxHeight: "30vh",
                overflow: "auto",
              }}
            >
              <Grid item xs={12}>
                <Typography
                  onClick={() => setCollapseAll(!collapseAll)}
                  sx={{
                    backgroundColor: collapseAll
                      ? ColorPalette.backgroundColor
                      : ColorPalette.white,
                    p: "10px",
                    "&:hover": {
                      cursor: "pointer",
                    },
                    fontWeight: "bold",
                  }}
                >
                  Client Spaces
                </Typography>
              </Grid>
              <Grid item xs={12} p={2}>
                <Nestable
                  items={transformData(clientSpaces)}
                  renderItem={renderItem}
                  collapsed={collapseAll}
                  renderCollapseIcon={({ isCollapsed }) => (
                    <div style={{ marginLeft: "8px" }}>
                      {isCollapsed ? (
                        <KeyboardArrowRight />
                      ) : (
                        <KeyboardArrowDown />
                      )}
                    </div>
                  )}
                />
              </Grid>
            </Grid>
          )}

          <Grid
            container
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Grid item></Grid>
            <Grid
              item
              sx={{
                display: "flex",
                gap: "10px",
                marginTop: "30px",
              }}
            >
              <ButtonComponent onClick={handleClose} title={"Cancel"} />
              <ButtonComponent
                contained={true}
                onClick={handleInviteSend}
                //   disabled={!selectedUsers?.length > 0 || isFetching}
                title={"Save"}
              />
            </Grid>
          </Grid>
        </Grid>
      </div>
    </CustomModal>
  );
};

export default EditUserDetailsModal;

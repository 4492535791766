import React, { Fragment, useState } from "react";
import {
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import TableListCard from "../../../../components/TableListCard/TableListCard";
import { useNavigate, useParams } from "react-router-dom";
import { ColorPalette } from "../../../../constants/colorPalette";
import { toTitleCase } from "../../../../helper/titleCaseConverter";
import { Typography } from "@mui/material";
import ConfirmModal from "../../../../components/CustomModal/ConfirmModal";
import EditEntityModal from "./editEntityModal/EditEntityModal";
import { useDispatch } from "react-redux";
import { deleteEntity } from "../../../../redux/ClientSpace/ClientSpaceSlice";
import { MoreVertOutlined } from "@mui/icons-material";
import { ButtonComponent } from "../../../../components/Button/Button";

export default function EntitiesList({
  title,
  subtitle,
  data,
  setModalOpen,
  deleteFunction,
  setType,
  setEditValues,
  setCurrentEntityId,
  isFetching,
  stateData,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { clientSpaceId, accountingFirmId } = useParams();
  const [anchorEl, setAnchorEl] = useState(null);
  const [editSpaceModalOpen, setEditSpaceModalOpen] = useState(false);
  const [deleteSpaceModalOpen, setDeleteSpaceModalOpen] = useState(false);
  const [editData, setEditData] = useState(null);
  const [deleteId, setDeleteId] = useState(null);

  const handleMenuItemClick = (event, row) => {
    setAnchorEl({ [row?.id]: event.currentTarget });
    setEditData(row);
    setDeleteId(row?.id);
  };

  const handleMenuItemClose = () => {
    setAnchorEl(null);
  };

  const handleEditModal = () => {
    setEditSpaceModalOpen(true);
    handleMenuItemClose();
  };

  const handleDeleteModal = () => {
    setDeleteSpaceModalOpen(true);
    handleMenuItemClose();
  };

  const handleDelete = () => {
    dispatch(deleteEntity({ accountingFirmId, id: deleteId }));
    setDeleteSpaceModalOpen(false);
  };

  return (
    <div>
      {editSpaceModalOpen && (
        <EditEntityModal
          modalOpen={editSpaceModalOpen}
          setModalOpen={setEditSpaceModalOpen}
          editData={editData}
          rows={data}
        />
      )}
      {deleteSpaceModalOpen && (
        <ConfirmModal
          message="Are you sure you want to delete the entity?"
          confirmText="Yes"
          declineText="No"
          declineFunction={() => setDeleteSpaceModalOpen(false)}
          confirmFunction={handleDelete}
          modalOpen={deleteSpaceModalOpen}
          setModalOpen={setDeleteSpaceModalOpen}
          title={"Delete Entity"}
          tooltipMessage={"Delete Impacts (Spreadsheets, Xero Data) "}
        />
      )}

      <div style={{ width: "100%" }}>
        {!isFetching && data.length !== 0 && (
          <TableListCard
            title={title}
            subtitle={subtitle}
            addTitle={"Add Entity"}
            setModalOpen={setModalOpen}
            setType={setType}
            buttonId={"addEntity"}
            titleId={"entityList"}
          >
            <Grid container>
              <Grid xs={12} item>
                <TableContainer
                  style={{
                    maxHeight: "53vh",
                    overflowY: "auto",
                    maxWidth: {
                      xs: 260,
                      sm: 290,
                      md: 580,
                      lg: "inherit",
                    },
                    border: `1px solid ${ColorPalette.borderColor}`,
                    borderRadius: "8px",
                  }}
                >
                  <Table>
                    <TableHead
                      style={{
                        position: "sticky",
                        top: "0",
                        zIndex: 1,
                        backgroundColor: ColorPalette.backgroundColor,
                      }}
                    >
                      <TableRow
                        sx={{
                          "*": {
                            paddingX: 3,
                          },
                        }}
                      >
                        <TableCell className="table-header">Name</TableCell>
                        <TableCell className="table-header">Type</TableCell>
                        <TableCell className="table-header"></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data?.map((row, index) => (
                        <TableRow
                          key={row.name + index}
                          sx={{
                            "&:last-child td, &:last-child th": {
                              border: 0,
                            },
                            "*": {
                              paddingX: 3,
                            },
                          }}
                        >
                          <TableCell component="th" scope="row" width={"55%"}>
                            {row.name}
                          </TableCell>
                          <TableCell width={"25%"}>
                            {toTitleCase(row.type)}
                          </TableCell>
                          <TableCell scope="row" align="right" width={"20%"}>
                            <ButtonComponent
                              size="small"
                              title={"View"}
                              disabled={
                                row.isOnboarded ? !row.xeroRefresh : false
                              }
                              onClick={() => {
                                localStorage.removeItem("currentEntityId");
                                localStorage.setItem("currentEntityId", row.id);
                                let route = `/accountingFirm/${accountingFirmId}/clientSpace/${clientSpaceId}/entity/${row.id}`;
                                row.isOnboarded
                                  ? navigate(route + "/spreadSheets")
                                  : navigate(route + "/onboarding");
                              }}
                            />
                            {
                              // stateData.role === "ADMIN" &&
                              <Fragment>
                                <IconButton
                                  id="button-clientEntityView"
                                  sx={{
                                    height: 40,
                                    width: 40,
                                  }}
                                  color="inherit"
                                  onClick={(event) =>
                                    handleMenuItemClick(event, row)
                                  }
                                >
                                  <MoreVertOutlined
                                    style={{
                                      color: "grey",
                                      fontSize: 20,
                                    }}
                                  />
                                </IconButton>
                                <Menu
                                  id="long-menu"
                                  MenuListProps={{
                                    "aria-labelledby": "long-button",
                                  }}
                                  anchorEl={anchorEl?.[row?.id]}
                                  open={Boolean(anchorEl?.[row?.id])}
                                  onClose={handleMenuItemClose}
                                >
                                  <MenuItem>
                                    <Typography
                                      className="menuItemTypography"
                                      onClick={handleEditModal}
                                    >
                                      <EditOutlinedIcon className="mr-10" />
                                      Edit
                                    </Typography>
                                  </MenuItem>
                                  <MenuItem>
                                    <Typography
                                      style={{ color: "#D33030" }}
                                      className="menuItemTypography"
                                      onClick={handleDeleteModal}
                                    >
                                      <DeleteOutlineOutlinedIcon className="mr-10" />
                                      Delete
                                    </Typography>
                                  </MenuItem>
                                </Menu>
                              </Fragment>
                            }
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </TableListCard>
        )}
      </div>
    </div>
  );
}

import React from "react";
import CustomModal from "./CustomModal";
import { Typography, Grid, IconButton, Tooltip } from "@mui/material";
import { ColorPalette } from "../../constants/colorPalette";
import { Close, Info } from "@mui/icons-material";
import { ButtonComponent } from "../Button/Button";

export default function ConfirmModal(props) {
  const {
    message,
    confirmText,
    declineText,
    confirmFunction,
    declineFunction,
    modalOpen,
    setModalOpen,
    singleButton = false,
    justifyRight = false,
    clearType,
    modalClose = () => {},
    confirmDisabled = false,
    title,
    tooltipMessage,
    buttonVariant,
    warningMessage,
    // onClose,
  } = props;

  const onClose = () => {
    setModalOpen(clearType === "nullable" ? null : false);
    modalClose();
  };

  return (
    <CustomModal
      modalOpen={modalOpen}
      clearType={clearType}
      minWidth="50vh"
      maxWidth="md"
      setModalOpen={setModalOpen}
      onClose={onClose}
      overflow="hidden"
    >
      <div>
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Grid
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "5px",
            }}
          >
            <Typography
              fontSize={20}
              fontWeight="bold"
              className="header-color"
            >
              {" "}
              {title}{" "}
            </Typography>
            {tooltipMessage && (
              <Tooltip
                title={<p style={{ fontSize: "15px" }}>{tooltipMessage} </p>}
              >
                <Info />
              </Tooltip>
            )}
          </Grid>
          <IconButton
            onClick={() => {
              setModalOpen(clearType === "nullable" ? null : false);
              modalClose();
            }}
          >
            <Close />
          </IconButton>
        </Grid>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Typography
            className="mt-10"
            variant="h6"
            sx={{
              marginBottom: "20px",
              color: ColorPalette.gray,
            }}
          >
            {message}
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {warningMessage !== null && (
            <Typography
              variant="caption"
              sx={{
                marginBottom: "15px",
                color: ColorPalette.error,
              }}
            >
              {warningMessage}
            </Typography>
          )}
        </div>
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: justifyRight ? "right" : "center",
            marginTop: "3px",
            marginBottom: "2px",
          }}
        >
          {!singleButton && (
            <Grid item>
              {/* <Button
                fullwidth
                id="button-confirmModalNo"
                onClick={declineFunction}
                sx={{
                  border: "1px solid #1976D2",
                  text: ColorPalette.white,
                  minWidth: "100px",
                }}
              >
                <Typography sx={{ paddingX: "20px" }}>
                  {declineText ?? "No"}
                </Typography>
              </Button> */}
              <ButtonComponent
                fullwidth
                onClick={declineFunction}
                title={declineText ?? "No"}
              />
            </Grid>
          )}

          <Grid item className="ml-20">
            <ButtonComponent
              disabled={confirmDisabled}
              contained={buttonVariant ? false : true}
              onClick={confirmFunction}
              title={confirmText ?? "Yes"}
            />
            {/* <Button
              disabled={confirmDisabled}
              id="button-confirmModalYes"
              variant={buttonVariant ? false : true}
              sx={{
                marginRight: "10px",
                minWidth: "100px",
              }}
              onClick={confirmFunction}
            >
           
              <Typography sx={{ paddingX: "20px" }}>
                {confirmText ?? "Yes"}
              </Typography>
            </Button> */}
          </Grid>
        </Grid>
      </div>
    </CustomModal>
  );
}

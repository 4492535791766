import React from "react";
import TableListCard from "../../../../components/TableListCard/TableListCard";
import {
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { TableContainer } from "@material-ui/core";
import { ColorPalette } from "../../../../constants/colorPalette";
import { toTitleCase } from "../../../../helper/titleCaseConverter";
import { DeleteOutline, EditOutlined } from "@mui/icons-material";
import { ButtonComponent } from "../../../../components/Button/Button";

const InviteTable = ({
  inviteModalOpen,
  setInviteModalOpen,
  setEditModalOpen,
  setType,
  invitedUsers,
  setDeleteModalOpen,
  setDeleteId,
  setUpdateId,
  setResendConfirmModal,
  stateData,
}) => {
  return (
    <div>
      <TableListCard
        title={" Users"}
        addTitle={"Invite Members"}
        modalOpen={inviteModalOpen}
        setModalOpen={setInviteModalOpen}
        setType={setType}
        titleId={"inviteMembersList"}
        buttonId={"inviteMembers"}
      >
        {invitedUsers.length === 0 ? (
          <Grid container justifyContent={"center"} sx={{ paddingTop: "30px" }}>
            <Grid item xs={12}>
              <Grid container justifyContent={"center"}>
                <Typography variant="h6">No Invitations Found</Typography>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid container>
            <Grid item xs={12} marginTop={2}>
              <div>
                <TableContainer
                  style={{
                    minWidth: "900px",
                    maxHeight: "53vh",
                    overflow: "auto",
                    maxWidth: {
                      xs: 260,
                      sm: 290,
                      md: 580,
                      lg: "inherit",
                    },
                    border: `1px solid ${ColorPalette.borderColor}`,
                    borderRadius: "8px",
                  }}
                >
                  <Table>
                    <TableHead
                      style={{
                        position: "sticky",
                        top: "0",
                        zIndex: 1,
                        backgroundColor: ColorPalette.backgroundColor,
                        border: "1",
                      }}
                    >
                      <TableRow
                        sx={{
                          "*": {
                            paddingX: 3,
                          },
                        }}
                      >
                        {["Email", "Name", "Status", "Role"].map(
                          (headers, index) => (
                            <TableCell className="table-header" key={index}>
                              {headers}
                            </TableCell>
                          )
                        )}
                        {
                          // stateData.role === "ADMIN" &&
                          <TableCell className="table-header" align="right">
                            Actions
                          </TableCell>
                        }
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {invitedUsers?.map((row, index) => (
                        <TableRow
                          key={row.email + index}
                          sx={{
                            "&:last-child td, &:last-child th": {
                              border: 0,
                            },
                          }}
                        >
                          <TableCell width={"30%"} component="th" scope="row">
                            {row?.email}
                          </TableCell>
                          <TableCell>
                            {row?.first_name
                              ? `${row?.first_name} ${row?.last_name}`
                              : "__"}
                          </TableCell>
                          <TableCell>
                            {row?.hasJoined ? (
                              <ButtonComponent
                                title={"Success"}
                                size={"small"}
                                contained={true}
                                color={ColorPalette.successColor}
                              />
                            ) : (
                              <ButtonComponent
                                title={"Pending"}
                                size={"small"}
                                contained={true}
                                color={ColorPalette.backgroundColor}
                              />
                            )}
                          </TableCell>
                          <TableCell>{toTitleCase(row?.role)}</TableCell>
                          {
                            // stateData.role === "ADMIN" &&
                            <TableCell scope="row" align="right" width={"20%"}>
                              <Grid
                                display={"flex"}
                                alignItems={"center"}
                                justifyContent={"right"}
                                mr={2}
                              >
                                {!row?.hasJoined ? (
                                  <ButtonComponent
                                    size={"small"}
                                    contained={true}
                                    color={ColorPalette.backgroundColor}
                                    title={"Cancel"}
                                    onClick={() => {
                                      setDeleteModalOpen(true);
                                      setDeleteId(row?._id);
                                    }}
                                  />
                                ) : (
                                  <Grid>
                                    <IconButton>
                                      <EditOutlined
                                        onClick={() => {
                                          setEditModalOpen(true);
                                          setUpdateId(row?._id);
                                        }}
                                      />
                                    </IconButton>
                                    <IconButton
                                      onClick={() => {
                                        setDeleteId(row?._id);
                                        setDeleteModalOpen(true);
                                      }}
                                    >
                                      <DeleteOutline color="error" />
                                    </IconButton>
                                  </Grid>
                                )}
                              </Grid>
                            </TableCell>
                          }
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </Grid>
          </Grid>
        )}
      </TableListCard>
    </div>
  );
};

export default InviteTable;

import {
  Button,
  FormControl,
  Grid,
  Modal,
  Snackbar,
  Alert,
  TextField,
  Select,
  MenuItem,
  Tab,
  Typography,
  IconButton,
  Autocomplete,
  Checkbox,
  Menu,
  Divider,
} from "@mui/material";
import { Box } from "@mui/system";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import Nestable from "react-nestable";
import { v4 as uuidv4 } from "uuid";
import { InputLabel } from "@mui/material";
import Loading from "../../../components/LoadingIndicator/Loading";
import {
  Close,
  DeleteOutline,
  EditOutlined,
  Info,
  KeyboardArrowRight,
  KeyboardArrowDown,
  MoreVertOutlined,
  Visibility,
  VisibilityOff,
  Add,
} from "@mui/icons-material";
import "./Settings.css";
import {
  groupAccountTypeData,
  moveToActive,
  saveAccountTypeFormat,
} from "../../../redux/Setting/SettingSlice";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import "react-nestable/dist/styles/index.css";
import cloneDeep from "lodash/cloneDeep";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import ConfirmModal from "../../../components/CustomModal/ConfirmModal";
import { useParams } from "react-router-dom";
import CustomModal from "../../../components/CustomModal/CustomModal";
import { ColorPalette } from "../../../constants/colorPalette";
import _ from "lodash";
import { defaultTypeMap } from "./coaTypes";
import { getEntity } from "../../../redux/ClientSpace/ClientSpaceSlice";
import FinancialSettings from "./components/FinancialSettings";
import { Tooltip } from "@material-ui/core";
// import RefreshXeroModal from "./components/modals/refreshXeroModal/RefreshXeroModal";
import XeroSettingsTab from "./XeroSettingsTab/XeroSettingsTab";
import { TabContainer } from "../../../components/TabContainer/TabContainer";
import { ButtonComponent } from "../../../components/Button/Button";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "white",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

export default function Settings() {
  const dispatch = useDispatch();
  const { entityId, accountingFirmId } = useParams();
  useEffect(() => {
    dispatch(
      groupAccountTypeData({
        entityId,
        accountingFirmId,
      })
    );
    dispatch(
      getEntity({
        entityId,
        accountingFirmId,
      })
    );
    // eslint-disable-next-line
  }, []);

  const [value, setValue] = useState("1");
  const [settingsTab, setSettingsTab] = useState("1");
  const [plValue, setPlValue] = useState("1");
  const [plArchivedValue, setPlArchivedValue] = useState(false);
  const [bsValue, setBsValue] = useState("1");
  const [isError, setError] = useState("");
  const [valuesToGroup, setValuesToGroup] = useState([]);
  const [groupName, setGroupName] = useState("");
  // const [collapseAll, setCollapseAll] = useState(true);
  const [groupOpen, setGroupOpen] = useState("");
  const [groupType, setGroupType] = useState("");
  const [bsItems, setBsItems] = useState([]);
  const [plItems, setPlItems] = useState([]);
  const [nameSwapModal, setNameSwapModal] = useState("");
  const [groupSwapModal, setGroupSwapModal] = useState("");
  const [nameSwapModalBS, setNameSwapModalBS] = useState("");
  const [groupSwapModalBS, setGroupSwapModalBS] = useState("");
  const [revertModalOpen, setRevertModalOpen] = useState("");
  const [moveToType, setMoveToType] = useState("");
  const [moveToGroup, setMoveToGroup] = useState("");
  const [collapsedPL, setCollapsedPL] = useState([]);
  const [collapsedBS, setCollapsedBS] = useState([]);
  const [editConfirmModalOpen, setEditConfirmModalOpen] = useState(false);
  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);
  const [saveChangesModalOpen, setSaveChangesModalOpen] = useState(false);
  const [confirmModalData, setConfirmModalData] = useState({});
  const [confirmEditModalData, setConfirmEditModalData] = useState({});
  const [editTextFieldValue, setEditTextFieldValue] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [plRef, setPlRef] = useState(null);
  //
  // const [refreshXeroModal, setRefreshXeroModal] = useState(false);
  const [movetoActiveFlag, setMoveToActiveFlag] = useState(false);
  const [moveToActiveIds, setMoveToActiveIds] = useState([]);
  const [enable, setEnable] = useState("");
  const { data, hiddenAccountList, isXeroRefreshing } = useSelector(
    (state) => state.setting
  );
  const { currentEntity, xeroTenants, isFetching } = useSelector(
    (state) => state.clientSpaces
  );

  useEffect(() => {
    if (data && data.pl) {
      setPlItems(data.pl);
    }
    if (data && data.bs) {
      setBsItems(data.bs);
    }
  }, [data]);
  let bsRef;
  const collapsePl = (val) => {
    plRef?.collapse(val);
  };
  const collapseBs = (val) => {
    bsRef?.collapse(val);
  };
  useEffect(() => {
    let plVal = [];
    plItems?.forEach((item) => {
      if (item?.children?.length !== 0) {
        if (item.collapsed) plVal.push(item.id);
        item?.children?.forEach((subItem) => {
          if (subItem?.children?.length) {
            if (subItem.collapsed) plVal.push(subItem.id);
          }
        });
      }
    });
    setCollapsedPL(plVal);
  }, [plItems, value, plValue]);

  useEffect(() => {
    let bsVal = [];
    bsItems?.forEach((item) => {
      if (item?.children?.length !== 0) {
        if (item.collapsed) bsVal.push(item.id);
        item?.children.forEach((subItem) => {
          if (subItem?.children?.length) {
            if (subItem.collapsed) bsVal.push(subItem.id);
          }
        });
      }
    });
    setCollapsedBS(bsVal);
  }, [bsItems, value, bsValue]);

  useEffect(() => {
    collapsePl(collapsedPL);
    // eslint-disable-next-line
  }, [collapsedPL, plRef]);
  useEffect(() => {
    collapseBs(collapsedBS);
    // eslint-disable-next-line
  }, [collapsedBS, value, bsValue]);

  const defaultValues = data;

  const plOptions = [];
  const bsOptions = [];
  plItems?.forEach((item) => {
    const accountNames = item.children
      ?.filter((child) => child.type === "Account Name")
      .map((val) => {
        return {
          ...val,
          group: item.text,
        };
      });
    plOptions.push(...(accountNames || []));
  });
  bsItems?.forEach((item) => {
    const accountNames = item.children
      .filter((child) => child.type === "Account Name")
      .map((val) => {
        return {
          ...val,
          group: item.text,
        };
      });
    bsOptions.push(...accountNames);
  });
  const Handler = () => {
    return (
      <div className="d-flex-center handler-pointer">
        <DragIndicatorIcon />
      </div>
    );
  };
  const handleChange = (event, newValue, type) => {
    if (type === "main") {
      setValue(newValue);
    } else if (type === "pl") {
      setPlValue(newValue);
    } else {
      setBsValue(newValue);
    }
  };
  const [inputFields, setInputFields] = useState({
    financialMonthEnd: "",
    taxRate: "",
    registeredForGST: "",
    employees: "",
    monthlyIAS: "",
  });

  const handleSubmit = () => {};
  const handleDelete = () => {
    const { data, item, setData } = confirmModalData;
    let val = cloneDeep(data);
    data?.forEach((b, index) => {
      if (b.children.map((a) => a.text).indexOf(item.text) !== -1) {
        let childTBR =
          val[index].children[b.children.map((a) => a.text).indexOf(item.text)];
        val[index].children.splice(
          b.children.map((a) => a.text).indexOf(item.text),
          1
        );
        if (childTBR?.children?.length !== undefined) {
          val[index].children.unshift(...childTBR.children);
        }
      }
    });
    setData(val);
    setConfirmDeleteModalOpen(false);
  };
  const handleEdit = () => {
    const { data, item, setData, type } = confirmEditModalData;
    let val = cloneDeep(data);
    const newValue = editTextFieldValue;
    if (type === "name") {
      val?.forEach((b, outerIndex) => {
        if (b.children.map((a) => a.text).indexOf(item.text) !== -1) {
          val[outerIndex].children.forEach((a, innerIndex) => {
            if (a.id === item.id) {
              val[outerIndex].children[innerIndex]["text"] = newValue;
            }
          });
        }
      });
    } else {
      val.forEach((data, index) => {
        if (data.id === item.id) {
          val[index]["reportingName"] = newValue;
        }
      });
    }
    dispatch(
      saveAccountTypeFormat({
        id: entityId,
        data: val,
        type: "pl",
        enabled: enable,
        accountingFirmId,
      })
    );
    setData(val);
    setEditConfirmModalOpen(false);
  };

  const renderItemPL = (props) => {
    const { item, collapseIcon, handler, lastItem, depth } = props;
    return (
      <div
        style={{
          fontWeight: item.children.length ? "700" : "400",
          paddingLeft: depth === 0 ? "" : depth === 1 ? "30px" : "60px",
          borderBottom: !lastItem ? "2px solid #E2E8F0" : "",
        }}
        className="d-flex"
      >
        {handler}
        <Grid container>
          <Grid item xs={7}>
            <div
              style={{
                margin: ".5rem",
              }}
            >
              {item.text}
              {!item.collapsed && item?.children?.length !== 0 && (
                <IconButton
                  id="button-collapseUp"
                  onClick={() => {
                    let cloneData = cloneDeep(plItems);
                    if (item.type === "Account Type") {
                      const ids = plItems.map((item) => item.id);
                      cloneData[ids.indexOf(item.id)]["collapsed"] = true;
                    } else {
                      const data = plItems
                        .map((mainItem, index) => {
                          let subIndex = mainItem?.children
                            ?.map((item) => item.id)
                            ?.indexOf(item.id);

                          return subIndex !== -1 ? { index, subIndex } : null;
                        })
                        .filter((val) => val !== null)[0];
                      cloneData[data.index].children[data.subIndex][
                        "collapsed"
                      ] = true;
                    }
                    setPlItems(cloneData);
                    collapseIcon.props.onClick();
                  }}
                >
                  <KeyboardArrowDown />
                </IconButton>
              )}

              {item.collapsed && item?.children?.length !== 0 && (
                <IconButton
                  id="button-collapseDown"
                  onClick={() => {
                    let cloneData = cloneDeep(plItems);
                    if (item.type === "Account Type") {
                      const ids = plItems.map((item) => item.id);
                      cloneData[ids.indexOf(item.id)]["collapsed"] = false;
                    } else {
                      const data = plItems
                        .map((mainItem, index) => {
                          let subIndex = mainItem?.children
                            ?.map((item) => item.id)
                            ?.indexOf(item.id);

                          return subIndex !== -1 ? { index, subIndex } : null;
                        })
                        ?.filter((val) => val !== null)[0];
                      cloneData[data?.index].children[data?.subIndex][
                        "collapsed"
                      ] = false;
                    }
                    setPlItems(cloneData);
                    collapseIcon.props.onClick();
                  }}
                >
                  <KeyboardArrowRight />
                </IconButton>
              )}
            </div>
          </Grid>
          <Grid item xs={5}>
            <Grid container justifyContent={"flex-end"}>
              <Grid item xs={3.5}>
                <div
                  style={{
                    margin: ".5rem",
                    padding: ".5rem",
                    width: "150px",
                    display: "flex",
                    marginLeft:
                      depth === 0 ? 0 : depth === 1 ? "-7.5px" : "-12.5px",
                    justifyContent: "center",
                    backgroundColor:
                      item.type === "Account Type"
                        ? "#DDE0FF"
                        : item.type === "Account Group"
                        ? "#FFF3C7"
                        : "#CDE9FF",
                    borderRadius: "7px",
                    color:
                      item.type === "Account Type"
                        ? "#3948CB"
                        : item.type === "Account Group"
                        ? "#926B0F"
                        : "#0042CB",
                    fontSize: "14px",
                  }}
                >
                  {item.type}
                </div>
              </Grid>
              <Grid item xs={3}>
                <Grid container>
                  {item.type === "Account Type" && (
                    <Grid item xs={3}>
                      <IconButton
                        size="small"
                        className="mt-10"
                        onClick={() => {
                          setEditTextFieldValue(
                            item?.reportingName || item.text
                          );
                          setConfirmEditModalData({
                            data: plItems,
                            item: item,
                            setData: setPlItems,
                            type: "reportingName",
                          });
                          setEditConfirmModalOpen(true);
                          setEnable("edit-reportingName");
                        }}
                      >
                        <EditOutlined />
                      </IconButton>
                    </Grid>
                  )}

                  <Grid item xs={6}>
                    {item.type === "Account Group" && (
                      <Grid container>
                        <Grid item xs={6}>
                          <IconButton
                            size="small"
                            onClick={() => {
                              setEditTextFieldValue(item.text);
                              setConfirmEditModalData({
                                data: plItems,
                                item: item,
                                setData: setPlItems,
                                type: "name",
                              });
                              setEditConfirmModalOpen(true);
                              setEnable("edit-accountGroup");
                            }}
                          >
                            <EditOutlined />
                          </IconButton>
                        </Grid>
                        <Grid item xs={6}>
                          <IconButton
                            size="small"
                            onClick={() => {
                              setConfirmModalData({
                                data: plItems,
                                item: item,
                                setData: setPlItems,
                              });
                              setConfirmDeleteModalOpen(true);
                            }}
                          >
                            <DeleteOutline />
                          </IconButton>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                  <Grid item xs={3}>
                    {item.type !== "Account Type" && (
                      <IconButton
                        id="button-swapAccountName"
                        onClick={() => {
                          if (item.type === "Account Group") {
                            setGroupSwapModal(item.id);
                          } else {
                            setNameSwapModal(item.id);
                          }
                        }}
                      >
                        <SwapVertIcon />
                      </IconButton>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  };
  const renderItemBS = (props) => {
    const { item, collapseIcon, handler } = props;
    return (
      <div
        style={{ fontWeight: item.children.length ? "700" : "400" }}
        className="d-flex"
      >
        {handler}
        <Grid container>
          <Grid item>
            <div
              style={{
                margin: ".5rem",
                padding: ".5rem",
              }}
            >
              {item.text}
            </div>
          </Grid>
          <Grid item>
            <div
              style={{
                margin: ".5rem",
                padding: ".5rem",
                width: "120px",
                display: "flex",
                justifyContent: "center",
                backgroundColor:
                  item.type === "Account Type"
                    ? "#DDE0FF"
                    : item.type === "Account Group"
                    ? "#FFF3C7"
                    : "#CDE9FF",
                borderRadius: "7px",
                color:
                  item.type === "Account Type"
                    ? "#3948CB"
                    : item.type === "Account Group"
                    ? "#926B0F"
                    : "#0042CB",
                fontSize: "14px",
              }}
            >
              {item.type}
            </div>
          </Grid>
          {!item.collapsed && item?.children?.length !== 0 && (
            <IconButton
              id="button-collapseUp"
              onClick={() => {
                let cloneData = cloneDeep(bsItems);
                if (item.type === "Account Type") {
                  const ids = bsItems.map((item) => item.id);
                  cloneData[ids.indexOf(item.id)]["collapsed"] = true;
                } else {
                  const data = bsItems
                    .forEach((mainItem, index) => {
                      let subIndex = mainItem?.children
                        ?.map((item) => item.id)
                        ?.indexOf(item.id);
                      if (subIndex !== -1) {
                        return { index, subIndex };
                      }
                    })
                    .filter((val) => val !== undefined)[0];
                  cloneData[data.index].children[data.subIndex][
                    "collapsed"
                  ] = true;
                }
                setBsItems(cloneData);
                collapseIcon.props.onClick();
              }}
            >
              <KeyboardArrowDown />
            </IconButton>
          )}
          {item.collapsed && item?.children?.length !== 0 && (
            <IconButton
              id="button-collpaseDown"
              onClick={() => {
                let cloneData = cloneDeep(bsItems);
                if (item.type === "Account Type") {
                  const ids = bsItems.map((item) => item.id);
                  cloneData[ids.indexOf(item.id)]["collapsed"] = false;
                } else {
                  const data = bsItems
                    .forEach((mainItem, index) => {
                      let subIndex = mainItem?.children
                        ?.map((item) => item.id)
                        ?.indexOf(item.id);
                      if (subIndex !== -1) {
                        return { index, subIndex };
                      }
                    })
                    .filter((val) => val !== undefined)[0];
                  cloneData[data.index].children[data.subIndex][
                    "collapsed"
                  ] = false;
                }
                setBsItems(cloneData);
                collapseIcon.props.onClick();
              }}
            >
              <KeyboardArrowRight />
            </IconButton>
          )}
          {item.type === "Account Type" && (
            <Grid item>
              <IconButton
                size="small"
                onClick={() => {
                  setEditTextFieldValue(item?.reportingName || item.text);
                  setConfirmEditModalData({
                    data: bsItems,
                    item: item,
                    setData: setBsItems,
                    type: "reportingName",
                  });
                  setEditConfirmModalOpen(true);
                  setEnable("edit");
                }}
              >
                <EditOutlined />
              </IconButton>
            </Grid>
          )}
          {item.type !== "Account Type" && (
            <Grid item>
              <IconButton
                id="button-swapAccountGroup"
                onClick={() => {
                  if (item.type === "Account Group") {
                    setGroupSwapModalBS(item.id);
                  } else {
                    setNameSwapModalBS(item.id);
                  }
                }}
              >
                <SwapVertIcon />
              </IconButton>
            </Grid>
          )}
          <Grid item>
            <div
              style={{
                width: "80%",
                display: "flex",
                justifyContent: "right",
              }}
            >
              {item.type === "Account Group" && (
                <>
                  <IconButton
                    size="small"
                    onClick={() => {
                      setEditTextFieldValue(item.text);
                      setConfirmEditModalData({
                        data: bsItems,
                        item: item,
                        setData: setBsItems,
                        type: "name",
                      });
                      setEditConfirmModalOpen(true);
                      setEnable("edit");
                    }}
                  >
                    <EditOutlined />
                  </IconButton>
                  <IconButton
                    size="small"
                    onClick={() => {
                      setConfirmModalData({
                        data: bsItems,
                        item: item,
                        setData: setBsItems,
                      });
                      setConfirmDeleteModalOpen(true);
                    }}
                  >
                    <DeleteOutline />
                  </IconButton>
                </>
              )}
            </div>
          </Grid>
        </Grid>
      </div>
    );
  };
  const getAccountGroupList = () => {
    return plItems ? (
      plItems[plItems?.map((a) => a.id)?.indexOf(moveToType)]?.children
        ?.filter((item) => item.type === "Account Group")
        ?.map((item) => {
          return (
            <MenuItem key={item.id} value={item.id}>
              {item.text}
            </MenuItem>
          );
        })
    ) : (
      <></>
    );
  };
  const getAccountGroupListBS = () => {
    return bsItems ? (
      bsItems[bsItems?.map((a) => a.id)?.indexOf(moveToType)]?.children
        ?.filter((item) => item.type === "Account Group")
        ?.map((item) => {
          return (
            <MenuItem key={item.id} value={item.id}>
              {item.text}
            </MenuItem>
          );
        })
    ) : (
      <></>
    );
  };

  const handleFormChange = (index, event) => {
    let data = [...inputFields];
    data[index][event.target.name] = event.target.value;
    setInputFields(data);
  };

  const handleCheckBoxSelect = (id) => {
    let clone = _.cloneDeep(moveToActiveIds);
    let idIndex = moveToActiveIds.indexOf(id);
    if (idIndex === -1) {
      clone.push(id);
    } else {
      clone.splice(idIndex, 1);
    }
    setMoveToActiveIds(clone);
  };

  const handleMoveToActive = () => {
    dispatch(
      moveToActive({
        id: entityId,
        ids: moveToActiveIds,
        type: "pl",
        accountingFirmId,
      })
    );
  };
  let plGroup = hiddenAccountList?.pl
    ? Object?.groupBy(hiddenAccountList?.pl, ({ type }) => defaultTypeMap[type])
    : {};
  // eslint-disable-next-line
  let bsGroup = hiddenAccountList?.bs
    ? Object?.groupBy(hiddenAccountList?.bs, ({ type }) => defaultTypeMap[type])
    : {};

  const saveChangesConfirmFunc = () => {
    dispatch(
      saveAccountTypeFormat({
        id: entityId,
        data: plItems,
        type: "pl",
        accountingFirmId,
        enabled: enable,
      })
    );
    setSaveChangesModalOpen(false);
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const tabValues = [
    {
      label: "Chart Of Accounts",
      value: "1",
    },
    { label: "Financial Settings", value: "2" },
    { label: "Xero Settings", value: "3" },
  ];
  const subTabValues = [
    {
      label: "Active",
      value: "1",
    },
    { label: "Inactive", value: "2" },
  ];

  return (
    <div style={{ margin: "1%" }}>
      {/*modal for save changes in ps */}
      {saveChangesModalOpen && (
        <ConfirmModal
          modalOpen={saveChangesModalOpen}
          setModalOpen={setSaveChangesModalOpen}
          message="Are you sure you want to save current changes? This cannot be undone"
          confirmFunction={saveChangesConfirmFunc}
          declineFunction={() => setSaveChangesModalOpen(false)}
        />
      )}

      {/*modal for revert changes in ps */}
      <ConfirmModal
        modalOpen={revertModalOpen}
        setModalOpen={() => setRevertModalOpen("")}
        message="Are you sure you want to revert the current changes? This cannot be undone."
        confirmFunction={() => {
          if (revertModalOpen === "bs") {
            setBsItems(defaultValues.bs);
          } else {
            setPlItems(defaultValues.pl);
          }
          setRevertModalOpen("");
        }}
        declineFunction={() => {
          setRevertModalOpen("");
        }}
      />

      {/*modal forto check the xero status */}
      {/* {checkStatusModalOpen && (
        <CheckStatusModal
          modalOpen={checkStatusModalOpen}
          setModalOpen={setCheckStatusModalOpen}
        />
      )} */}

      <Box sx={{ width: "75%", typography: "body1" }}>
        {!isFetching ? (
          <TabContext value={settingsTab}>
            <Box sx={{ borderBottom: 0, borderColor: "divider" }}>
              <TabContainer
                tabs={tabValues}
                value={settingsTab}
                onChange={(event, newValue) => setSettingsTab(newValue)}
              />
              <TabPanel
                value="1"
                style={{ paddingRight: "0px", paddingLeft: "0px" }}
              >
                <div className="coa-container">
                  <TabContext value={value}>
                    <Grid container>
                      <Grid item xs={6}>
                        <TabList
                          textColor="inherit"
                          onChange={(event, value) =>
                            handleChange(event, value, "main")
                          }
                          TabIndicatorProps={{
                            style: { background: ColorPalette.primary },
                          }}
                        >
                          <Tab
                            id="button-profitAndLoss"
                            label="Profit & Loss"
                            value="1"
                            style={{
                              textTransform: "none",
                              fontSize: "16px",
                              fontWeight: value === "1" ? "bold" : "",
                            }}
                          />
                          <Tab
                            disabled
                            id="button-balanceShheet"
                            label="Balance Sheet"
                            value="2"
                            style={{
                              textTransform: "none",
                              fontSize: "16px",
                              fontWeight: value === "2" ? "bold" : "",
                            }}
                          />
                        </TabList>
                      </Grid>
                    </Grid>
                    <Grid container className="p-t-20">
                      <Grid item xs={6}>
                        <Grid container>
                          <Grid
                            item
                            // style={{ borderRight: "2.5px solid grey" }}
                          >
                            <Grid container>
                              <TabContext>
                                <TabContainer
                                  tabs={subTabValues}
                                  value={plValue}
                                  onChange={() => {
                                    setPlValue(plValue === "1" ? "2" : "1");
                                    setMoveToActiveFlag(false);
                                  }}
                                />
                              </TabContext>
                              {/* <Typography>Active</Typography>
                              <CustomSwitch
                                check={plValue === "2"}
                                handleCheck={() => {
                                  setPlValue(plValue === "1" ? "2" : "1");
                                  setMoveToActiveFlag(false);
                                }}
                              />
                              <Typography>Inactive</Typography> */}
                              <Tooltip
                                className="mt-10 ml-15"
                                title={
                                  <h2>
                                    Inactive accounts are those that have not
                                    had any transactions yet in Xero
                                  </h2>
                                }
                              >
                                <Info />
                              </Tooltip>
                            </Grid>
                          </Grid>

                          <Grid item>
                            <Grid container>
                              <IconButton
                                className="mt-5"
                                size="small"
                                aria-label="more"
                                id="long-button"
                                aria-controls={open ? "coa-menu" : undefined}
                                aria-expanded={open ? "true" : undefined}
                                aria-haspopup="true"
                                onClick={handleClick}
                              >
                                <MoreVertOutlined />
                              </IconButton>
                              <Menu
                                id="coa-menu"
                                MenuListProps={{
                                  "aria-labelledby": "long-button",
                                }}
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                              >
                                <MenuItem
                                  key={"check-status"}
                                  onClick={() => {
                                    handleClose();
                                    setPlArchivedValue(!plArchivedValue);
                                  }}
                                >
                                  {!plArchivedValue ? (
                                    <VisibilityOff className="mr-10" />
                                  ) : (
                                    <Visibility className="mr-10" />
                                  )}
                                  {plArchivedValue ? "Show " : "Hide "}
                                  Archived
                                </MenuItem>
                                {/* <MenuItem
                                  key={"check-status"}
                                  onClick={() => {
                                    setCheckStatusModalOpen(true);
                                    handleClose();
                                  }}
                                >
                                  <CheckOutlined className="mr-10" /> Check
                                  Status
                                </MenuItem> */}
                              </Menu>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={6}>
                        {plValue === "2" && (
                          <Grid container justifyContent={"flex-end"}>
                            {!movetoActiveFlag && (
                              <ButtonComponent
                                variant="outlined"
                                onClick={() => {
                                  setMoveToActiveFlag(true);
                                }}
                                title={" Move to Active"}
                              ></ButtonComponent>
                            )}

                            {movetoActiveFlag && (
                              <div>
                                {" "}
                                <Button
                                  variant="outlined"
                                  onClick={() => {
                                    setMoveToActiveFlag(false);
                                    setMoveToActiveIds([]);
                                  }}
                                >
                                  Cancel
                                </Button>
                                <Button
                                  className="ml-10"
                                  variant="contained"
                                  disabled={moveToActiveIds.length === 0}
                                  onClick={() => {
                                    handleMoveToActive();
                                    setMoveToActiveFlag(false);
                                    setMoveToActiveIds([]);
                                  }}
                                >
                                  Save
                                </Button>
                              </div>
                            )}
                          </Grid>
                        )}
                        <Grid container justifyContent={"flex-end"}>
                          {" "}
                          {plValue === "1" && (
                            <ButtonComponent
                              startIcon={<Add />}
                              id="button-createGroupModal"
                              style={{ marginLeft: "20px" }}
                              variant="outlined"
                              onClick={() => {
                                setGroupOpen(true);
                                setEnable("create");
                              }}
                              title={" Group"}
                            ></ButtonComponent>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>

                    <TabPanel
                      value="1"
                      style={{ paddingRight: "0px", paddingLeft: "0px" }}
                    >
                      <div style={{ padding: "0px" }}>
                        <header>
                          <Grid
                            container
                            display={plValue === "1" ? "block" : "none"}
                          >
                            <Grid
                              item
                              xs={12}
                              display={"flex"}
                              justifyContent={"right"}
                            ></Grid>
                          </Grid>
                        </header>

                        <TabContext value={plValue}>
                          <Box
                            sx={{
                              borderBottom: 0,
                              borderColor: "divider",
                              paddingLeft: "30px",
                            }}
                          >
                            <Grid container></Grid>
                          </Box>
                          <TabPanel value="1" style={{ padding: "0px" }}>
                            <div>
                              {isError !== "" && (
                                <Snackbar
                                  open={isError !== ""}
                                  autoHideDuration={3000}
                                  onClose={() => {
                                    setError("");
                                  }}
                                >
                                  <Alert severity="error">{isError}</Alert>
                                </Snackbar>
                              )}

                              {/*  modal for create new acc group in pl  */}
                              <CustomModal
                                modalOpen={groupOpen !== ""}
                                onClose={() => {
                                  setGroupOpen("");
                                }}
                                setModalOpen={() => {
                                  setGroupOpen("");
                                }}
                                overflow={"auto"}
                              >
                                <Grid
                                  item
                                  xs={12}
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    marginBottom: "20px",
                                  }}
                                >
                                  <Grid
                                    item
                                    display={"flex"}
                                    alignItems={"center"}
                                    justifyContent={"space-between"}
                                  >
                                    <Typography
                                      style={{ padding: "10px" }}
                                      variant="h5"
                                    >
                                      Create new account group
                                    </Typography>

                                    <IconButton
                                      id="button-closeModalIcon"
                                      onClick={() => setGroupOpen("")}
                                    >
                                      <Close />
                                    </IconButton>
                                  </Grid>
                                  <Divider
                                    sx={{
                                      width: "100%",
                                      color: ColorPalette.danger,
                                    }}
                                  />
                                </Grid>
                                <Grid p={2}>
                                  <Grid
                                    container
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "space-between",
                                      gap: "5px",
                                      marginBottom: "20px",
                                    }}
                                  >
                                    <Grid item xs={5.8}>
                                      <InputLabel
                                        id="account-type"
                                        className="mb-10"
                                      >
                                        Account Type
                                      </InputLabel>
                                      <Select
                                        fullWidth
                                        size="small"
                                        id="input-accoutType"
                                        labelId="account-type"
                                        value={groupType}
                                        label="Account Group"
                                        onChange={(e) => {
                                          setGroupType(e.target.value);
                                        }}
                                      >
                                        {plItems?.map((item) => {
                                          return (
                                            <MenuItem
                                              key={item.id}
                                              value={item.id}
                                            >
                                              {item.text}
                                            </MenuItem>
                                          );
                                        })}
                                      </Select>
                                    </Grid>
                                    <Grid item xs={5.8}>
                                      <InputLabel
                                        id="account-type"
                                        className="mb-10"
                                      >
                                        Group Name
                                      </InputLabel>
                                      <TextField
                                        size="small"
                                        id="input-groupName"
                                        fullWidth
                                        value={groupName}
                                        onChange={({ target: { value } }) => {
                                          setGroupName(value);
                                        }}
                                      ></TextField>
                                    </Grid>
                                  </Grid>
                                  <Grid container xs={12} mb={4} mt={3}>
                                    <Autocomplete
                                      size="small"
                                      style={{ width: "100%" }}
                                      multiple
                                      disableCloseOnSelect
                                      isOptionEqualToValue={(option, value) =>
                                        option.id === value.id
                                      }
                                      onChange={(event, values) => {
                                        setValuesToGroup(values);
                                      }}
                                      id="grouped-account-type"
                                      options={plOptions}
                                      groupBy={(option) => option.group}
                                      getOptionLabel={(option) => option.text}
                                      sx={{ width: 300 }}
                                      renderInput={(params) => (
                                        <div>
                                          <InputLabel className="mb-10">
                                            With Accounts
                                          </InputLabel>
                                          <TextField
                                            id="input-withCategories"
                                            {...params}
                                          />
                                        </div>
                                      )}
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    display="flex"
                                    justifyContent={"right"}
                                    alignItems={"center"}
                                    gap={2}
                                    mb={1}
                                  >
                                    <ButtonComponent
                                      variant="outlined"
                                      onClick={() => {
                                        setGroupOpen("");
                                        setGroupName("");
                                        setGroupType("");
                                      }}
                                      title={"Cancel"}
                                    />
                                    <ButtonComponent
                                      id="button-createGroup"
                                      contained
                                      disabled={
                                        groupType === "" || groupName === ""
                                      }
                                      title={"Create Group"}
                                      onClick={() => {
                                        const change = cloneDeep(plItems);
                                        const id = uuidv4();
                                        valuesToGroup.forEach((a) => {
                                          change.forEach((b) => {
                                            const index = b.children
                                              .map((c) => c.id)
                                              .indexOf(a.id);
                                            if (index !== -1) {
                                              b.children.splice(index, 1);
                                            }
                                          });
                                        });
                                        change[
                                          plItems
                                            ?.map((item) => item.id)
                                            .indexOf(groupType)
                                        ].children.unshift({
                                          id: id,
                                          text: groupName,
                                          type: "Account Group",
                                          collapsed: true,
                                          children: valuesToGroup,
                                        });
                                        setGroupName("");
                                        setGroupType("");
                                        setGroupOpen("");
                                        setPlItems(change);
                                        setValuesToGroup([]);
                                        dispatch(
                                          saveAccountTypeFormat({
                                            id: entityId,
                                            data: change,
                                            type: "pl",
                                            enabled: enable,
                                            accountingFirmId,
                                          })
                                        );
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                              </CustomModal>
                              <div
                                style={{
                                  // marginLeft: "3rem",
                                  overflow: "auto",
                                  maxHeight: "60vh",
                                }}
                                className="nestable-border"
                              >
                                <Nestable
                                  items={[
                                    ...(plItems.map((parent) => {
                                      return {
                                        ...parent,
                                        children:
                                          parent?.children
                                            ?.map((child) => {
                                              if (
                                                child.type === "Account Name"
                                              ) {
                                                return (
                                                  plArchivedValue
                                                    ? child.status === "ACTIVE"
                                                    : true
                                                )
                                                  ? child
                                                  : null;
                                              } else {
                                                return {
                                                  ...child,
                                                  children: child.children
                                                    .map((subChild) => {
                                                      return (
                                                        plArchivedValue
                                                          ? subChild.status ===
                                                            "ACTIVE"
                                                          : true
                                                      )
                                                        ? subChild
                                                        : null;
                                                    })
                                                    ?.filter(
                                                      (val) => val !== null
                                                    ),
                                                };
                                              }
                                            })
                                            ?.filter((val) => val !== null) ||
                                          [],
                                      };
                                    }) || []),
                                  ]}
                                  renderItem={(e) =>
                                    renderItemPL({
                                      ...e,
                                      lastItem:
                                        plItems[plItems.length - 1]?.id ===
                                        e?.item?.id,
                                    })
                                  }
                                  maxDepth={3}
                                  ref={(el) => setPlRef(el)}
                                  handler={<Handler />}
                                  confirmChange={({
                                    dragItem,
                                    destinationParent,
                                  }) => {
                                    if (dragItem.type === "Account Type") {
                                      if (destinationParent === null) {
                                        return true;
                                      }
                                    } else {
                                      if (
                                        dragItem.type === "Account Group" &&
                                        (destinationParent?.type ===
                                          "Account Name" ||
                                          destinationParent?.type ===
                                            "Account Group")
                                      ) {
                                        setError(
                                          "Account group cannot be grouped under another account group or account name"
                                        );
                                        setTimeout(() => {
                                          setError("");
                                        }, 2000);
                                        return false;
                                      }
                                      if (
                                        dragItem.type === "Account Name" &&
                                        destinationParent === null
                                      ) {
                                        return true;
                                      }
                                      if (destinationParent !== null) {
                                        return true;
                                      }
                                    }
                                  }}
                                  onChange={(values) => {
                                    if (
                                      values.items?.filter(
                                        (item) => item.type === "Account Name"
                                      )?.length !== 0
                                    ) {
                                      setError(
                                        "Account name cannot be placed as account type"
                                      );
                                      setPlItems(plItems);
                                      setTimeout(() => {
                                        setError("");
                                      }, 2000);
                                    } else if (
                                      values.items
                                        .map((item) => item.children)
                                        .flat()
                                        .filter(
                                          (item) => item.type === "Account Name"
                                        )
                                        .filter(
                                          (item) => item.children?.length > 0
                                        ).length !== 0
                                    ) {
                                      setError(
                                        "Account name cannot be grouped under another account name"
                                      );
                                      setPlItems(plItems);
                                      setTimeout(() => {
                                        setError("");
                                      }, 2000);
                                    } else {
                                      setPlItems(values.items);
                                    }
                                  }}
                                />
                              </div>
                            </div>
                            <Grid item xs={6} className="mt-10">
                              <Grid container justifyContent={"flex-end"}>
                                <ButtonComponent
                                  title={"Revert"}
                                  id="button-revert"
                                  style={{ marginLeft: "20px" }}
                                  variant="outlined"
                                  onClick={() => {
                                    setRevertModalOpen("pl");
                                  }}
                                  disabled={_.isEqual(
                                    plItems,
                                    defaultValues.pl
                                  )}
                                />
                                <ButtonComponent
                                  title={"Save Changes"}
                                  className="ml-10 "
                                  disabled={
                                    (isXeroRefreshing ||
                                      _.isEqual(plItems, defaultValues.pl)) &&
                                    currentEntity?.coaAdded?.pl
                                  }
                                  id="button-saveChanges"
                                  variant="outlined"
                                  onClick={() => {
                                    setSaveChangesModalOpen(true);
                                    setEnable("save");
                                  }}
                                  contained
                                />
                              </Grid>
                            </Grid>
                          </TabPanel>
                          <TabPanel value="2">
                            <div
                              style={{
                                paddingLeft: "30px",
                                height: "60vh",
                                overflowX: "auto",
                              }}
                            >
                              {Object.keys(plGroup).map((key) => {
                                return (
                                  <div key={key}>
                                    <Typography variant="h5">{key}</Typography>

                                    {plGroup[key].map((val) => {
                                      return (
                                        <ul key={val.accountID}>
                                          <Checkbox
                                            disabled={!movetoActiveFlag}
                                            checked={moveToActiveIds.includes(
                                              val.accountID
                                            )}
                                            onClick={() => {
                                              handleCheckBoxSelect(
                                                val.accountID
                                              );
                                            }}
                                          />
                                          {val.name}
                                        </ul>
                                      );
                                    })}
                                  </div>
                                );
                              })}
                              {/* {hiddenAccountList?.pl?.map((val) => {
                          return (
                            <ul key={val.accountID}>
                              <Checkbox
                                disabled={!movetoActiveFlag}
                                checked={moveToActiveIds.includes(
                                  val.accountID
                                )}
                                onClick={() => {
                                  handleCheckBoxSelect(val.accountID);
                                }}
                              />
                              {val.name}
                            </ul>
                          );
                        })} */}
                            </div>
                          </TabPanel>
                        </TabContext>
                      </div>
                    </TabPanel>
                    <TabPanel
                      value="2"
                      style={{ paddingRight: "0px", paddingLeft: "0px" }}
                    >
                      <div style={{ padding: "0px" }}>
                        <header style={{ marginBottom: "2rem" }}>
                          <Grid container>
                            <Grid
                              item
                              xs={12}
                              display="flex"
                              justifyContent={"right"}
                              visibility={
                                bsValue === "1" ? "visible" : "hidden"
                              }
                            >
                              <Button
                                id="button-revert"
                                style={{ marginLeft: "20px" }}
                                variant="outlined"
                                onClick={() => {
                                  setRevertModalOpen("bs");
                                }}
                                startIcon={<RestartAltIcon />}
                              >
                                Revert
                              </Button>
                              <Button
                                id="button-createGroupModal"
                                style={{ marginLeft: "20px" }}
                                variant="contained"
                                onClick={() => {
                                  setGroupOpen(true);
                                }}
                              >
                                Create Group
                              </Button>
                              <Button
                                id="button-saveChanges"
                                style={{
                                  marginLeft: "20px",
                                  backgroundColor: "#08A562",
                                }}
                                variant="contained"
                                onClick={() => {
                                  dispatch(
                                    saveAccountTypeFormat({
                                      id: entityId,
                                      data: bsItems,
                                      type: "bs",
                                      accountingFirmId,
                                    })
                                  );
                                }}
                              >
                                Save Changes
                              </Button>
                            </Grid>
                          </Grid>
                        </header>
                        <TabContext value={bsValue}>
                          <Box
                            sx={{
                              borderBottom: 0,
                              borderColor: "divider",
                              paddingLeft: "30px",
                            }}
                          >
                            <TabList
                              textColor="inherit"
                              onChange={(event, value) =>
                                handleChange(event, value, "bs")
                              }
                              TabIndicatorProps={{
                                style: { background: ColorPalette.primary },
                              }}
                            >
                              <Tab
                                id="button-active"
                                label="Active"
                                value="1"
                                style={{
                                  textTransform: "none",
                                  fontSize: "16px",
                                  fontWeight: plValue === "1" ? "bold" : "",
                                }}
                              />
                              <Tab
                                id="button-inactive"
                                label="Inactive"
                                value="2"
                                style={{
                                  textTransform: "none",
                                  fontSize: "16px",
                                  fontWeight: plValue === "2" ? "bold" : "",
                                }}
                              />
                            </TabList>
                          </Box>
                          <TabPanel value="1">
                            <div>
                              {isError !== "" && (
                                <Snackbar
                                  open={isError !== ""}
                                  autoHideDuration={3000}
                                  onClose={() => {
                                    setError("");
                                  }}
                                >
                                  <Alert severity="error">{isError}</Alert>
                                </Snackbar>
                              )}
                              <CustomModal
                                modalOpen={groupOpen !== ""}
                                setModalOpen={() => setGroupOpen("")}
                                onClose={() => {
                                  setGroupOpen(false);
                                }}
                                // aria-labelledby="modal-modal-title"
                                // aria-describedby="modal-modal-description"
                              >
                                {/* <Box style={style}> */}
                                <Grid container className="container">
                                  {/* <Grid container spacing={3}> */}
                                  <Grid item xs={12}>
                                    <Grid container>
                                      <Grid item xs={11}>
                                        <Typography
                                          style={{ padding: "10px" }}
                                          variant="h5"
                                        >
                                          Create new account group
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={1}
                                        display="flex"
                                        justifyContent={"right"}
                                      >
                                        <IconButton
                                          id="button-closeModalIcon"
                                          onClick={() => setGroupOpen("")}
                                        >
                                          <Close />
                                        </IconButton>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  <Grid item xs={6}>
                                    <FormControl fullWidth>
                                      <InputLabel id="demo-simple-select-label">
                                        Account Type
                                      </InputLabel>
                                      <Select
                                        id="input-accountType"
                                        labelId="demo-simple-select-label"
                                        value={groupType}
                                        label="Account Group"
                                        onChange={(e) => {
                                          setGroupType(e.target.value);
                                        }}
                                      >
                                        {bsItems?.map((item) => {
                                          return (
                                            <MenuItem
                                              key={item.id}
                                              value={item.id}
                                            >
                                              {item.text}
                                            </MenuItem>
                                          );
                                        })}
                                      </Select>
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={6}>
                                    <TextField
                                      id="input-groupName"
                                      fullWidth
                                      value={groupName}
                                      placeholder="Group name"
                                      onChange={({ target: { value } }) => {
                                        setGroupName(value);
                                      }}
                                    ></TextField>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <Autocomplete
                                      style={{ width: "100%" }}
                                      multiple
                                      disableCloseOnSelect
                                      isOptionEqualToValue={(option, value) =>
                                        option.id === value.id
                                      }
                                      onChange={(event, values) => {
                                        setValuesToGroup(values);
                                      }}
                                      id="grouped-account-type"
                                      options={bsOptions}
                                      groupBy={(option) => option.group}
                                      getOptionLabel={(option) => option.text}
                                      sx={{ width: 300 }}
                                      renderInput={(params) => (
                                        <TextField
                                          id="input-withCategories"
                                          {...params}
                                          label="With categories"
                                        />
                                      )}
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    display="flex"
                                    justifyContent={"center"}
                                  >
                                    <Button
                                      id="button-createGroup"
                                      variant="contained"
                                      disabled={
                                        groupType === "" || groupName === ""
                                      }
                                      color="success"
                                      onClick={() => {
                                        const change = cloneDeep(bsItems);
                                        const id = uuidv4();
                                        valuesToGroup.forEach((a) => {
                                          change.forEach((b) => {
                                            const index = b.children
                                              .map((c) => c.id)
                                              .indexOf(a.id);
                                            if (index !== -1) {
                                              b.children.splice(index, 1);
                                            }
                                          });
                                        });
                                        change[
                                          bsItems
                                            ?.map((item) => item.id)
                                            .indexOf(groupType)
                                        ].children.unshift({
                                          id: id,
                                          text: groupName,
                                          type: "Account Group",
                                          children: valuesToGroup,
                                        });
                                        setGroupName("");
                                        setGroupType("");
                                        setGroupOpen("");
                                        setBsItems(change);
                                        setValuesToGroup([]);
                                      }}
                                    >
                                      Create Group
                                    </Button>
                                  </Grid>
                                </Grid>
                                {/* </Grid> */}
                                {/* </Box> */}
                              </CustomModal>
                              <div style={{ marginLeft: "3rem" }}>
                                <Nestable
                                  items={[...(bsItems || [])]}
                                  renderItem={(e) => renderItemBS(e)}
                                  ref={(el) => (bsRef = el)}
                                  maxDepth={3}
                                  handler={<Handler />}
                                  threshold={50}
                                  confirmChange={({
                                    dragItem,
                                    destinationParent,
                                  }) => {
                                    if (dragItem.type === "Account Type") {
                                      if (destinationParent === null) {
                                        return true;
                                      }
                                    } else {
                                      const child = bsItems
                                        ?.map((item) => {
                                          return item.children.find((child) => {
                                            return child.id === dragItem.id;
                                          });
                                        })
                                        .find((a) => a !== undefined);
                                      const parent = bsItems.filter((item) => {
                                        return (
                                          item.children
                                            .map((child) => child?.id)
                                            .indexOf(child?.id) !== -1
                                        );
                                      });
                                      if (parent[0]?.children?.length === 1) {
                                        setError(
                                          "Account Type must contain atleast one child item"
                                        );
                                        setTimeout(() => {
                                          setError("");
                                        }, 2000);
                                        return false;
                                      }

                                      if (
                                        dragItem.type === "Account Group" &&
                                        (destinationParent?.type ===
                                          "Account Name" ||
                                          destinationParent?.type ===
                                            "Account Group")
                                      ) {
                                        setError(
                                          "Account group cannot be grouped under another account group or account name"
                                        );
                                        setTimeout(() => {
                                          setError("");
                                        }, 2000);
                                        return false;
                                      }
                                      if (
                                        dragItem.type === "Account Name" &&
                                        destinationParent === null
                                      ) {
                                        return true;
                                      }
                                      if (destinationParent !== null) {
                                        return true;
                                      }
                                    }
                                  }}
                                  onChange={(values) => {
                                    if (
                                      values.items?.filter(
                                        (item) => item.type === "Account Name"
                                      )?.length !== 0
                                    ) {
                                      setError(
                                        "Account name cannot be placed as account type"
                                      );
                                      setTimeout(() => {
                                        setError("");
                                      }, 2000);
                                    } else if (
                                      values.items
                                        .map((item) => item.children)
                                        .flat()
                                        .filter(
                                          (item) => item.type === "Account Name"
                                        )
                                        .filter(
                                          (item) => item.children?.length > 0
                                        ).length !== 0
                                    ) {
                                      setError(
                                        "Account name cannot be grouped under another account name"
                                      );
                                      setTimeout(() => {
                                        setError("");
                                      }, 2000);
                                    } else {
                                      setBsItems(values.items);
                                    }
                                  }}
                                />
                              </div>
                            </div>
                          </TabPanel>
                          <TabPanel value="2">
                            <div
                              style={{
                                paddingLeft: "30px",
                                height: "70vh",
                                overflowX: "auto",
                              }}
                            >
                              <ul>
                                {hiddenAccountList?.bs?.map((val) => {
                                  return <li key={val._id}>{val.name}</li>;
                                })}
                              </ul>
                            </div>
                          </TabPanel>
                        </TabContext>
                      </div>
                    </TabPanel>
                  </TabContext>
                  <Modal
                    open={modalOpen}
                    onClose={() => setModalOpen(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={style}>
                      <div>
                        <Grid container>
                          <Grid item xs={12}>
                            <label className="dashboard-h1">
                              Configure Entity
                            </label>
                          </Grid>

                          <Grid item xs={12}>
                            <form onSubmit={handleSubmit}>
                              <Grid container marginTop={4}>
                                <Grid item xs={12}>
                                  <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                      <TextField
                                        id="text-financialMonthEnd"
                                        variant="outlined"
                                        label="Financial Month End"
                                        fullWidth
                                        size="medium"
                                        type="financialMonthEnd"
                                        name="financialMonthEnd"
                                        value={inputFields.financialMonthEnd}
                                        onChange={(event) =>
                                          handleFormChange(event)
                                        }
                                      />
                                    </Grid>
                                    <Grid item xs={6}>
                                      <FormControl fullWidth>
                                        {" "}
                                        <InputLabel id="role-user">
                                          Tax Rate
                                        </InputLabel>
                                        <Select
                                          id="input-taxRate"
                                          labelId="role-user"
                                          value={inputFields.employees}
                                          label="Employees"
                                          name="role"
                                          onChange={(event) =>
                                            handleFormChange(event)
                                          }
                                        >
                                          <MenuItem value={"Manager"}>
                                            25%
                                          </MenuItem>
                                          <MenuItem value={"Accountant"}>
                                            30%
                                          </MenuItem>
                                        </Select>
                                      </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <FormControl fullWidth>
                                        {" "}
                                        <InputLabel id="role-user">
                                          Registered for GST
                                        </InputLabel>
                                        <Select
                                          labelId="role-user"
                                          id="input-registeredForGst"
                                          value={inputFields.employees}
                                          label="Employees"
                                          name="role"
                                          onChange={(event) =>
                                            handleFormChange(event)
                                          }
                                        >
                                          <MenuItem value={"Manager"}>
                                            Yes
                                          </MenuItem>
                                          <MenuItem value={"Accountant"}>
                                            No
                                          </MenuItem>
                                        </Select>
                                      </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <FormControl fullWidth>
                                        {" "}
                                        <InputLabel id="role-user">
                                          Employees
                                        </InputLabel>
                                        <Select
                                          labelId="role-user"
                                          id="input-employees"
                                          value={inputFields.employees}
                                          label="Employees"
                                          name="role"
                                          onChange={(event) =>
                                            handleFormChange(event)
                                          }
                                        >
                                          <MenuItem value={"Manager"}>
                                            Yes
                                          </MenuItem>
                                          <MenuItem value={"Accountant"}>
                                            No
                                          </MenuItem>
                                        </Select>
                                      </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <FormControl fullWidth>
                                        {" "}
                                        <InputLabel id="role-user">
                                          Monthly IAS
                                        </InputLabel>
                                        <Select
                                          id="input-monthlyIAS"
                                          labelId="role-user"
                                          value={inputFields.employees}
                                          label="Employees"
                                          name="role"
                                          onChange={(event) =>
                                            handleFormChange(event)
                                          }
                                        >
                                          <MenuItem value={"Manager"}>
                                            Yes
                                          </MenuItem>
                                          <MenuItem value={"Accountant"}>
                                            No
                                          </MenuItem>
                                        </Select>
                                      </FormControl>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </form>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          marginTop={3}
                          display="flex"
                          justifyContent={"right"}
                        >
                          <Button
                            id="button-done"
                            variant="contained"
                            type="submit"
                            onClick={() => setModalOpen(false)}
                          >
                            Done
                          </Button>
                        </Grid>
                      </div>
                    </Box>
                  </Modal>

                  {/* Account name swap modal pl */}
                  <CustomModal
                    modalOpen={nameSwapModal !== ""}
                    setModalOpen={() => setNameSwapModal("")}
                    onClose={() => setNameSwapModal("")}
                    overflow={"hidden"}
                    maxWidth="md"
                  >
                    <Grid container spacing={3}>
                      <Grid item xs={12} mb={2}>
                        <Grid container>
                          <Grid item xs={11}>
                            <Typography fontSize={20} fontWeight="bold">
                              Move Account Name to
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={1}
                            display="flex"
                            justifyContent={"right"}
                          >
                            <IconButton
                              id="button-closeModalIcon"
                              onClick={() => setNameSwapModal("")}
                            >
                              <Close />
                            </IconButton>
                          </Grid>
                          <Divider
                            sx={{ width: "100%", color: ColorPalette.danger }}
                          />
                        </Grid>
                      </Grid>
                      <Grid container mx={2} mt={2}>
                        <Grid
                          container
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: "10px",
                            marginBottom: "20px",
                          }}
                        >
                          <Grid item xs={5.5}>
                            <InputLabel id="account-label">
                              Account Type
                            </InputLabel>
                            <Select
                              fullWidth
                              size="small"
                              id="input-accountType"
                              labelId="account-label"
                              value={moveToType}
                              onChange={(e) => {
                                setMoveToType(e.target.value);
                                setMoveToGroup("");
                              }}
                            >
                              {plItems?.map((item) => {
                                return (
                                  <MenuItem key={item.id} value={item.id}>
                                    {item.text}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </Grid>
                          <Grid item xs={5.5}>
                            <InputLabel id="account-group">
                              Account Group
                            </InputLabel>
                            <Select
                              fullWidth
                              labelId="account-group"
                              id="input-accountGroup"
                              value={moveToGroup}
                              size="small"
                              onChange={(e) => {
                                setMoveToGroup(e.target.value);
                              }}
                            >
                              {getAccountGroupList()}
                            </Select>
                          </Grid>
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          display="flex"
                          justifyContent={"right"}
                          mt={1}
                          mx={2}
                        >
                          <Button
                            id="button-move"
                            variant="contained"
                            onClick={() => {
                              const typeIndex = plItems
                                .map((item, index) => {
                                  let a = item.children
                                    .map((a) => a.id)
                                    .indexOf(nameSwapModal);
                                  return a !== -1
                                    ? {
                                        childrenIndex: a,
                                        index,
                                      }
                                    : null;
                                })
                                .filter((a) => a !== null)[0];
                              if (typeIndex !== undefined) {
                                if (moveToGroup === "") {
                                  let copyData = cloneDeep(plItems);
                                  const moveToIndex = plItems
                                    .map((a) => a.id)
                                    .indexOf(moveToType);
                                  copyData[moveToIndex].children.push(
                                    copyData[typeIndex.index].children[
                                      typeIndex.childrenIndex
                                    ]
                                  );
                                  copyData[typeIndex.index].children.splice(
                                    typeIndex.childrenIndex,
                                    1
                                  );
                                  setPlItems(copyData);
                                } else {
                                  let copyData = cloneDeep(plItems);
                                  const moveToIndex = plItems
                                    .map((a) => a.id)
                                    .indexOf(moveToType);
                                  const moveToChildIndex = copyData[
                                    moveToIndex
                                  ]?.children
                                    ?.map((a) => a.id)
                                    .indexOf(moveToGroup);

                                  copyData[moveToIndex].children[
                                    moveToChildIndex
                                  ].children.push(
                                    copyData[typeIndex.index].children[
                                      typeIndex.childrenIndex
                                    ]
                                  );
                                  copyData[typeIndex.index].children.splice(
                                    typeIndex.childrenIndex,
                                    1
                                  );
                                  setPlItems(copyData);
                                }
                              } else {
                                const typeIndex = plItems.map((item, index) => {
                                  return item?.children?.map(
                                    (subitem, subindex) => {
                                      let a = subitem?.children
                                        ?.map((a) => a.id)
                                        .indexOf(nameSwapModal);
                                      return a !== undefined && a !== -1
                                        ? {
                                            childrenIndex: a,
                                            groupIndex: subindex,
                                            typeIndex: index,
                                          }
                                        : null;
                                    }
                                  );
                                });
                                const flatData = []
                                  .concat(...typeIndex)
                                  .filter((a) => a !== null)[0];
                                let copyData = cloneDeep(plItems);
                                const moveToIndex = plItems
                                  .map((a) => a.id)
                                  .indexOf(moveToType);
                                const moveToChildIndex = copyData[
                                  moveToIndex
                                ]?.children
                                  ?.map((a) => a.id)
                                  .indexOf(moveToGroup);
                                if (moveToGroup === "") {
                                  copyData[moveToIndex].children.push(
                                    copyData[flatData.typeIndex]?.children[
                                      flatData.groupIndex
                                    ].children[flatData.childrenIndex]
                                  );
                                  copyData[flatData.typeIndex]?.children[
                                    flatData.groupIndex
                                  ].children.splice(flatData.childrenIndex, 1);
                                } else {
                                  copyData[moveToIndex].children[
                                    moveToChildIndex
                                  ].children.push(
                                    copyData[flatData.typeIndex]?.children[
                                      flatData.groupIndex
                                    ].children[flatData.childrenIndex]
                                  );
                                  copyData[flatData.typeIndex]?.children[
                                    flatData.groupIndex
                                  ].children.splice(flatData.childrenIndex, 1);
                                }
                                setPlItems(copyData);
                              }
                              setMoveToGroup("");
                              setMoveToType("");
                              setNameSwapModal("");
                            }}
                          >
                            Move
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </CustomModal>

                  {/* Account group swap modal pl */}
                  <CustomModal
                    modalOpen={groupSwapModal !== ""}
                    setModalOpen={() => setGroupSwapModal("")}
                    onClose={() => setGroupSwapModal("")}
                    overflow={"auto"}
                    maxWidth="50vh"
                  >
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Grid container>
                          <Grid item xs={11}>
                            <Typography fontSize={20} fontWeight="bold">
                              Move Account Group
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={1}
                            display="flex"
                            justifyContent={"right"}
                          >
                            <IconButton
                              id="button-closeModalIcon"
                              onClick={() => setGroupSwapModal("")}
                            >
                              <Close />
                            </IconButton>
                          </Grid>
                          <Divider
                            sx={{ width: "100%", color: ColorPalette.danger }}
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={12} mx={2} mt={1}>
                        {/* <FormControl fullWidth> */}
                        <InputLabel id="account-type" className="mb-10">
                          Account Type
                        </InputLabel>
                        <Select
                          size="small"
                          fullWidth
                          labelId="account-type"
                          id="input-accountGroup"
                          value={moveToType}
                          onChange={(e) => {
                            setMoveToType(e.target.value);
                          }}
                        >
                          {plItems?.map((item) => {
                            return (
                              <MenuItem key={item.id} value={item.id}>
                                {item.text}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        {/* </FormControl> */}
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        display="flex"
                        justifyContent={"right"}
                        mx={2}
                        mt={1}
                      >
                        <Button
                          id="button-move"
                          variant="contained"
                          onClick={() => {
                            const typeIndex = plItems
                              .map((item, index) => {
                                let a = item.children
                                  .map((a) => a.id)
                                  .indexOf(groupSwapModal);
                                return a !== -1
                                  ? {
                                      childrenIndex: a,
                                      index,
                                    }
                                  : null;
                              })
                              .filter((a) => a !== null)[0];
                            let copyData = cloneDeep(plItems);
                            const moveToIndex = plItems
                              .map((a) => a.id)
                              .indexOf(moveToType);
                            copyData[moveToIndex].children.push(
                              copyData[typeIndex.index].children[
                                typeIndex.childrenIndex
                              ]
                            );
                            copyData[typeIndex.index].children.splice(
                              typeIndex.childrenIndex,
                              1
                            );
                            setMoveToType("");
                            setMoveToGroup("");
                            setGroupSwapModal("");
                            setPlItems(copyData);
                          }}
                        >
                          Move
                        </Button>
                      </Grid>
                    </Grid>
                  </CustomModal>

                  <Modal
                    open={nameSwapModalBS !== ""}
                    onClose={() => {
                      setNameSwapModalBS("");
                    }}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box style={style}>
                      <Grid container className="container">
                        <Grid container spacing={3}>
                          <Grid item xs={12}>
                            <Grid container>
                              <Grid item xs={11}>
                                <Typography
                                  style={{ padding: "10px" }}
                                  variant="h5"
                                >
                                  Move Account Name to
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={1}
                                display="flex"
                                justifyContent={"right"}
                              >
                                <IconButton
                                  id="button-closeModalIcon"
                                  onClick={() => setNameSwapModalBS("")}
                                >
                                  <Close />
                                </IconButton>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={6}>
                            <FormControl fullWidth>
                              <InputLabel id="demo-simple-select-label">
                                Account Type
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="input-accountType"
                                value={moveToType}
                                label="Account Group"
                                onChange={(e) => {
                                  setMoveToType(e.target.value);
                                  setMoveToGroup("");
                                }}
                              >
                                {bsItems?.map((item) => {
                                  return (
                                    <MenuItem key={item.id} value={item.id}>
                                      {item.text}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={6}>
                            <FormControl fullWidth>
                              <InputLabel id="demo-simple-select-label">
                                Account Group
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="input-accountGroup"
                                value={moveToGroup}
                                label="Account Group"
                                onChange={(e) => {
                                  setMoveToGroup(e.target.value);
                                }}
                              >
                                {getAccountGroupListBS()}
                              </Select>
                            </FormControl>
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            display="flex"
                            justifyContent={"center"}
                          >
                            <Button
                              id="button-move"
                              variant="contained"
                              color="success"
                              onClick={() => {
                                const typeIndex = bsItems
                                  .map((item, index) => {
                                    let a = item.children
                                      .map((a) => a.id)
                                      .indexOf(nameSwapModalBS);
                                    return a !== -1
                                      ? {
                                          childrenIndex: a,
                                          index,
                                        }
                                      : null;
                                  })
                                  .filter((a) => a !== null)[0];
                                if (typeIndex !== undefined) {
                                  if (moveToGroup === "") {
                                    let copyData = cloneDeep(bsItems);
                                    const moveToIndex = bsItems
                                      .map((a) => a.id)
                                      .indexOf(moveToType);
                                    copyData[moveToIndex].children.push(
                                      copyData[typeIndex.index].children[
                                        typeIndex.childrenIndex
                                      ]
                                    );
                                    copyData[typeIndex.index].children.splice(
                                      typeIndex.childrenIndex,
                                      1
                                    );
                                    setBsItems(copyData);
                                  } else {
                                    let copyData = cloneDeep(bsItems);
                                    const moveToIndex = bsItems
                                      .map((a) => a.id)
                                      .indexOf(moveToType);
                                    const moveToChildIndex = copyData[
                                      moveToIndex
                                    ]?.children
                                      ?.map((a) => a.id)
                                      .indexOf(moveToGroup);

                                    copyData[moveToIndex].children[
                                      moveToChildIndex
                                    ].children.push(
                                      copyData[typeIndex.index].children[
                                        typeIndex.childrenIndex
                                      ]
                                    );
                                    copyData[typeIndex.index].children.splice(
                                      typeIndex.childrenIndex,
                                      1
                                    );
                                    setBsItems(copyData);
                                  }
                                } else {
                                  const typeIndex = bsItems.map(
                                    (item, index) => {
                                      return item?.children?.map(
                                        (subitem, subindex) => {
                                          let a = subitem?.children
                                            ?.map((a) => a.id)
                                            .indexOf(nameSwapModalBS);
                                          return a !== undefined && a !== -1
                                            ? {
                                                childrenIndex: a,
                                                groupIndex: subindex,
                                                typeIndex: index,
                                              }
                                            : null;
                                        }
                                      );
                                    }
                                  );
                                  const flatData = []
                                    .concat(...typeIndex)
                                    .filter((a) => a !== null)[0];
                                  let copyData = cloneDeep(bsItems);
                                  const moveToIndex = bsItems
                                    .map((a) => a.id)
                                    .indexOf(moveToType);
                                  const moveToChildIndex = copyData[
                                    moveToIndex
                                  ]?.children
                                    ?.map((a) => a.id)
                                    .indexOf(moveToGroup);
                                  if (moveToGroup === "") {
                                    copyData[moveToIndex].children.push(
                                      copyData[flatData.typeIndex]?.children[
                                        flatData.groupIndex
                                      ].children[flatData.childrenIndex]
                                    );
                                    copyData[flatData.typeIndex]?.children[
                                      flatData.groupIndex
                                    ].children.splice(
                                      flatData.childrenIndex,
                                      1
                                    );
                                  } else {
                                    copyData[moveToIndex].children[
                                      moveToChildIndex
                                    ].children.push(
                                      copyData[flatData.typeIndex]?.children[
                                        flatData.groupIndex
                                      ].children[flatData.childrenIndex]
                                    );
                                    copyData[flatData.typeIndex]?.children[
                                      flatData.groupIndex
                                    ].children.splice(
                                      flatData.childrenIndex,
                                      1
                                    );
                                  }
                                  setBsItems(copyData);
                                }
                                setMoveToGroup("");
                                setMoveToType("");
                                setNameSwapModalBS("");
                              }}
                            >
                              Move
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>
                  </Modal>
                  <Modal
                    open={groupSwapModalBS !== ""}
                    onClose={() => {
                      setGroupSwapModalBS("");
                    }}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box style={style}>
                      <Grid container className="container">
                        <Grid container spacing={3}>
                          <Grid item xs={12}>
                            <Grid container>
                              <Grid item xs={11}>
                                <Typography
                                  style={{ padding: "10px" }}
                                  variant="h5"
                                >
                                  Move Account Group
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={1}
                                display="flex"
                                justifyContent={"right"}
                              >
                                <IconButton
                                  id="button-closeModalIcon"
                                  onClick={() => setGroupSwapModalBS("")}
                                >
                                  <Close />
                                </IconButton>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={6}>
                            <FormControl fullWidth>
                              <InputLabel id="demo-simple-select-label">
                                Account Type
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="input-accountGroup"
                                value={moveToType}
                                label="Account Group"
                                onChange={(e) => {
                                  setMoveToType(e.target.value);
                                }}
                              >
                                {bsItems?.map((item) => {
                                  return (
                                    <MenuItem key={item.id} value={item.id}>
                                      {item.text}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            display="flex"
                            justifyContent={"center"}
                          >
                            <Button
                              id="button-move"
                              variant="contained"
                              color="success"
                              onClick={() => {
                                const typeIndex = bsItems
                                  .map((item, index) => {
                                    let a = item.children
                                      .map((a) => a.id)
                                      .indexOf(groupSwapModalBS);
                                    return a !== -1
                                      ? {
                                          childrenIndex: a,
                                          index,
                                        }
                                      : null;
                                  })
                                  .filter((a) => a !== null)[0];
                                let copyData = cloneDeep(bsItems);
                                const moveToIndex = bsItems
                                  .map((a) => a.id)
                                  .indexOf(moveToType);
                                copyData[moveToIndex].children.push(
                                  copyData[typeIndex.index].children[
                                    typeIndex.childrenIndex
                                  ]
                                );
                                copyData[typeIndex.index].children.splice(
                                  typeIndex.childrenIndex,
                                  1
                                );
                                setMoveToType("");
                                setMoveToGroup("");
                                setGroupSwapModalBS("");
                                setBsItems(copyData);
                              }}
                            >
                              Move
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>
                  </Modal>
                  {/* <Modal
                    open={revertModalOpen !== ""}
                    onClose={() => {
                      setRevertModalOpen("");
                    }}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box style={style}>
                      <Grid container className="container">
                        <Grid container spacing={3}>
                          <Grid item xs={12}>
                            <Grid container>
                              <Grid item xs={11}></Grid>
                              <Grid
                                item
                                xs={1}
                                display="flex"
                                justifyContent={"right"}
                              >
                                <IconButton
                                  id="button-closeModalIcon"
                                  onClick={() => setRevertModalOpen("")}
                                >
                                  <Close />
                                </IconButton>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            display="flex"
                            justifyContent={"center"}
                          >
                            <Typography variant="body1">
                              Are you sure you want to revert the changes? This
                              cannot be undone.
                            </Typography>
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            display="flex"
                            justifyContent={"center"}
                          >
                            <Button
                              id="button-confirm"
                              variant="contained"
                              color="success"
                              onClick={() => {
                                if (revertModalOpen === "bs") {
                                  setBsItems(defaultValues.bs);
                                } else {
                                  setPlItems(defaultValues.pl);
                                }
                                setRevertModalOpen("");
                              }}
                            >
                              Confirm
                            </Button>
                            <Button
                              id="button-error"
                              style={{ marginLeft: "30px" }}
                              variant="contained"
                              color="error"
                              onClick={() => {
                                setRevertModalOpen("");
                              }}
                            >
                              Cancel
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>
                  </Modal> */}

                  {/* edit account group modal pl */}
                  <CustomModal
                    modalOpen={editConfirmModalOpen}
                    setModalOpen={() => setEditConfirmModalOpen}
                    onClose={() => setEditConfirmModalOpen(false)}
                    overflow={"auto"}
                    maxWidth="50vh"
                  >
                    <Grid container p={1}>
                      <Grid container>
                        <Grid
                          item
                          xs={12}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            marginBottom: "10px",
                          }}
                        >
                          <Grid container display={"flex"}>
                            <Typography variant="h6">
                              Edit
                              {confirmEditModalData.type === "name"
                                ? " account name"
                                : " reporting name"}
                            </Typography>
                            <Tooltip
                              className="ml-10 mt-5"
                              title={
                                <h2>
                                  Updating this name will update the reporting
                                  name for this section
                                </h2>
                              }
                            >
                              <Info />
                            </Tooltip>{" "}
                          </Grid>

                          <IconButton>
                            <Close
                              onClick={() => setEditConfirmModalOpen(false)}
                            />
                          </IconButton>
                        </Grid>
                        <Divider
                          sx={{ width: "100%", color: ColorPalette.danger }}
                        />
                      </Grid>
                      <Grid container pl={2} pt={2}>
                        <Grid item xs={6} my={1}>
                          <InputLabel className="mb-10">
                            {confirmEditModalData.type === "name"
                              ? "Account "
                              : "Reporting "}{" "}
                            Name
                          </InputLabel>
                          <TextField
                            fullWidth
                            size="small"
                            variant="outlined"
                            value={editTextFieldValue}
                            onChange={(e) =>
                              setEditTextFieldValue(e.target.value)
                            }
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "right",
                            marginTop: "20px",
                            gap: "10px",
                          }}
                        >
                          <ButtonComponent
                            variant="outlined"
                            onClick={() => setEditConfirmModalOpen(false)}
                            className="text-normal"
                            title={"Cancel"}
                          />

                          <ButtonComponent
                            className="text-normal"
                            contained
                            onClick={handleEdit}
                            sx={{
                              marginRight: "10px",
                              minWidth: "100px",
                            }}
                            title={"Save"}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </CustomModal>

                  {/* delete account group modal pl */}
                  <ConfirmModal
                    message="Do you want to delete the group?"
                    declineFunction={() => setConfirmDeleteModalOpen(false)}
                    confirmFunction={handleDelete}
                    setModalOpen={() => setConfirmDeleteModalOpen(false)}
                    modalOpen={confirmDeleteModalOpen}
                  />
                  {/* <ConfirmModal
                    message="Are you sure you want to refresh xero data to latest values? This is not reversible and will take some time."
                    confirmText="Yes"
                    declineText="No"
                    modalOpen={refreshXeroModal}
                    confirmFunction={() => {
                      dispatch(
                        refreshXero({
                          id: entityId,
                          email: localStorage.getItem("email"),
                          accountingFirmId,
                        })
                      );
                      dispatch(setIsXeroRefreshing());
                      setRefreshXeroModal(false);
                    }}
                    declineFunction={() => {
                      setRefreshXeroModal(false);
                    }}
                    setModalOpen={setRefreshXeroModal}
                  /> */}
                </div>
              </TabPanel>
              <TabPanel value="2" style={{ padding: "0px" }}>
                {currentEntity && (
                  <FinancialSettings
                    {...currentEntity}
                    accountingFirmId={accountingFirmId}
                    entityId={entityId}
                  />
                )}
              </TabPanel>
              <TabPanel
                value="3"
                style={{ paddingRight: "0px", paddingLeft: "0px" }}
              >
                <XeroSettingsTab
                  xeroData={xeroTenants}
                  entity={currentEntity}
                />
              </TabPanel>
            </Box>
          </TabContext>
        ) : (
          <Loading />
        )}
      </Box>
    </div>
  );
}

import React, { useState } from "react";
import CustomModal from "../../../../../../components/CustomModal/CustomModal";
import {
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { isXeroMap } from "../../../../../../helper/utils";
import CloseIcon from "@mui/icons-material/Close";
import { ColorPalette } from "../../../../../../constants/colorPalette";
import ConfirmModal from "../../../../../../components/CustomModal/ConfirmModal";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  refreshXero,
  setIsXeroRefreshing,
} from "../../../../../../redux/Setting/SettingSlice";
import { ButtonComponent } from "../../../../../../components/Button/Button";

const RefreshXeroModal = ({ modalOpen, setModalOpen }) => {
  const dispatch = useDispatch();
  const { entityId, accountingFirmId, clientSpaceId } = useParams();

  const [checkList, setCheckList] = useState(isXeroMap);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  const keyDisplayFormat = (key) => {
    const displayFormat = {
      chartOfAccounts: "Chart of accounts",
      // detailedTransaction: "Detailed Transaction",
      // settings: "Settings",
      // budgets: "Budgets",
      // contacts: "Contacts",
      transactions: "Transactions",
    };
    return displayFormat[key];
  };

  const handleCheckBoxChange = (key) => {
    setCheckList({ ...checkList, [key]: !checkList[key] });
  };

  const onDeselectAllClick = () => {
    const reset = {};
    for (const key in checkList) {
      reset[key] = false;
    }
    setCheckList(reset);
  };

  const onSelectClick = () => {
    const reset = {};
    for (const key in checkList) {
      reset[key] = true;
    }
    setCheckList(reset);
  };
  return (
    <div>
      {confirmModalOpen && (
        <ConfirmModal
          message="Are you sure you want to refresh xero data to latest values? This is not reversible and will take some time."
          confirmText="Yes"
          declineText="No"
          modalOpen={confirmModalOpen}
          confirmFunction={() => {
            dispatch(
              refreshXero({
                id: entityId,
                email: localStorage.getItem("email"),
                accountingFirmId,
                clientSpaceId,
                modelsToFetch: checkList,
              })
            );
            dispatch(setIsXeroRefreshing());
            setConfirmModalOpen(false);
            setModalOpen(false);
          }}
          declineFunction={() => {
            setConfirmModalOpen(false);
          }}
          setModalOpen={setConfirmModalOpen}
        />
      )}
      <CustomModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        overflow={"auto"}
      >
        <Grid container py={3} px={1}>
          <Grid
            container
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Grid
              item
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
              mb={2}
            >
              <Typography variant="h5">
                Select accounts you want to refresh from Xero
              </Typography>
              <IconButton onClick={() => setModalOpen(false)}>
                <CloseIcon />
              </IconButton>
            </Grid>
            <Divider sx={{ width: "100%", color: ColorPalette.danger }} />
          </Grid>
          <Grid
            container
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "right",
              marginTop: "20px",
            }}
          >
            <ButtonComponent
              id="button-confirmModalYes"
              contained
              onClick={onSelectClick}
              title={"Select All"}
              className={"mr-10"}
            />

            <ButtonComponent
              onClick={onDeselectAllClick}
              title={"Deselect All"}
            />
          </Grid>
          <Grid
            container
            sx={{
              display: "flex",
              flexDirection: "column",
              maxHeight: "30vh",
              overflow: "auto",
              padding: "20px",
              paddingRight: "10px",
            }}
          >
            {Object.keys(checkList).map((val) => (
              <FormGroup key={val}>
                <FormControlLabel
                  control={<Checkbox />}
                  label={keyDisplayFormat(val)}
                  checked={checkList[val]}
                  onChange={() => handleCheckBoxChange(val)}
                />
              </FormGroup>
            ))}
          </Grid>
          <Grid
            container
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "right",
              gap: "10px",
              paddingTop: "20px",
            }}
          >
            <ButtonComponent
              title="Cancel"
              onClick={() => setModalOpen(false)}
            />

            <ButtonComponent
              id="button-confirmModalYes"
              contained
              sx={{
                marginRight: "10px",
                minWidth: "100px",
                textTransform: "none",
              }}
              disabled={!Object.values(checkList).some((val) => val)}
              onClick={() => setConfirmModalOpen(true)}
              title="Save"
            />
          </Grid>
        </Grid>
      </CustomModal>
    </div>
  );
};

export default RefreshXeroModal;

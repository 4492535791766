const ColorPalette = {
  primary: "#1916B6",
  primaryHover: "#100D8C",
  // accent: "#0F71FD",
  button1: "#FFFFFF",
  button1Hover: "#E2E8F0",
  text: "#3A3549",
  borderColor: "#DCDFE1",
  disabled: "#D9D9D9",
  backgroundColor: "#F1F5F9",
  successColor: "#BBF1CE",
  tabColor: "#DEDDFF",
  black: "#222222",

  background: "#050331",
  gray: "#7E84A3",
  gold: "#FFC700",
  lightBackground: "#E6E8F6",
  error: "#F0142F",
  info: "#6FAAFE",
  secondary: "#33EED9",
  secondaryDark: "#094498",
  highlight: "#3795BD",
  danger: "#FC2947",
  darkBackground: "#002F29",
  muted: "#696883",
  lightAccent: "#A5D7E8",
  lightInfo: "#C9EEFF",
  darkBackground2: "#020114",
  success: "#06A561",
  darkAccent: "#062D65",
  warning: "#FC5A5A",
  darkAccent2: "#062D65",
  sidebar: "#576CBC",
  royal: "#AA77FF",
  indigo: "#3A1078",
  white: "#FFFFFF",
  contained: "#1976D2",
};

module.exports = { ColorPalette };

import * as React from "react";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import { styled } from "@mui/material/styles";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import {
  BoltRounded,
  GridViewRounded,
  MonetizationOnOutlined,
  GridOnRounded,
  TableChartOutlined,
  AccountCircleOutlined,
  ExitToAppOutlined,
  KeyboardArrowUpOutlined,
  KeyboardArrowDownOutlined,
} from "@mui/icons-material";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import SettingsApplicationsOutlinedIcon from "@mui/icons-material/SettingsApplicationsOutlined";
import { FolderOpenRounded } from "@mui/icons-material";
import { LeaderboardRounded } from "@mui/icons-material";
import { HomeRounded } from "@mui/icons-material";
import { PeopleOutlineTwoTone } from "@mui/icons-material";
import { Settings } from "@mui/icons-material";
import { useLocation, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import ConfirmModal from "../CustomModal/ConfirmModal";
import { Divider, Grid, Menu, MenuItem, Typography, Box } from "@mui/material";
import { ColorPalette } from "../../constants/colorPalette";
import { useDispatch, useSelector } from "react-redux";
import { checkDataAvailibility } from "../../helper/utils";
import { reset } from "../../redux/User/UserSlice";

const drawerWidth = 250;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)})`,
  [theme.breakpoints.down("sm")]: {
    width: 0,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  zIndex: 2,
  position: "absolute",
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const renderIcon = (type) => {
  switch (type) {
    case "dashboard":
      return <GridViewRounded />;
    case "profitability":
      return <BoltRounded />;
    case "cashPosition":
      return <FolderOpenRounded />;
    case "financialPosition":
      return <MonetizationOnOutlined />;
    case "profitAndLoss":
      return <TableChartOutlined />;
    case "budgetComparision":
      return <LeaderboardRounded />;
    case "settings":
      return <Settings />;
    case "clientSpaces":
    case "entities":
      return <HomeRounded />;
    case "team":
      return <PeopleOutlineTwoTone />;
    case "spreadSheets":
      return <GridOnRounded />;
    case "firmProfile":
      return <AdminPanelSettingsOutlinedIcon />;
    default:
      return <></>;
  }
};

export default function SidebarCustom(props) {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { accountingFirmId, clientSpaceId, entityId } = useParams();
  const data = useSelector((state) => state.user);
  const { open, setOpen } = props;
  const role = localStorage.getItem("role");
  const path = pathname.split("/");
  const stateData = checkDataAvailibility(data);
  const [anchorEl, setAnchorEl] = React.useState(null);

  // Check route types
  const isEntitiesRoute = pathname.includes("entities");
  const isEntityUserDetailsRoute = pathname.includes("entityUserDetails");

  // Define labels
  const labels = {
    spreadSheets: "Sheets",
    profitAndLoss: "Profit & Loss",
    settings: "Settings",
  };

  const adminLabels = {
    clientSpaces:
      isEntitiesRoute || isEntityUserDetailsRoute
        ? "Entities"
        : "Client Spaces",
    team: "Team",
    firmProfile: "Accounting Firm",
  };

  const [isConfrimModalOpen, setIsConfirmModalOpen] = React.useState(false);
  const [isRedirectModalOpen, setIsRedirectModalOpen] = React.useState(false);

  const handleMenuItemClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClose = () => {
    setAnchorEl(null);
  };

  // Helper function to determine if a menu item is selected
  const isItemSelected = (value) => {
    if (value === "team") {
      return isEntityUserDetailsRoute || pathname.includes(`/${value}`);
    }
    if (value === "clientSpaces") {
      return (
        (isEntitiesRoute && !isEntityUserDetailsRoute) ||
        (pathname.includes(`/${value}`) && !pathname.includes("entity"))
      );
    }
    if (value === "spreadSheets") {
      return (
        pathname.split("/").includes(value) || pathname.includes("spreadsheet")
      );
    }

    return pathname.includes(`/${value}`);
  };

  // Handle navigation logic WITHOUT drawer closing
  const handleNavigation = (value) => {
    // Special handling for admin profile pages that should always be navigable from anywhere
    if (value === "firmProfile") {
      navigate(`/accountingFirm/${accountingFirmId}/${value}`, {
        state: { data: pathname.split("/")[2] },
      });
      return;
    }

    if (value === "team") {
      if (isEntitiesRoute || isEntityUserDetailsRoute) {
        navigate(
          `accountingFirm/${accountingFirmId}/clientSpace/${clientSpaceId}/entityUserDetails`
        );
      } else if (path.length < 7) {
        navigate(`accountingFirm/${accountingFirmId}/${value}`, {
          state: { data: pathname.split("/")[2] },
        });
      } else {
        setIsConfirmModalOpen(true);
      }
    } else if (value === "clientSpaces") {
      if (isEntityUserDetailsRoute) {
        navigate(
          `/accountingFirm/${accountingFirmId}/clientSpace/${clientSpaceId}/entities`
        );
      } else if (isEntitiesRoute) {
        // Already on entities page, no navigation needed
      } else if (path.length < 7) {
        navigate(`accountingFirm/${accountingFirmId}/${value}`, {
          state: { data: pathname.split("/")[2] },
        });
      } else {
        setIsConfirmModalOpen(true);
      }
    } else if (
      Object.keys(labels).includes(value) &&
      path.length >= 8 &&
      !path.includes("onboarding")
    ) {
      // Navigation for label items
      navigate(
        `accountingFirm/${accountingFirmId}/clientSpace/${clientSpaceId}/entity/${entityId}/${value}`
      );
    } else if (path.length < 7) {
      navigate(`accountingFirm/${accountingFirmId}/${value}`, {
        state: { data: pathname.split("/")[2] },
      });
    } else {
      setIsConfirmModalOpen(true);
    }
  };

  // Render menu item with fixed styling
  const renderMenuItem = (value, label) => {
    const selected = isItemSelected(value);

    return (
      <ListItem key={value} disablePadding sx={{ display: "block", px: 0 }}>
        <ListItemButton
          id={`nav-${value.split(" ").join("")}`}
          sx={{
            backgroundColor: selected
              ? ColorPalette.primary
              : ColorPalette.white,
            borderRadius: "8px",
            margin: open ? "8px 8px" : "8px auto",
            maxWidth: open ? "auto" : "80%",
            color: selected ? ColorPalette.white : ColorPalette.gray,
            minHeight: 48,
            justifyContent: open ? "flex-start" : "center",
            alignItems: "center",
            px: open ? 2 : 0,
            width: open ? "auto" : "44px",
            "&:hover": {
              backgroundColor: selected ? ColorPalette.primary : "#f5f5f5",
            },
          }}
          onClick={() => handleNavigation(value)}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 2 : 0,
              justifyContent: "center",
              color: selected ? ColorPalette.white : ColorPalette.gray,
              fontSize: "1.2rem",
            }}
          >
            {renderIcon(value)}
          </ListItemIcon>
          {open && <ListItemText primary={label} />}
        </ListItemButton>
      </ListItem>
    );
  };

  return (
    <Drawer
      variant={"permanent"}
      open={open}
      onClose={() => setOpen(!open)}
      style={{ zIndex: 1000 }}
      PaperProps={{
        sx: {
          overflowX: "hidden",
        },
      }}
    >
      <DrawerHeader />

      <Box
        sx={{
          height: "calc(100vh - 64px)",
          display: "flex",
          flexDirection: "column",
          justifyContent: !open ? "flex-start" : "space-between",
          overflowX: "hidden",
          width: "100%",
        }}
      >
        <Box
          sx={{
            overflowY: "auto",
            overflowX: "hidden",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            flexGrow: !open ? 1 : 0,
          }}
        >
          {path.length >= 8 && !path.includes("onboarding") && (
            <>
              <List sx={{ py: 0, width: "100%" }}>
                {Object.entries(labels).map(([value, label]) =>
                  renderMenuItem(value, label)
                )}
              </List>
              <Divider sx={{ width: "100%", my: 1 }} />
            </>
          )}

          <List sx={{ py: 0, width: "100%" }}>
            {Object.entries(adminLabels)
              .filter(([value]) => value !== "firmProfile" || role === "ADMIN")
              .map(([value, label], index) => (
                <React.Fragment key={value}>
                  {renderMenuItem(value, label)}
                  {index === 1 && <Divider sx={{ my: 1 }} />}
                </React.Fragment>
              ))}
          </List>
        </Box>

        <Box
          sx={{
            pb: 2,
            width: "100%",
            display: "flex",
            justifyContent: "center",
            mt: !open ? "auto" : 0,
          }}
        >
          <ListItemButton
            onClick={handleMenuItemClick}
            sx={{
              backgroundColor: ColorPalette.white,
              borderRadius: "8px",
              margin: open ? "8px 8px" : "8px auto",
              maxWidth: open ? "auto" : "80%",
              color: ColorPalette.gray,
              minHeight: 48,
              justifyContent: open ? "flex-start" : "center",
              px: open ? 2 : 0,
              width: open ? "auto" : "44px",
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 2 : 0,
                justifyContent: "center",
                display: "flex",
              }}
            >
              <AccountCircleOutlined />
            </ListItemIcon>
            {open && <ListItemText primary={stateData.name} />}
            {open && (
              <>
                {Boolean(anchorEl) ? (
                  <KeyboardArrowUpOutlined />
                ) : (
                  <KeyboardArrowDownOutlined />
                )}
              </>
            )}
          </ListItemButton>
          <Menu
            id="long-menu"
            MenuListProps={{
              "aria-labelledby": "long-button",
            }}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuItemClose}
          >
            <Grid
              sx={{
                minWidth: "200px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <MenuItem
                onClick={(e) => {
                  e.stopPropagation();
                  handleMenuItemClose();
                  handleNavigation("userProfile");
                  if (open) {
                    setOpen(false);
                  }
                }}
              >
                <Typography
                  className="menuItemTypography"
                  sx={{
                    color: ColorPalette.gray,
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <SettingsApplicationsOutlinedIcon fontSize="small" />
                  <span>User Profile</span>
                </Typography>
              </MenuItem>
              <Divider />
              <MenuItem
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  handleMenuItemClose();
                  localStorage.clear();
                  dispatch(reset());
                  navigate("./login", { state: { isLoggedOut: true } });
                }}
              >
                <Typography
                  sx={{
                    color: ColorPalette.gray,
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                  }}
                  className="menuItemTypography"
                >
                  <ExitToAppOutlined fontSize="small" />
                  <span>Logout</span>
                </Typography>
              </MenuItem>
            </Grid>
          </Menu>
        </Box>
      </Box>

      <ConfirmModal
        message="Do you want to return back to Admin Panel?"
        declineFunction={() => setIsConfirmModalOpen(false)}
        confirmFunction={() => {
          setIsConfirmModalOpen(false);
          setIsRedirectModalOpen(true);
        }}
        setModalOpen={setIsConfirmModalOpen}
        modalOpen={isConfrimModalOpen}
        confirmText="Return"
        declineText="Cancel"
      />

      {isRedirectModalOpen && (
        <ConfirmModal
          message="Redirect to:"
          setModalOpen={setIsRedirectModalOpen}
          modalOpen={isRedirectModalOpen}
          declineFunction={() => {
            localStorage.removeItem("currentEntityId");
            navigate(
              `/accountingFirm/${accountingFirmId}/clientSpace/${clientSpaceId}/entities`
            );
            setIsRedirectModalOpen(false);
          }}
          confirmFunction={() => {
            localStorage.removeItem("currentEntityId");
            navigate(`/accountingFirm/${accountingFirmId}/clientSpaces`);
            setIsRedirectModalOpen(false);
          }}
          confirmText="ClientSpace"
          declineText="Entity"
          buttonVariant="outlined"
        />
      )}
    </Drawer>
  );
}

import { Button } from "@mui/material";
import React from "react";
import { ColorPalette } from "../../constants/colorPalette";

export const ButtonComponent = ({
  title,
  contained,
  startIcon,
  onClick,
  color,
  disabled,
  size,
  styles,
  type,
  fullwidth,
  className,
}) => {
  return (
    <Button
      fullWidth={fullwidth}
      variant={contained ? "contained" : "outlined"}
      startIcon={startIcon}
      disabled={disabled}
      size={size}
      onClick={onClick}
      className={className}
      sx={{
        ...styles,
        backgroundColor:
          contained && color
            ? color
            : contained
            ? ColorPalette.primary
            : undefined,
        color: ((contained && color) || !contained) && ColorPalette.text,
        textTransform: "none",
        border: !contained && `1px solid ${ColorPalette.borderColor}`,
        "&:hover": {
          backgroundColor:
            contained && color
              ? color
              : contained
              ? ColorPalette.primaryHover
              : ColorPalette.button1Hover,
          border: !contained && `1px solid ${ColorPalette.borderColor}`,
        },
        "&:disabled": {
          backgroundColor: ColorPalette.disabled,
          border: "none",
        },
      }}
      type={type}
    >
      {title}
    </Button>
  );
};

import { useEffect, useRef, useState } from "react";
import {
  Add,
  AddCircle,
  Close,
  GridViewRounded,
  List,
  MoreVert,
  Search,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Menu,
  MenuItem,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import sheetsLogo from "../../../assets/sheets.svg";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import Loading from "../../../components/LoadingIndicator/Loading";
import TableListCard from "../../../components/TableListCard/TableListCard";
import {
  addSpreadSheet,
  deleteSpreadsheet,
  duplicateSpreadsheet,
  getSpreadSheets,
  updateSpreadsheetData,
} from "../../../redux/SpreadSheet/SpreadSheetSlice";
import { useNavigate, useParams } from "react-router-dom";
import ConfirmModal from "../../../components/CustomModal/ConfirmModal";
import NoDataView from "../../../components/NoDataView/NoDataView";
import xeroLogo from "../../../assets/xero.svg";
import { toTitleCase } from "../../../helper/titleCaseConverter";
import { ColorPalette } from "../../../constants/colorPalette";
import { Formik } from "formik";
import _ from "lodash";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import HistoryTableModal from "../../../components/HistoryTable/HistoryTableModal";
import { ButtonComponent } from "../../../components/Button/Button";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 800,
  minWidth: 350,
  bgcolor: "white",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

const getTabColor = (type, value) => {
  return type === value
    ? {
        backgroundColor: "#F2F2F2",
        border: "0.5px solid #9B9AAD",
        color: "black",
        borderRadius: "0px",
      }
    : {
        backgroundColor: "#FFFFFF",
        border: "0.5px solid #FFFFFF",
        color: "black",
        borderRadius: "0px",
      };
};

const getSelectionColor = (type, value) => {
  return type === value
    ? {
        backgroundColor: "#F2F2F2",
        border: "0.5px solid #03A561",
      }
    : {
        backgroundColor: "#FFFFFF",
      };
};

export default function SpreadsheetList() {
  const [viewStyle, setViewStyle] = useState("grid");
  const { clientSpaceId, entityId, accountingFirmId } = useParams();
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const userId = JSON.parse(localStorage.getItem("user_data"))?.userId || "";
  const defaultSpreadsheetValue = {
    name: "",
    type: "scratch",
    createdBy: userId,
  };
  const [newSpreadSheet, setNewSpreadSheet] = useState({
    ...defaultSpreadsheetValue,
  });
  const [spreadsheetCreationType, setSpreadsheetCreationType] =
    useState("scratch");
  const { spreadsheets, isFetching } = useSelector(
    (state) => state.spreadsheet
  );
  const dispatch = useDispatch();
  const timer = useRef(null);
  const [newSheetOpen, setNewSheetOpen] = useState(false);
  const [editSheetOpen, setEditSheetOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [spreadsheetId, setSpreadsheetId] = useState(null);
  const [isDuplicate, setIsDuplicate] = useState(false);
  const [duplicate, setDuplicate] = useState(null);
  const [selectedSpreadsheet, setSelectedSpreadsheet] = useState(null);
  const [selectedOriginalValue, setSelectedOriginalValue] = useState(null);
  const [historyModalOpen, setHistoryModalOpen] = useState(false);

  useEffect(() => {
    clearTimeout(timer.current);
    timer.current = setTimeout(
      () => {
        dispatch(getSpreadSheets({ entityId, search, accountingFirmId }));
      },
      search === "" ? 0 : 1000
    );
    // eslint-disable-next-line
  }, [search]);

  const closeNewSheetModal = () => {
    setNewSheetOpen(false);
    setSpreadsheetCreationType("scratch");
    setNewSpreadSheet({
      ...defaultSpreadsheetValue,
    });
    setIsDuplicate(false);
    setDuplicate(null);
  };

  const closeEditSheetModal = () => {
    setEditSheetOpen(false);
    setSelectedSpreadsheet(null);
  };

  const saveSpreadSheet = () => {
    if (!isDuplicate) {
      dispatch(
        addSpreadSheet({ payload: newSpreadSheet, entityId, accountingFirmId })
      );
    } else {
      dispatch(
        duplicateSpreadsheet({
          payload: {
            name: newSpreadSheet?.name,
            id: duplicate,
            createdBy: userId,
          },
          entityId,
          accountingFirmId,
        })
      );
    }

    closeNewSheetModal();
  };
  const handleClick = (event) => {
    event.stopPropagation();
    setIsMenuOpen(!isMenuOpen);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setIsMenuOpen(false);
    setAnchorEl(null);
  };

  const handleDelete = () => {
    handleClose();
    dispatch(deleteSpreadsheet({ spreadsheetId, accountingFirmId }));
    setDeleteModal(false);
    setSpreadsheetId(null);
  };

  const handleConfirmDelete = (id) => {
    setAnchorEl(null);
    setDeleteModal(true);
    setSpreadsheetId(id);
  };

  const handleDuplicate = (data) => {
    handleClose();
    setIsDuplicate(true);
    setDuplicate(data._id);
    setAnchorEl(null);
    setNewSpreadSheet({
      name: data.name + " (Copy)",
      createdBy: userId,
    });
    setNewSheetOpen(true);
  };
  const handleEdit = (data) => {
    setSelectedSpreadsheet(data);
    setSelectedOriginalValue(_.cloneDeep(data));
    setEditSheetOpen(true);
    setAnchorEl(null);
  };
  const handleSaveEditedSpreadsheet = () => {
    dispatch(
      updateSpreadsheetData({
        spreadSheetId: selectedSpreadsheet._id,
        accountingFirmId,
        payload: { query: { name: selectedSpreadsheet.name }, entityId },
      })
    );
    setEditSheetOpen(false);
    selectedSpreadsheet(null);
    setSelectedOriginalValue(null);
  };
  const handleSpreadsheetOpen = (spreadSheetId) => {
    navigate(
      `/accountingFirm/${accountingFirmId}/clientSpace/${clientSpaceId}/entity/${entityId}/spreadsheet/${spreadSheetId}`
    );
  };

  return (
    <div className="p-10">
      {historyModalOpen && (
        <HistoryTableModal
          modalOpen={historyModalOpen}
          setModalOpen={setHistoryModalOpen}
          pageType={"SPREADSHEET"}
          operations={["Add", "Update", "Delete"]}
        />
      )}
      <Grid container>
        <Grid item xs={12} style={{ padding: "20px" }}>
          <Grid container justifyContent={"space-between"}>
            <Grid item xs={8} sm={4} lg={3} className="mb-10">
              <Grid container>
                <Grid item xs={12}>
                  <TextField
                    className="textfield-white"
                    size="small"
                    placeholder="Search"
                    fullWidth
                    value={search}
                    name="Search"
                    disabled={isFetching}
                    onChange={({ target }) => {
                      setSearch(target.value);
                    }}
                    InputProps={{
                      endAdornment: (
                        <>
                          {search !== "" && (
                            <IconButton onClick={() => setSearch("")}>
                              <Close />
                            </IconButton>
                          )}

                          <InputAdornment position="start">
                            <Search />
                          </InputAdornment>
                        </>
                      ),
                    }}
                  ></TextField>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={8} lg={5}>
              <Grid container justifyContent={"flex-end"}>
                <Grid item className="mb-10">
                  <ButtonComponent
                    // size="small"
                    disabled={isFetching}
                    contained
                    startIcon={<Add />}
                    onClick={() => setNewSheetOpen(true)}
                    title={"New Sheet"}
                  />
                </Grid>
                <Grid item className="ml-10">
                  <Button
                    variant="contained"
                    style={getTabColor("grid", viewStyle)}
                    startIcon={<GridViewRounded />}
                    onClick={() => {
                      setViewStyle("grid");
                    }}
                  >
                    Grid View
                  </Button>
                  <Button
                    variant="contained"
                    style={getTabColor("list", viewStyle)}
                    startIcon={<List />}
                    onClick={() => {
                      setViewStyle("list");
                    }}
                  >
                    List View
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {!isFetching && spreadsheets.length !== 0 && (
          <Grid item xs={12}>
            {viewStyle === "list" && (
              <div>
                <TableListCard title={"Spreadsheet List"} marginTop={3}>
                  <div style={{ padding: "20px" }}>
                    <TableContainer
                      style={{
                        height: "70vh",
                        overflowY: "auto",
                        maxHeight: "60vh",
                        maxWidth: {
                          xs: 260,
                          sm: 290,
                          md: 580,
                          lg: "inherit",
                        },
                      }}
                    >
                      <Table stickyHeader>
                        <TableHead>
                          <TableRow>
                            <TableCell>
                              <Typography fontWeight={"bold"}>Name</Typography>
                            </TableCell>
                            <TableCell>
                              <Typography fontWeight={"bold"}>
                                Creation Type
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography fontWeight={"bold"}>
                                Created By
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography fontWeight={"bold"}>
                                Created At
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography fontWeight={"bold"} align="right">
                                Options
                              </Typography>
                            </TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {spreadsheets.map((data, index) => {
                            return (
                              <TableRow key={index}>
                                <TableCell>{data.name}</TableCell>
                                <TableCell>
                                  {data?.initializationType.toUpperCase()}
                                </TableCell>
                                <TableCell>
                                  {data.createdBy.first_name}{" "}
                                  {data.createdBy.last_name}
                                </TableCell>
                                <TableCell>
                                  {data.createdAt.split("T")[0]}
                                </TableCell>

                                <TableCell align="right">
                                  <Button
                                    sx={{
                                      textTransform: "none",
                                      backgroundColor: ColorPalette.primary,
                                    }}
                                    variant="contained"
                                    onClick={() =>
                                      handleSpreadsheetOpen(data._id)
                                    }
                                  >
                                    View
                                  </Button>
                                </TableCell>
                                <TableCell>
                                  <IconButton
                                    onClick={handleClick}
                                    size="small"
                                    id={"more-" + data._id}
                                  >
                                    <MoreVert />
                                  </IconButton>
                                  <Menu
                                    id="menu"
                                    MenuListProps={{
                                      "aria-labelledby": "more-" + data._id,
                                    }}
                                    anchorEl={anchorEl}
                                    open={anchorEl?.id === "more-" + data._id}
                                    onClose={handleClose}
                                    PaperProps={{
                                      style: {
                                        maxHeight: 30 * 4.5,
                                        width: "20ch",
                                      },
                                    }}
                                  >
                                    <MenuItem
                                      key={"edit"}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleEdit(data);
                                      }}
                                    >
                                      Edit
                                    </MenuItem>
                                    <MenuItem
                                      key={"duplicate"}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleDuplicate(data);
                                      }}
                                    >
                                      Duplicate
                                    </MenuItem>
                                    <MenuItem
                                      style={{ color: "red " }}
                                      key={"delete"}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleConfirmDelete(data._id);
                                      }}
                                    >
                                      Delete
                                    </MenuItem>
                                  </Menu>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </TableListCard>
              </div>
            )}
            {viewStyle === "grid" && (
              <Grid container>
                <Grid
                  item
                  xs={8}
                  sx={{
                    height: "70vh",
                    overflow: "auto",
                  }}
                >
                  <Grid container>
                    {spreadsheets.map((data, index) => {
                      return (
                        <Grid
                          item
                          xs={6}
                          sm={4}
                          md={3}
                          lg={2}
                          key={index}
                          style={{
                            padding: "20px",
                          }}
                        >
                          <div
                            className="spreadsheet-grid-container "
                            onClick={(e) => {
                              if (!isMenuOpen) {
                                handleSpreadsheetOpen(data._id);
                              }
                            }}
                          >
                            <div className="mb-10" style={{ width: "100%" }}>
                              <Avatar
                                src={sheetsLogo}
                                alt="Sheets"
                                className="mt-20 mb-10"
                                sx={{
                                  width: 80,
                                  height: 80,
                                  mx: "auto",
                                  mb: 2,
                                }}
                              />
                            </div>
                            <Divider className="mb-10" />
                            <div className=" ">
                              <Tooltip title={data.name} placement="top-start">
                                <Typography
                                  fontWeight={"bold"}
                                  className=" text-overflow"
                                >
                                  {data.name}
                                </Typography>
                              </Tooltip>
                            </div>
                            <div>({toTitleCase(data?.initializationType)})</div>
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                              className="mb-10"
                            >
                              <div className="mt-5">
                                {data.createdAt.split("T")[0]}
                              </div>
                              <div>
                                <IconButton
                                  onClick={(e) => handleClick(e)}
                                  size="small"
                                  id={"more-" + data._id}
                                >
                                  <MoreVert />
                                </IconButton>
                                <Menu
                                  id="menu"
                                  MenuListProps={{
                                    "aria-labelledby": "more-" + data._id,
                                  }}
                                  anchorEl={anchorEl}
                                  open={anchorEl?.id === "more-" + data._id}
                                  onClose={handleClose}
                                  PaperProps={{
                                    style: {
                                      maxHeight: 30 * 4.5,
                                      width: "20ch",
                                    },
                                  }}
                                >
                                  <MenuItem
                                    key={"edit"}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleEdit(data);
                                    }}
                                  >
                                    Edit
                                  </MenuItem>
                                  <MenuItem
                                    key={"duplicate"}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleDuplicate(data);
                                    }}
                                  >
                                    Duplicate
                                  </MenuItem>
                                  <MenuItem
                                    style={{ color: "red " }}
                                    key={"delete"}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleConfirmDelete(data._id);
                                    }}
                                  >
                                    Delete
                                  </MenuItem>
                                </Menu>
                              </div>
                            </div>
                          </div>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Grid>
                <Grid
                  sx={{
                    position: "fixed",
                    bottom: "50px",
                    right: "40px",
                    zIndex: 1000,
                  }}
                >
                  <ButtonComponent
                    title={"Activity Log"}
                    startIcon={<VisibilityOutlinedIcon />}
                    onClick={() => setHistoryModalOpen(true)}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
        )}
        {isFetching && <Loading />}
        {!isFetching && spreadsheets.length === 0 && search !== "" && (
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            Search Results empty
          </div>
        )}
        {!isFetching && spreadsheets.length === 0 && search === "" && (
          <NoDataView
            hintText="Sorry, you haven't added any spreadsheet"
            buttonText="Add new sheet"
            addFunction={() => setNewSheetOpen(true)}
            showButton={true}
          />
        )}
      </Grid>

      <Modal
        open={newSheetOpen}
        onClose={closeNewSheetModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Formik
          initialValues={{
            spreadsheetName: newSpreadSheet.name,
          }}
          validate={(values) => {
            const errors = {};
            if (!values.spreadsheetName) {
              errors.spreadsheetName = "No spreadsheet name";
            }
            if (
              spreadsheets
                .map((val) => val.name.trim())
                .includes(values.spreadsheetName.trim())
            ) {
              errors.spreadsheetName = "Spreadsheet name already exists";
            }
            return errors;
          }}
          onSubmit={(value) => {
            saveSpreadSheet();
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box sx={style}>
                <Grid container>
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      pb={1}
                      display={"flex"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <Typography
                        id="modal-modal-title"
                        fontSize={20}
                        fontWeight={"bold"}
                        component="h2"
                      >
                        {isDuplicate ? "Duplicate" : "New"} Spreadsheet
                      </Typography>
                      <IconButton
                        id="button-addGroupCloseIcon"
                        style={{ borderRadius: 0 }}
                        size="small"
                        onClick={closeNewSheetModal}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Grid>
                    <Divider
                      sx={{ width: "100%", color: ColorPalette.danger }}
                    />
                  </Grid>
                  <Grid item xs={12} pt={2} px={2}>
                    <Grid container>
                      <Grid item xs={12} className="mt-10">
                        <InputLabel className="mb-10">Name:</InputLabel>
                        <TextField
                          size="small"
                          fullWidth
                          variant="outlined"
                          name="spreadsheetName"
                          value={values.spreadsheetName}
                          error={
                            !!errors.spreadsheetName &&
                            !!touched.spreadsheetName
                          }
                          helperText={
                            touched.spreadsheetName && errors.spreadsheetName
                          }
                          onChange={(e) => {
                            handleChange(e);
                            const newName = e.target.value;
                            setFieldValue("spreadsheetName", newName);
                            setNewSpreadSheet({
                              ...newSpreadSheet,
                              name: newName,
                            });
                          }}
                        ></TextField>
                      </Grid>
                    </Grid>
                    {!isDuplicate && (
                      <Grid item xs={12} className="mt-30">
                        <Typography variant={"body-1"}>
                          How would you like to start the spreadsheet ?
                        </Typography>
                        <Grid container className="mt-20">
                          <div
                            style={{
                              ...getSelectionColor(
                                "scratch",
                                spreadsheetCreationType
                              ),
                            }}
                            className="selection-tile"
                            onClick={() => {
                              setSpreadsheetCreationType("scratch");
                              setNewSpreadSheet({
                                ...newSpreadSheet,
                                type: "scratch",
                              });
                            }}
                          >
                            <AddCircle
                              color="disabled"
                              sx={{ fontSize: 45 }}
                              className="mt-5"
                            />
                            <p className="m-5"> From Scratch </p>
                          </div>
                          <div
                            variant="contained"
                            className="ml-20 selection-tile"
                            style={getSelectionColor(
                              "xero",
                              spreadsheetCreationType
                            )}
                            onClick={() => {
                              setSpreadsheetCreationType("xero");
                              setNewSpreadSheet({
                                ...newSpreadSheet,
                                type: "xero",
                              });
                            }}
                          >
                            <Avatar
                              src={xeroLogo}
                              alt="Image 1"
                              sx={{ width: 55, height: 55 }}
                            />
                            <p className="m-5"> From Xero </p>
                          </div>
                        </Grid>
                      </Grid>
                    )}
                    <Grid item xs={12} className="mt-20">
                      <Grid container justifyContent={"flex-end"}>
                        <ButtonComponent
                          title={"Cancel"}
                          onClick={closeNewSheetModal}
                        >
                          Cancel
                        </ButtonComponent>
                        <ButtonComponent
                          type="submit"
                          className="ml-10"
                          contained
                          title={"Save"}
                          // onClick={saveSpreadSheet}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </form>
          )}
        </Formik>
      </Modal>
      <Modal
        open={editSheetOpen}
        onClose={closeEditSheetModal}
        aria-labelledby="edit-modal-title"
        aria-describedby="edit-modal-description"
      >
        <Formik
          initialValues={{
            spreadsheetName: selectedSpreadsheet?.name,
          }}
          validate={(values) => {
            const errors = {};
            if (!values.spreadsheetName) {
              errors.spreadsheetName = "No spreadsheet name";
            }
            if (
              spreadsheets
                .map((val) => val.name.trim())
                .includes(values.spreadsheetName.trim())
            ) {
              errors.spreadsheetName = "Spreadsheet name already exists";
            }
            return errors;
          }}
          onSubmit={() => {
            handleSaveEditedSpreadsheet();
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box sx={style}>
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    pb={1}
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <Typography
                      id="modal-modal-title"
                      fontSize={20}
                      fontWeight={"bold"}
                      component="h2"
                    >
                      Edit Spreadsheet
                    </Typography>
                  </Grid>
                  <Grid item xs={12} pt={2} px={2}>
                    <Grid container>
                      <Grid item xs={12} className="mt-10">
                        <InputLabel className="mb-10">Name:</InputLabel>
                        <TextField
                          size="small"
                          fullWidth
                          variant="outlined"
                          name="spreadsheetName"
                          value={values.spreadsheetName}
                          error={
                            !!errors.spreadsheetName &&
                            !!touched.spreadsheetName
                          }
                          helperText={
                            touched.spreadsheetName && errors.spreadsheetName
                          }
                          onChange={(e) => {
                            handleChange(e);
                            const newName = e.target.value;
                            setFieldValue("spreadsheetName", newName);
                            setSelectedSpreadsheet({
                              ...selectedSpreadsheet,
                              name: newName,
                            });
                          }}
                        ></TextField>
                      </Grid>
                    </Grid>

                    <Grid item xs={12} className="mt-20">
                      <Grid container justifyContent={"flex-end"}>
                        <ButtonComponent
                          title={"Cancel"}
                          onClick={closeEditSheetModal}
                        />

                        <ButtonComponent
                          type="submit"
                          className="ml-10"
                          contained
                          disabled={
                            selectedSpreadsheet?.name ===
                            selectedOriginalValue?.name
                          }
                          title={"Save"}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </form>
          )}
        </Formik>
      </Modal>
      <ConfirmModal
        message="Are you sure you want to delete the spreadsheet?"
        declineFunction={() => setDeleteModal(false)}
        confirmFunction={() => {
          handleDelete();
        }}
        setModalOpen={setDeleteModal}
        modalOpen={deleteModal}
      />
    </div>
  );
}

import { Grid, InputLabel, Typography, TextField } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { ColorPalette } from "../../../constants/colorPalette";
import { Formik } from "formik";
import ConfirmModal from "../../../components/CustomModal/ConfirmModal";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { accFirmProfile } from "../../../redux/User/UserSlice";
import { checkDataAvailibility } from "../../../helper/utils";
import Loading from "../../../components/LoadingIndicator/Loading";
import { CloseOutlined, EditOutlined } from "@mui/icons-material";
import userProfileIcon from "../../../assets/user-profile.svg";
import { ButtonComponent } from "../../../components/Button/Button";

function FirmProfile() {
  const fileInputRef = useRef();
  const dispatch = useDispatch();
  const { accountingFirmId } = useParams();
  const users = useSelector((state) => state.user);
  const [formValues, setFormValues] = useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(users.isFetching);
  const fileSize = 2097152;

  const stateData = checkDataAvailibility(users);

  useEffect(() => {
    setIsLoading(users.isFetching);
  }, [users.isFetching]);

  const handleSubmit = () => {
    const formData = new FormData();
    if (formValues) {
      formData.append("accFirmImg", formValues.file);
      formData.append("firmName", formValues.firmName);
      dispatch(accFirmProfile({ formData, accountingFirmId }));
      setConfirmModalOpen(false);
    }
  };

  return (
    <div style={{ display: "flex", margin: "2%" }}>
      {confirmModalOpen && (
        <ConfirmModal
          message={"Are you sure ypu want to save the changed inputs?"}
          confirmFunction={handleSubmit}
          declineFunction={() => setConfirmModalOpen(false)}
          modalOpen={confirmModalOpen}
          setModalOpen={setConfirmModalOpen}
        />
      )}

      <Formik
        initialValues={{
          firmName: stateData.accFirmName,
          adminEmail: "",
          file: stateData.accFirmImg || null,
        }}
        validate={(values) => {
          const errors = {};

          // File validation
          if (values.file) {
            if (values.file.size >= fileSize) {
              errors.file = "Please upload an image smaller than 2MB";
            } else if (
              values.file.type !== "image/svg+xml" &&
              values.file.type !== "image/svg"
            ) {
              errors.file = "Please upload an SVG image";
            }
          }

          if (!values.firmName) {
            errors.firmName = "Please enter firm name";
          } else if (
            values.firmName.length !== 0 &&
            /[!@#$%^&*]/g.test(values.firmName)
          ) {
            errors.firmName = "Special characters not allowed";
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          setFormValues(values);
          setConfirmModalOpen(true);
          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          setFieldValue,
          dirty,
          isValid,
          resetForm,
        }) => (
          <form onSubmit={handleSubmit} style={{ width: "100%" }}>
            {isLoading ? (
              <Loading />
            ) : (
              <>
                <Grid
                  container
                  sx={{
                    width: "700px",
                    backgroundColor: "#FFFFFF",
                    borderRadius: "10px",
                    padding: "20px",
                    marginTop: "20px",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                    minHeight: "34vh",
                  }}
                >
                  <Grid container>
                    <Typography variant="h6" color={ColorPalette.black}>
                      Accounting Firm Details
                    </Typography>

                    {isLoading ? (
                      <Loading />
                    ) : (
                      <>
                        <Grid item xs={12} mt={4}>
                          <InputLabel
                            className="mb-20"
                            sx={{ color: ColorPalette.text }}
                          >
                            Accounting Firm Image
                          </InputLabel>
                          <Grid container alignItems="center" spacing={2}>
                            <Grid item>
                              {(values.file && !errors.file) ||
                              stateData.accFirmImg ? (
                                <img
                                  src={
                                    values.file
                                      ? URL.createObjectURL(values.file)
                                      : stateData.accFirmImg
                                  }
                                  alt="Firm Logo"
                                  width={120}
                                  height={120}
                                  style={{ borderRadius: "50%" }}
                                />
                              ) : (
                                <img
                                  src={userProfileIcon}
                                  alt="Firm Icon"
                                  width={120}
                                  height={120}
                                  style={{ borderRadius: "50%" }}
                                />
                              )}
                            </Grid>
                            <Grid item>
                              <input
                                type="file"
                                ref={fileInputRef}
                                style={{ display: "none" }}
                                onChange={(e) =>
                                  setFieldValue(
                                    "file",
                                    e.currentTarget.files[0]
                                  )
                                }
                              />
                              {values.file ? (
                                <Grid
                                  item
                                  sx={{
                                    position: "relative",
                                    right: 35,
                                    bottom: 35,
                                    zIndex: 1,
                                  }}
                                  onClick={() => {
                                    setFieldValue("file", null);
                                    fileInputRef.current.value = null;
                                  }}
                                >
                                  <CloseOutlined
                                    sx={{
                                      cursor: "pointer",
                                      boxShadow:
                                        "0px 3px 6px rgba(0, 0, 0, 0.16)",
                                      borderRadius: "6px",
                                      padding: "3px",
                                      backgroundColor: "white",
                                    }}
                                  />
                                </Grid>
                              ) : (
                                <Grid
                                  item
                                  sx={{
                                    position: "relative",
                                    right: 35,
                                    top: 30,
                                    zIndex: 1,
                                  }}
                                  onClick={() => fileInputRef.current?.click()}
                                >
                                  <EditOutlined
                                    fontSize="small"
                                    sx={{
                                      cursor: "pointer",
                                      boxShadow:
                                        "0px 3px 6px rgba(0, 0, 0, 0.16)",
                                      borderRadius: "6px",
                                      padding: "3px",
                                      backgroundColor: "white",
                                    }}
                                  />
                                </Grid>
                              )}
                            </Grid>
                          </Grid>
                          {errors.file && (
                            <Typography sx={{ color: "error.main" }}>
                              {errors.file}
                            </Typography>
                          )}
                        </Grid>

                        {/* Firm Name Input Section */}
                        <Grid container spacing={3} mt={1}>
                          <Grid item xs={12}>
                            <InputLabel className="mb-10">Firm Name</InputLabel>
                            <TextField
                              fullWidth
                              name="firmName"
                              size="small"
                              placeholder="Accounting firm name"
                              value={values.firmName}
                              error={!!errors.firmName && !!touched.firmName}
                              onChange={handleChange}
                              sx={{ maxWidth: "300px" }}
                            />
                            <Typography variant="body2" color="error" mt={0.5}>
                              {!!errors.firmName &&
                                !!touched.firmName &&
                                errors.firmName}
                            </Typography>
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </Grid>

                  <Grid
                    container
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="center"
                    gap={3}
                    mt={3}
                  >
                    <ButtonComponent
                      title={"Reset"}
                      onClick={() => resetForm()}
                      disabled={!dirty}
                    />
                    <ButtonComponent
                      title={"Save"}
                      contained={true}
                      type={"submit"}
                      disabled={!(dirty && isValid)}
                    />
                  </Grid>
                </Grid>
              </>
            )}
          </form>
        )}
      </Formik>
    </div>
  );
}

export default FirmProfile;

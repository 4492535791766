import React from "react";
import { getCellProperty } from "@silevis/reactgrid";

export class CalculationCell {
  getCompatibleCell(uncertainCell) {
    const text = getCellProperty(uncertainCell, "text", "string");
    return {
      ...uncertainCell,
      text,
    };
  }

  render(cell, isInEditMode, onCellChanged) {
    let backgroundColor =
      cell.text === "Add"
        ? "#EEECFF"
        : cell.text === "Subtract"
        ? "#EFF6FF"
        : cell.text === "Multiply"
        ? "#EAFBF0"
        : "#F5E5C0";
    let borderColor =
      cell.text === "Add"
        ? "#D1CBFF"
        : cell.text === "Subtract"
        ? "#CCE2FF"
        : cell.text === "Multiply"
        ? "#BBF1CE"
        : "#DDBB6C";

    let textColor =
      cell.text === "Add"
        ? "#440FA2"
        : cell.text === "Subtract"
        ? "#3A58B9"
        : cell.text === "Multiply"
        ? "#206F3B"
        : "#C28E14";

    return (
      <>
        {cell.text !== "-" && (
          <div
            style={{
              overflow: "clip",
              textOverflow: "ellipsis",
              border: "1px solid",
              padding: "4px",
              marginLeft: "5px",
              borderColor: borderColor,
              color: textColor,
              backgroundColor: backgroundColor,
              borderRadius: "6px",
            }}
          >
            {cell.text}
          </div>
        )}
      </>
    );
  }
}

import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  Typography,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  MenuItem,
  IconButton,
  Divider,
  Checkbox,
} from "@mui/material";
import { DeleteOutline, EditOutlined } from "@mui/icons-material";
import HistoryTableModal from "../../../../../components/HistoryTable/HistoryTableModal";
import { useNavigate, useParams } from "react-router-dom";
import { ColorPalette } from "../../../../../constants/colorPalette";
import Loading from "../../../../../components/LoadingIndicator/Loading";
import TableListCard from "../../../../../components/TableListCard/TableListCard";
import { ButtonComponent } from "../../../../../components/Button/Button";
import { toTitleCase } from "../../../../../helper/titleCaseConverter";
import ConfirmModal from "../../../../../components/CustomModal/ConfirmModal";
import EditEntityUsersModal from "../editEntityUsers/EditEntityUsersModal";

import NoDataView from "../../../../../components/NoDataView/NoDataView";
import CustomModal from "../../../../../components/CustomModal/CustomModal";
import { v4 as uuidv4 } from "uuid";
import CloseIcon from "@mui/icons-material/Close";
import { Select, TextField } from "@material-ui/core";
import AddIcon from "@mui/icons-material/Add";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { inviteUser } from "../../../../../redux/User/UserSlice";
// import { checkDataAvailibility } from "../../../../../helper/utils";

const EntityUserDetails = () => {
  const dispatch = useDispatch();
  const { accountingFirmId } = useParams();
  const navigate = useNavigate();
  const { currentClientSpace, isFetching } = useSelector(
    (state) => state.clientSpaces
  );
  const roles = ["ACCOUNTANT", "ADMIN", "CLIENT"];
  const [hasEmptyUsers, setHasEmptyUsers] = useState(true);
  const [historyModalOpen, setHistoryModalOpen] = useState(false);
  const [initialLoadingState, setInitialLoadingState] = useState(true);
  const [inviteModalOpen, setInviteModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [inviteConfirmModal, setInviteConfirmModal] = useState(false);
  // const [deleteId, setDeleteId] = useState(null);
  // const userData = useSelector((state) => state.user);
  // const stateData = checkDataAvailibility(userData);
  // const [editId, setEditId] = useState(null);
  const [selectedUsers, setSelectedUsers] = useState([
    {
      id: uuidv4(),
      email: "",
      role: "ACCOUNTANT",
      error: {
        email: null,
        role: null,
        entity: "null",
      },
    },
  ]);
  const [selectedEntities, setSelectedEntities] = useState([]);

  useEffect(() => {
    setHasEmptyUsers(
      selectedUsers.some(({ email, role }) => !email.trim() || !role.trim())
    );
  }, [selectedUsers, hasEmptyUsers]);

  useEffect(() => {
    setTimeout(() => {
      setInitialLoadingState(false);
    }, 500);
  }, []);

  const validateForm = () => {
    let hasError = false;
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

    // Check for empty fields and invalid emails
    const updatedUsers = selectedUsers.map((user) => {
      const error = { email: null, role: null };

      if (!user.email.trim()) {
        error.email = "Email is empty";
        hasError = true;
      } else if (!emailRegex.test(user.email)) {
        error.email = "Invalid email address";
        hasError = true;
      }

      if (!user.role.trim()) {
        error.role = "Role is empty";
        hasError = true;
      }
      return { ...user, error };
    });

    // Check for duplicate emails
    const emails = updatedUsers.map((user) =>
      user.email.trim().toLocaleLowerCase()
    );
    const duplicates = emails.filter(
      (email, index) => emails.indexOf(email) !== index
    );
    if (duplicates.length > 0) {
      updatedUsers.forEach((user) => {
        if (duplicates.includes(user.email.trim().toLocaleLowerCase())) {
          user.error.email = "Duplicate email";
          hasError = true;
        }
      });
    }

    setSelectedUsers(updatedUsers);
    return !hasError;
  };

  const confirmDeleteFunction = () => {
    // deleteCurrentInvitation(deleteId);
    setDeleteModalOpen(false);
  };

  const cancelInviteMembers = () => {
    setInviteModalOpen(false);
    setSelectedUsers([
      {
        id: uuidv4(),
        email: "",
        role: "ACCOUNTANT",
        error: { email: null, role: null },
      },
    ]);
  };

  const handleAddAnother = () => {
    setSelectedUsers([
      ...selectedUsers,
      {
        id: uuidv4(),
        email: "",
        role: "ACCOUNTANT",
        error: { email: null, role: null },
      },
    ]);
  };

  const handleAddUserChange = (id, fieldName, fieldValue) => {
    const updatedUser = selectedUsers.map((user) => {
      if (user.id === id) {
        return {
          ...user,
          [fieldName]: fieldValue,
          error: {
            ...user.error,
            [fieldName === "email" ? "name" : "type"]: null,
          },
        };
      }
      return user;
    });
    setSelectedUsers(updatedUser);
  };

  const sendInvitation = () => {
    const payload = selectedUsers?.map((item) => ({
      email: item.email,
      role: item.role,
      entityIds: selectedEntities,
    }));
    dispatch(inviteUser({ payload, accountingFirmId }));
    setInviteConfirmModal(false);
    // setSelectedUsers([
    //   {
    //     id: uuidv4(),
    //     email: "",
    //     role: "USER",
    //     error: { email: null, role: null },
    //   },
    // ]);
  };

  const handleDelete = (id) => {
    const updatedUser = selectedUsers.filter((entity) => entity.id !== id);
    setSelectedUsers(updatedUser);
  };

  const handleInviteClick = () => {
    const isValid = validateForm();
    if (isValid) {
      setInviteConfirmModal(true);
    }
  };

  return (
    <div
      style={{
        padding: "0 2%",
        position: "relative",
        minHeight: "calc(100vh - 100px)",
      }}
    >
      {historyModalOpen && (
        <HistoryTableModal
          modalOpen={historyModalOpen}
          setModalOpen={setHistoryModalOpen}
          pageType="ENTITY"
          operations={["Add", "Update", "Delete"]}
        />
      )}
      {deleteModalOpen && (
        <ConfirmModal
          modalOpen={deleteModalOpen}
          setModalOpen={setDeleteModalOpen}
          onClose={() => setDeleteModalOpen(false)}
          confirmFunction={confirmDeleteFunction}
          declineFunction={() => setDeleteModalOpen(false)}
          message="Are you sure you want to delete?"
        />
      )}

      {editModalOpen && (
        <EditEntityUsersModal
          modalOpen={editModalOpen}
          setModalOpen={setEditModalOpen}
        />
      )}

      {inviteConfirmModal && (
        <ConfirmModal
          modalOpen={inviteConfirmModal}
          setModalOpen={setInviteConfirmModal}
          onClose={() => setInviteConfirmModal(false)}
          confirmFunction={sendInvitation}
          declineFunction={() => setInviteConfirmModal(false)}
          message="Are you sure you want to send the invite?"
        />
      )}

      <CustomModal
        onClose={cancelInviteMembers}
        modalOpen={inviteModalOpen}
        setModalOpen={setInviteModalOpen}
        overflow="hidden"
        minWidth="50vw"
        maxWidth="md"
      >
        <div>
          {isFetching && (
            <Grid>
              <Loading wait={true} />
            </Grid>
          )}

          <Grid container padding={2}>
            <Grid item xs={12} mb={2}>
              <Grid
                container
                xs={12}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginBottom: "15px",
                }}
              >
                <Typography fontSize={18} fontWeight="bold">
                  Invite Entity Team Members
                </Typography>
                <IconButton
                  id="button-addGroupCloseIcon"
                  style={{ borderRadius: 0 }}
                  size="small"
                  onClick={cancelInviteMembers}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
              <Divider sx={{ width: "100%", color: ColorPalette.danger }} />
            </Grid>

            <Grid item xs={12} marginTop={2} px={2} pt={2}>
              <Grid style={{ maxHeight: "20vh", overflowY: "auto" }}>
                {selectedUsers.map((user, index) => (
                  <Grid
                    display="flex"
                    container
                    alignItems="flex-start"
                    justifyContent="space-between"
                    marginTop="10px"
                    key={user.id}
                  >
                    <Grid item xs={5.5}>
                      <Typography
                        id="text-addEmail"
                        className="mb-10"
                        color={ColorPalette.black}
                      >
                        Email
                      </Typography>
                      <TextField
                        size="small"
                        fullWidth
                        id="input-email"
                        name="email"
                        error={user.error.email && !!user.error.email}
                        variant="outlined"
                        value={user?.email}
                        onChange={(e) =>
                          handleAddUserChange(user.id, "email", e.target.value)
                        }
                      />
                      {user.error.email && (
                        <Typography color={"error"} fontSize={"12px"} my={1}>
                          {user.error.email}
                        </Typography>
                      )}
                    </Grid>

                    <Grid
                      style={{ display: "flex", alignItems: "center" }}
                      item
                      xs={5.5}
                    >
                      <Grid item xs={12}>
                        <Typography
                          id="text-addRole"
                          color={ColorPalette.black}
                          className="mb-10"
                        >
                          Role
                        </Typography>
                        <Select
                          size="small"
                          labelId="role"
                          id="input-role"
                          value={user.role}
                          fullWidth
                          error={user.error.role && !!user.error.role}
                          onChange={(e) =>
                            handleAddUserChange(user.id, "role", e.target.value)
                          }
                          variant="outlined"
                        >
                          {roles.map((type, index) => (
                            <MenuItem key={index} value={type}>
                              {toTitleCase(type)}
                            </MenuItem>
                          ))}
                        </Select>
                        {user.error.role && (
                          <Typography color={"error"} fontSize={"12px"} my={1}>
                            {user.error.role}
                          </Typography>
                        )}
                      </Grid>

                      {selectedUsers.length > 1 && (
                        <IconButton
                          id="button-deleteInvitationDetails"
                          style={{
                            marginTop: "auto",
                            color: ColorPalette.danger,
                          }}
                          size="medium"
                          onClick={() => handleDelete(user.id)}
                        >
                          <DeleteOutline />
                        </IconButton>
                      )}
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>

            {isFetching ? (
              <Grid
                container
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Loading />
              </Grid>
            ) : (
              <Fragment>
                <Grid
                  container
                  sx={{
                    border: `1px solid ${ColorPalette.borderColor}`,
                    borderRadius: "8px",
                    mt: "20px",
                    maxHeight: "30vh",
                    overflow: "auto",
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    sx={{
                      backgroundColor: ColorPalette.backgroundColor,
                      p: "15px",
                      "&:hover": { cursor: "pointer" },
                      fontWeight: "bold",
                    }}
                  >
                    <Typography>Entity List</Typography>
                  </Grid>

                  <Grid item xs={12} p={2}>
                    {currentClientSpace.clientSpaceEntities.map(
                      (entity, index) => (
                        <div key={entity.id}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              cursor: "pointer",
                              padding: "8px 0",
                              "&:hover": {
                                backgroundColor: ColorPalette.backgroundColor,
                              },
                            }}
                          >
                            <Checkbox
                              checked={selectedEntities.includes(entity._id)}
                              onChange={(e) => {
                                e.stopPropagation();
                                setSelectedEntities((prev) =>
                                  prev.includes(entity._id)
                                    ? prev.filter((id) => id !== entity._id)
                                    : [...prev, entity._id]
                                );
                              }}
                              size="small"
                              sx={{
                                padding: "4px",
                                color: ColorPalette.primary,
                                "&.Mui-checked": {
                                  color: ColorPalette.primary,
                                },
                              }}
                            />
                            {entity.entityName}
                          </div>
                          {index !==
                            currentClientSpace.clientSpaceEntities.length -
                              1 && (
                            <Divider
                              sx={{
                                my: 1,
                                bgcolor: ColorPalette.borderColor,
                              }}
                            />
                          )}
                        </div>
                      )
                    )}
                  </Grid>
                </Grid>
                {!selectedEntities.length && (
                  <Typography
                    color={"error"}
                    Please
                    select
                    any
                    one
                    of
                    the
                    entity
                  ></Typography>
                )}
              </Fragment>
            )}

            <Grid
              container
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Grid item>
                <ButtonComponent
                  startIcon={<AddIcon />}
                  styles={{ marginTop: "20px" }}
                  title={"Add Another"}
                  onClick={handleAddAnother}
                  disabled={hasEmptyUsers}
                />
              </Grid>
              <Grid
                item
                sx={{ display: "flex", gap: "10px", marginTop: "30px" }}
              >
                <ButtonComponent
                  onClick={cancelInviteMembers}
                  title={"Cancel"}
                />
                <ButtonComponent
                  contained={true}
                  onClick={handleInviteClick}
                  disabled={!selectedUsers?.length > 0 || isFetching}
                  title={"Invite"}
                />
              </Grid>
            </Grid>
          </Grid>
        </div>
      </CustomModal>

      <Grid container marginY={3}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Button
            onClick={() =>
              navigate(`../accountingFirm/${accountingFirmId}/clientSpaces`)
            }
            sx={{
              color: ColorPalette.primary,
              textTransform: "none",
              fontSize: "20px",
              "&:hover": {
                textDecoration: "underline",
              },
            }}
          >
            Firm Portal
          </Button>
          <Typography component="p" sx={{ margin: "0 5px" }}>
            /
          </Typography>
          <Typography color={ColorPalette.text}>
            {currentClientSpace.clientSpaceName}
          </Typography>
        </div>
      </Grid>

      {isFetching || initialLoadingState ? (
        <Loading />
      ) : (
        <Grid
          container
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"space-between"}
        >
          {!isFetching ? (
            <TableListCard
              title={"Entity Team List"}
              addTitle="Invite Users"
              setModalOpen={setInviteModalOpen}
            >
              <TableContainer
                style={{
                  minWidth: "900px",
                  maxHeight: "53vh",
                  overflow: "auto",
                  maxWidth: {
                    xs: 260,
                    sm: 290,
                    md: 580,
                    lg: "inherit",
                  },
                  border: `1px solid ${ColorPalette.borderColor}`,
                  borderRadius: "8px",
                }}
              >
                <Table stickyHeader>
                  <TableHead
                    style={{
                      position: "sticky",
                      top: "0",
                      zIndex: 1,
                      backgroundColor: ColorPalette.backgroundColor,
                    }}
                  >
                    <TableRow>
                      {["Email", "Name", "Invite", "Role"].map(
                        (headers, index) => (
                          <TableCell className="table-header" key={index}>
                            {headers}
                          </TableCell>
                        )
                      )}
                      {
                        // stateData.role === "ADMIN" &&
                        <TableCell className="table-header" align="right">
                          Actions
                        </TableCell>
                      }
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {currentClientSpace?.clientUsers?.map((row, index) => (
                      <TableRow
                        key={row.email + index}
                        sx={{
                          "&:last-child td, &:last-child th": {
                            border: 0,
                          },
                        }}
                      >
                        <TableCell width={"30%"} component="th" scope="row">
                          {row?.email}
                        </TableCell>
                        <TableCell>
                          {row?.first_name} {row?.last_name}
                        </TableCell>
                        <TableCell>
                          {row?.hasJoined ? (
                            <ButtonComponent
                              title={"Success"}
                              size={"small"}
                              contained={true}
                              color={ColorPalette.successColor}
                            />
                          ) : (
                            <ButtonComponent
                              title={"Pending"}
                              size={"small"}
                              contained={true}
                              color={ColorPalette.backgroundColor}
                            />
                          )}
                        </TableCell>
                        <TableCell>{toTitleCase(row?.role)}</TableCell>

                        {
                          // stateData.role === "ADMIN" &&
                          <TableCell scope="row" align="right" width={"20%"}>
                            <Grid
                              display={"flex"}
                              alignItems={"center"}
                              justifyContent={"right"}
                              mr={2}
                            >
                              {!row?.hasJoined ? (
                                <ButtonComponent
                                  size={"small"}
                                  contained={true}
                                  color={ColorPalette.backgroundColor}
                                  title={"Cancel"}
                                  onClick={() => {
                                    setDeleteModalOpen(true);
                                    // setDeleteId(row?._id);
                                  }}
                                />
                              ) : (
                                <Grid
                                  container
                                  display={"flex"}
                                  alignItems={"center"}
                                >
                                  <IconButton>
                                    <EditOutlined
                                      onClick={() => {
                                        setEditModalOpen(true);
                                        // setEditId(row?._id);
                                      }}
                                    />
                                  </IconButton>
                                  <IconButton
                                    onClick={() => {
                                      // setDeleteId(row?._id);
                                      setDeleteModalOpen(true);
                                    }}
                                  >
                                    <DeleteOutline color="error" />
                                  </IconButton>
                                </Grid>
                              )}
                              {/* {!row?.hasJoined && row?.status === "expired" && (
                             <Button
                               id="resend-button"
                               className="text-normal"
                               onClick={() => {
                                 setUpdateId(row?._id);
                                 setResendConfirmModal(true);
                               }}
                             >
                               <SendIcon
                                 sx={{ paddingRight: "4px" }}
                                 fontSize="small"
                               />
                               Resend
                             </Button>
                           )} */}
                            </Grid>
                          </TableCell>
                        }
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </TableListCard>
          ) : (
            <NoDataView hintText="Sorry you haven't any entity users" />
          )}

          {
            // stateData.role === "ADMIN" &&
            <Grid
              item
              sx={{
                position: "absolute",
                bottom: "5%",
                right: "2%",
                display: "flex",
                justifyContent: "right",
              }}
            >
              <ButtonComponent
                title={"Activity Log"}
                startIcon={<VisibilityOutlinedIcon />}
                onClick={() => setHistoryModalOpen(true)}
              />
            </Grid>
          }
        </Grid>
      )}
    </div>
  );
};

// // Extracted Menu Component for better readability
// const ActionMenu = ({ anchorEl, onClose, onEdit, onDelete }) => (
//   <Menu
//     id="long-menu"
//     MenuListProps={{ "aria-labelledby": "long-button" }}
//     anchorEl={anchorEl}
//     open={Boolean(anchorEl)}
//     onClose={onClose}
//   >
//     <MenuItem onClick={onEdit}>
//       <Typography className="menuItemTypography">
//         <EditOutlinedIcon className="mr-10" />
//         Edit
//       </Typography>
//     </MenuItem>
//     <MenuItem onClick={onDelete}>
//       <Typography style={{ color: "#D33030" }} className="menuItemTypography">
//         <DeleteOutlineOutlinedIcon className="mr-10" />
//         Delete
//       </Typography>
//     </MenuItem>
//   </Menu>
// );

export default EntityUserDetails;

import React, { useEffect, useState } from "react";
import "./ResetPassword.css";
import { Formik } from "formik";
import {
  TextField,
  Link,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormControl,
  InputLabel,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import logo from "../../../assets/formulr-logo.svg";
// import background from "../../../assets/login_template.jpeg";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { resetPassword, reset } from "../../../redux/User/UserSlice";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { ColorPalette } from "../../../constants/colorPalette";
import { ButtonComponent } from "../../../components/Button/Button";
export default function ResetPassword() {
  let search = window.location.search;
  let params = new URLSearchParams(search);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [id] = useState(params.get("id"));
  const [token] = useState(params.get("token"));
  const [email] = useState(params.get("email"));
  const [submitting, setSubmitting] = useState(false);
  const [showPassword, setShowPassword] = useState({
    password: false,
    passwordConfirm: false,
  });
  const { isSuccess, isError } = useSelector((state) => state.user);
  const onSubmit = (data) => {
    dispatch(resetPassword(data));
    setSubmitting(true);
  };
  useEffect(() => {
    if (isError) {
      dispatch(reset());
      setSubmitting(false);
    }
    if (isSuccess) {
      dispatch(reset());
      navigate("../login");
    }
  }, [isError, isSuccess, dispatch, navigate]);
  return (
    <div
    // style={{ backgroundImage: `url(${background})` }}
    >
      <div className="login-wrapper">
        <Formik
          initialValues={{
            password: "",
            passwordConfirm: "",
            id,
            token,
            email: email,
          }}
          validate={(values) => {
            const errors = {};
            if (values.password.length === 0) {
              errors.isPasswordEmpty = true;
              errors.password = "Required Field";
            }
            if (
              values.password.length !== 0 &&
              !/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/i.test(values.password)
            ) {
              errors.password =
                "Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters";
            }
            if (!values.passwordConfirm) {
              errors.isPasswordConfirmEmpty = true;
              errors.passwordConfirm = "Required Field";
            }
            if (values.password && values.passwordConfirm) {
              if (values.password !== values.passwordConfirm) {
                errors.passwordConfirm = "Please enter the same password.";
              }
            }
            return errors;
          }}
          onSubmit={(data, { setSubmitting }) => {
            onSubmit(data, setSubmitting);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            isSubmitting,
          }) => (
            <form
              className="form-wrapper-reset-password"
              onSubmit={handleSubmit}
            >
              <div
                style={{
                  paddingTop: "8%",
                  paddingBottom: "5%",
                  alignContent: "center",
                }}
              >
                <img
                  style={{
                    display: "block",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                  src={logo}
                  alt="Logo"
                />
              </div>
              <Grid justifyContent="center" container spacing={2}>
                <Grid item xs={9}>
                  <TextField
                    InputProps={{ readOnly: true }}
                    error={!!errors.email && !!touched.email}
                    fullWidth
                    id="input-resetPasswordEmail"
                    label="Email"
                    type="email"
                    name="email"
                    onChange={handleChange}
                    value={values.email}
                  />
                </Grid>
                {/* <Grid item xs={9}>
                  <TextField
                    error={!!errors.code && !!touched.code}
                    fullWidth
                    label="Code"
                    type="code"
                    name="code"
                    onChange={handleChange}
                    value={values.code}
                  />
                </Grid> */}
                <Grid item xs={9}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel
                      htmlFor="outlined-adornment-password"
                      shrink={!!values.password}
                    >
                      Password
                    </InputLabel>
                    <OutlinedInput
                      fullWidth
                      name="password"
                      notched={!!values.password}
                      label="Password"
                      type={showPassword.password ? "text" : "password"}
                      value={values.password}
                      error={!!errors.password && !!touched.password}
                      onChange={handleChange}
                      InputLabelProps={{ shrink: !!values.password }}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() =>
                              setShowPassword((state) => ({
                                ...state,
                                password: !state.password,
                              }))
                            }
                            onMouseDown={(e) => e.preventDefault()}
                            edge="end"
                            size="small"
                          >
                            {showPassword.password ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    ></OutlinedInput>
                  </FormControl>
                  <Typography
                    id="error-groupRequiredField"
                    variant="caption"
                    color={ColorPalette.danger}
                  >
                    {errors.password && !!touched.password && errors.password}
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel
                      htmlFor="outlined-adornment-password"
                      shrink={!!values.passwordConfirm}
                    >
                      Confirm Password
                    </InputLabel>
                    <OutlinedInput
                      fullWidth
                      name="passwordConfirm"
                      notched={!!values.passwordConfirm}
                      label="Confirm Password"
                      type={showPassword.passwordConfirm ? "text" : "password"}
                      value={values.passwordConfirm}
                      error={
                        !!errors.passwordConfirm && !!touched.passwordConfirm
                      }
                      onChange={handleChange}
                      InputLabelProps={{ shrink: !!values.passwordConfirm }}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() =>
                              setShowPassword((state) => ({
                                ...state,
                                passwordConfirm: !state.passwordConfirm,
                              }))
                            }
                            onMouseDown={(e) => e.preventDefault()}
                            edge="end"
                            size="small"
                          >
                            {showPassword.passwordConfirm ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    ></OutlinedInput>
                  </FormControl>
                  <Typography
                    id="error-groupRequiredField"
                    variant="caption"
                    color={ColorPalette.danger}
                    pl={1}
                  >
                    {errors.passwordConfirm &&
                      !!touched.passwordConfirm &&
                      errors.passwordConfirm}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <ButtonComponent
                    title={submitting ? "Loading..." : "Change Password"}
                    type={"submit"}
                    contained={true}
                    fullwidth
                    disabled={submitting}
                  />
                </Grid>
                <Grid container justifyContent="center" spacing={0}>
                  <Grid item className="login-link">
                    <Link
                      id="linkk-backToLoin"
                      onClick={() => navigate("../login")}
                      underline="none"
                    >
                      <h5
                        className="custom"
                        style={{ color: ColorPalette.primary }}
                      >
                        Back to Login
                      </h5>
                    </Link>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
}

import { TabContext, TabPanel } from "@mui/lab";
import React, { useState } from "react";
import ProfitAndLossTab from "./ProfitAndLossTab/ProfitandLossTab";
import CashFlowTab from "./CashFlowTab/CashFlowTab";
import CustomReportTab from "./CustomReportTab/CustomReportTab";
import { TabContainer } from "../../../components/TabContainer/TabContainer";
import { useLocation } from "react-router-dom";

const ProfitandLoss = () => {
  const { state } = useLocation();
  const [value, setValue] = useState(state?.tabValue || "1");
  const tabValues = [
    {
      label: "Profit & Loss",
      value: "1",
    },
    { label: "Cash Flow", value: "2" },
    { label: "Custom Reports", value: "3" },
  ];
  const handleChange = (newValue) => {
    setValue(newValue);
  };

  return (
    <div className="p-10">
      <TabContext value={value}>
        <TabContainer
          tabs={tabValues}
          value={value}
          onChange={(event, newValue) => handleChange(newValue)}
        />
      </TabContext>
      <TabContext value={value}>
        <TabPanel value="1" sx={{ padding: 0, margin: 0, my: 2 }}>
          <ProfitAndLossTab />
        </TabPanel>
        <TabPanel value="2" sx={{ padding: 0, margin: 0 }}>
          <CashFlowTab />
        </TabPanel>
        <TabPanel value="3" sx={{ padding: 0, margin: 0, my: 1 }}>
          <CustomReportTab />
        </TabPanel>
      </TabContext>
    </div>
  );
};

export default ProfitandLoss;

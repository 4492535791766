import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Box,
  Button,
  Chip,
  Divider,
  FormControl,
  Grid,
  Stack,
  Tab,
  TextField,
  Typography,
  IconButton,
  CircularProgress,
} from "@mui/material";
import React, {
  forwardRef,
  Fragment,
  useCallback,
  useEffect,
  useState,
} from "react";
import {
  Image,
  GridOnRounded,
  Close,
  PictureAsPdfOutlined,
} from "@mui/icons-material";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import CustomModal from "../../../../components/CustomModal/CustomModal";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  downloadExcelFile,
  resetDownloadSuccess,
  sendFileInEmail,
} from "../../../../redux/ProfitandLoss/ProfitandLossSlice";

const SelectionContainer = ({ type, downloadType, handleFormatSelection }) => {
  const getSelectionColor = (value, type) => {
    return type === value
      ? {
          backgroundColor: "#F2F2F2",
          border: "0.5px solid #03A561",
        }
      : {
          backgroundColor: "#FFFFFF",
        };
  };
  return (
    <Grid item xs={12}>
      <Typography variant={"inherit"}>{type} Format </Typography>
      <Grid container display={"flex"} alignItems={"center"} className="mt-20">
        <div
          style={{
            ...getSelectionColor("image", downloadType),
          }}
          className="selection-tile"
          onClick={() => {
            handleFormatSelection("image");
          }}
        >
          <Image color="disabled" sx={{ fontSize: 45 }} className="mt-5" />
          <p className="m-5"> Image </p>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            gap: "2px",
          }}
        >
          {!(
            downloadType === "pdf" ||
            downloadType === "landscape" ||
            downloadType === "portrait"
          ) ? (
            <div
              variant="contained"
              className="ml-20 selection-tile"
              style={getSelectionColor("pdf", downloadType)}
              onClick={() => {
                handleFormatSelection("pdf");
              }}
            >
              <PictureAsPdfOutlined
                color="disabled"
                sx={{ fontSize: 45 }}
                className="mt-5"
              />
              <p className="m-5"> Pdf </p>
            </div>
          ) : (
            <div className="ml-20 selection-tile">
              <Grid container display={"flex"} gap={2}>
                <Button
                  variant="outlined"
                  onClick={() => {
                    handleFormatSelection("landscape");
                  }}
                  sx={getSelectionColor("landscape", downloadType)}
                >
                  Landscape
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => handleFormatSelection("portrait")}
                  sx={getSelectionColor("portrait", downloadType)}
                >
                  Potrait
                </Button>
              </Grid>
            </div>
          )}
        </div>
        <div
          variant="contained"
          className="ml-20 selection-tile"
          style={getSelectionColor("excel", downloadType)}
          onClick={() => {
            handleFormatSelection("excel");
          }}
        >
          <GridOnRounded
            color="disabled"
            sx={{ fontSize: 45 }}
            className="mt-5"
          />
          <p className="m-5"> Spreadsheet </p>
        </div>
      </Grid>
    </Grid>
  );
};

const DownloadModal = forwardRef(
  (
    {
      modalOpen,
      setModalOpen,
      spreadsheetList,
      selectedSpreadSheet,
      reportingMonth,
      plData,
    },
    ref
  ) => {
    const dispatch = useDispatch();
    const { accountingFirmId, entityId } = useParams();
    const {
      isDownloadFetching: isFetching,
      isDownloadSuccess,
      excelFile,
    } = useSelector((state) => state.profitAndLoss);
    const [value, setValue] = useState("1");
    const [sendEmailField, setSendEmailField] = useState("");
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [isDownloading, setIsDownloading] = useState(false);
    const [isDownloadType, setIsDownloadType] = useState({
      image: false,
      pdf: {
        type: false,
        landscape: false,
        portrait: false,
      },
      excel: false,
    });

    //current date
    const now = new Date();
    const day = now.getDate();
    const month = now.getMonth() + 1;
    const year = now.getFullYear();

    const currentDateTime = `${day}/${month}/${year}`;

    const initialState = {
      isEmailEmpty: false,
      email: "",
      downloadType: "",
    };
    const [errors, setErrors] = useState(initialState);

    useEffect(() => {
      dispatch(resetDownloadSuccess());
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDownloadSuccess, isFetching]);

    useEffect(() => {
      if (isDownloadSuccess) {
        setModalOpen(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDownloadSuccess]);

    const spreadSheetName = spreadsheetList.find(
      (row) => row._id === selectedSpreadSheet
    );

    const handleTabChange = (event, newValue) => {
      setValue(newValue);
    };

    const generateFullPageImage = async () => {
      if (!ref.current) return null;

      const element = ref.current;
      const margin = 40;
      const headerHeight = 60;

      // Create canvas from element
      const elementCanvas = await html2canvas(element, {
        scrollX: 0,
        scrollY: 0,
        width: element.scrollWidth,
        height: element.scrollHeight,
        useCORS: true,
        allowTaint: true,
        scale: 2,
      });

      // Create final canvas with margins and header
      const finalCanvas = document.createElement("canvas");
      const ctx = finalCanvas.getContext("2d");

      finalCanvas.width = elementCanvas.width + margin * 2;
      finalCanvas.height = elementCanvas.height + margin * 2 + headerHeight;

      // Fill white background
      ctx.fillStyle = "#ffffff";
      ctx.fillRect(0, 0, finalCanvas.width, finalCanvas.height);

      // Add header text
      ctx.fillStyle = "#000000";
      ctx.font = "bold 48px Arial";
      ctx.textAlign = "center";
      ctx.fillText(
        `Profit And Loss Report (${reportingMonth})`,
        finalCanvas.width / 2,
        headerHeight
      );

      // Draw element canvas with margins
      ctx.drawImage(elementCanvas, margin, margin + headerHeight);

      return finalCanvas.toDataURL("image/png");
    };

    const generateFullPagePdf = async () => {
      if (ref.current === null) return null;
      const element = ref.current;

      const canvas = await html2canvas(element, {
        scrollX: 0,
        scrollY: 0,
        width: element.scrollWidth,
        height: element.scrollHeight,
        useCORS: true,
        allowTaint: true,
        scale: 2,
      });

      const imgData = canvas.toDataURL("image/png");

      const pdf = new jsPDF({
        orientation:
          (isDownloadType.pdf.landscape && "l") ||
          (isDownloadType.pdf.portrait && "p"),
        unit: "mm",
        format: "a4",
        compress: true,
      });

      const margin = 10;
      const pageWidth = pdf.internal.pageSize.getWidth();
      const contentWidth = pageWidth - 2 * margin;

      // Calculate dimensions to fit content within margins
      const imgWidth = contentWidth;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      let position = margin + 10; // Add some space below the heading

      pdf.addImage(imgData, "JPG", margin, position, contentWidth, imgHeight);

      pdf.text(70, 10, `Profit and Loss Report (${reportingMonth})`);

      return pdf.output("datauristring");
    };

    const onImageDownload = useCallback(async () => {
      if (ref.current === null) return;

      try {
        setIsDownloading(true);
        const dataUrl = await generateFullPageImage();
        const link = document.createElement("a");
        link.download = `profit&Loss_report_of_${spreadSheetName.name}_${currentDateTime}.png`;
        link.href = dataUrl;
        link.click();
      } catch (err) {
        console.log("Error downloading image:", err);
      } finally {
        setIsDownloading(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref]);

    const onPdfDownload = async () => {
      if (ref.current === null) return;

      try {
        setIsDownloading(true);
        const pdfDataUrl = await generateFullPagePdf();
        const link = document.createElement("a");
        link.href = pdfDataUrl;
        link.download = `profit&Loss_report_of_${spreadSheetName.name}_${currentDateTime}.pdf`;
        link.click();
      } catch (err) {
        console.log("Error downloading PDF:", err);
      } finally {
        setIsDownloading(false);
      }
    };

    const onExcelDownload = () => {
      dispatch(
        downloadExcelFile({
          accountingFirmId,
          entityId,
          selectedSpreadSheet,
          plData,
        })
      );
      const uint8Array = new Uint8Array(excelFile);
      const blob = new Blob([uint8Array]);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `profit&Loss_report_of_${spreadSheetName.name}_${currentDateTime}.xlsx`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    };

    const handleFormatSelection = (format) => {
      switch (format) {
        case "image":
          setIsDownloadType({
            image: true,
            pdf: {
              type: false,
              landscape: false,
              portrait: false,
            },
            excel: false,
          });
          break;
        case "pdf":
          setIsDownloadType({
            image: false,
            pdf: {
              type: true,
              landscape: false,
              portrait: false,
            },
            excel: false,
          });
          break;
        case "landscape":
          setIsDownloadType({
            image: false,
            pdf: {
              type: false,
              landscape: true,
              portrait: false,
            },
            excel: false,
          });
          break;
        case "portrait":
          setIsDownloadType({
            image: false,
            pdf: {
              type: false,
              landscape: false,
              portrait: true,
            },
            excel: false,
          });
          break;
        case "excel":
          setIsDownloadType({
            image: false,
            pdf: {
              type: false,
              landscape: false,
              portrait: false,
            },
            excel: true,
          });
          break;
        default:
          setIsDownloadType({
            image: false,
            pdf: {
              type: false,
              landscape: false,
              portrait: false,
            },
            excel: false,
          });
      }

      setErrors((prevErrors) => ({
        ...prevErrors,
        downloadType: "",
      }));
    };

    const cancelInviteMembers = () => {
      setSelectedUsers([]);
      setSendEmailField("");
      setErrors(initialState);
    };

    const handleSendEmailChange = (event) => {
      setSendEmailField(event.target.value);
      if (event.target.value?.includes(",")) {
        setSelectedUsers(selectedUsers?.concat(sendEmailField.split(",")));
        setSendEmailField("");
      }
    };

    const handleChipDelete = (data) => {
      setSelectedUsers(selectedUsers?.filter((item) => item !== data));
    };

    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        if (!sendEmailField) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            isEmailEmpty: true,
            email: "Required Field",
          }));
        } else if (!emailRegex.test(sendEmailField)) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            email: "Invalid email address",
          }));
        } else if (selectedUsers?.includes(sendEmailField)) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            email: "Can't enter same email",
          }));
        } else {
          setErrors(initialState);
          setSelectedUsers(selectedUsers?.concat(sendEmailField));
          setSendEmailField("");
        }
      }
    };

    //handle documents to send in email
    const hanldeEmailSend = async () => {
      if (
        !(
          Object.values(isDownloadType.pdf).some((value) => value === true) ||
          isDownloadType.image ||
          isDownloadType.excel
        )
      ) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          downloadType:
            "Please select a format (image,pdf, excel) for document to download",
        }));
        return;
      }

      if (ref.current === null) return;
      let base64Data;
      if (isDownloadType.image) {
        //generate the image as base64
        const dataUrl = await generateFullPageImage();
        base64Data = dataUrl.split(",")[1];
      } else if (isDownloadType.pdf.landscape || isDownloadType.pdf.portrait) {
        //generate pdf data as base64
        const pdfDataUrl = await generateFullPagePdf();
        base64Data = pdfDataUrl.split(",")[1];
      }
      const payload = {
        userEmail: selectedUsers?.map((item) => item),
        imageData: isDownloadType.image ? base64Data : null,
        pdfData: isDownloadType.pdf.type ? base64Data : null,
        excelData: isDownloadType.excel ? plData : null,
        downloadType:
          (isDownloadType.image && "image") ||
          (isDownloadType.pdf.type && "pdf") ||
          (isDownloadType.excel && "excel"),
      };
      dispatch(
        sendFileInEmail({
          accountingFirmId,
          entityId,
          selectedSpreadSheet,
          payload,
        })
      );
      setSelectedUsers([]);
    };

    //handle download document
    const handleDownloadSubmit = async () => {
      if (isDownloadType.image) {
        await onImageDownload();
        setModalOpen(false);
      } else if (isDownloadType.pdf.landscape || isDownloadType.pdf.portrait) {
        await onPdfDownload();
        setModalOpen(false);
      } else if (isDownloadType.excel) {
        onExcelDownload();
        setModalOpen(false);
      }
      setIsDownloading(false);
    };

    return (
      <Fragment>
        <CustomModal
          modalOpen={modalOpen}
          maxWidth="md"
          setModalOpen={setModalOpen}
          onClose={setModalOpen}
          overflow="hidden"
        >
          <Box sx={{ width: "100%", marginTop: "23px" }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 0, borderColor: "#0000001F" }}>
                <Grid
                  container
                  sx={{
                    dipslay: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    paddingRight: "10px",
                  }}
                >
                  <TabList onChange={handleTabChange}>
                    <Tab
                      label="Download"
                      value="1"
                      style={{
                        fontWeight: value === "1" ? "bold" : "",
                        color: "black",
                        textTransform: "none",
                        fontSize: "17px",
                      }}
                    />
                    <Tab
                      label="Email"
                      value="2"
                      style={{
                        fontWeight: value === "2" ? "bold" : "",
                        color: "black",
                        textTransform: "none",
                        fontSize: "17px",
                      }}
                    />
                  </TabList>
                  <IconButton onClick={() => setModalOpen(false)}>
                    <Close />
                  </IconButton>
                </Grid>
                <Divider />
              </Box>
              <TabPanel value="1">
                <Grid container>
                  <Grid className="mt-30">
                    <Grid item>
                      <SelectionContainer
                        type="Download"
                        downloadType={
                          (isDownloadType.image && "image") ||
                          (isDownloadType.pdf.type && "pdf") ||
                          (isDownloadType.pdf.landscape && "landscape") ||
                          (isDownloadType.pdf.portrait && "portrait") ||
                          (isDownloadType.excel && "excel")
                        }
                        handleFormatSelection={handleFormatSelection}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"right"}
                    gap={2}
                    marginTop={4}
                  >
                    <Button
                      variant="outlined"
                      onClick={() =>
                        setIsDownloadType({
                          image: false,
                          pdf: {
                            type: false,
                            landscape: false,
                            portrait: false,
                          },
                        })
                      }
                      disabled={
                        !(
                          isDownloadType.image ||
                          isDownloadType.pdf.landscape ||
                          isDownloadType.pdf.portrait ||
                          isDownloadType.excel
                        )
                      }
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      onClick={handleDownloadSubmit}
                      disabled={
                        !(
                          isDownloadType.image ||
                          isDownloadType.pdf.landscape ||
                          isDownloadType.pdf.portrait ||
                          isDownloadType.excel
                        )
                      }
                    >
                      {isDownloading ? "Downloading..." : "Download"}
                    </Button>
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value="2">
                {isFetching ? (
                  <Grid
                    container
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                      gap: 3,
                      height: "20vh",
                    }}
                  >
                    <Typography variant="h6">
                      {`Please wait a moment. The ${
                        (isDownloadType.image && "image") ||
                        (isDownloadType.pdf.type && "pdf") ||
                        (isDownloadType.excel && "excel")
                      } is being sent to email ...`}
                    </Typography>
                    <CircularProgress />
                  </Grid>
                ) : (
                  <div>
                    <Grid container padding={2}>
                      <Grid item xs={12}>
                        <Grid container mb={2}>
                          <Grid>
                            <SelectionContainer
                              type="Email"
                              downloadType={
                                (isDownloadType.image && "image") ||
                                (isDownloadType.pdf.type && "pdf") ||
                                (isDownloadType.pdf.landscape && "landscape") ||
                                (isDownloadType.pdf.portrait && "portrait") ||
                                (isDownloadType.excel && "excel")
                              }
                              handleFormatSelection={handleFormatSelection}
                            />
                          </Grid>
                          <Grid>
                            <Typography color="error" mt={2}>
                              {errors.downloadType ? errors.downloadType : ""}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} marginTop={2} pt={2}>
                          <Grid container>
                            <Grid item xs={12}>
                              <Stack direction="row">
                                <div>
                                  {selectedUsers?.map((data, key) => {
                                    return (
                                      <Chip
                                        key={key}
                                        label={data}
                                        variant="outlined"
                                        onDelete={() => {
                                          handleChipDelete(data);
                                        }}
                                        style={{ margin: 2 }}
                                      />
                                    );
                                  })}
                                </div>
                              </Stack>
                            </Grid>
                            <Grid item xs={12} marginTop={2}>
                              <FormControl fullWidth>
                                <TextField
                                  id="input-sendEmail"
                                  variant="outlined"
                                  fullWidth
                                  label="Enter an email and press enter to add more"
                                  value={sendEmailField}
                                  onChange={handleSendEmailChange}
                                  onKeyDown={handleKeyDown}
                                  disabled={isFetching}
                                  error={
                                    errors.isEmailEmpty || errors.email !== ""
                                  }
                                  helperText={
                                    errors.isEmailEmpty
                                      ? "Required Field"
                                      : errors.email || ""
                                  }
                                />
                              </FormControl>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sx={{
                                display: "flex",
                                justifyContent: "right",
                                gap: "10px",
                                marginTop: "30px",
                              }}
                            >
                              <Button
                                variant="outlined"
                                fullwidth
                                id="button-inviteMembers"
                                onClick={cancelInviteMembers}
                              >
                                <Typography textTransform={"none"}>
                                  Cancel
                                </Typography>
                              </Button>
                              <Button
                                id="button-invite"
                                variant="contained"
                                onClick={hanldeEmailSend}
                                disabled={
                                  !selectedUsers?.length > 0 || isFetching
                                }
                              >
                                <Typography textTransform={"none"}>
                                  Send Email
                                </Typography>
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>
                )}
              </TabPanel>
            </TabContext>
          </Box>
        </CustomModal>
      </Fragment>
    );
  }
);

export default DownloadModal;

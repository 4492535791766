import React, { useEffect, useState } from "react";
import CustomModal from "../../../../components/CustomModal/CustomModal";
import {
  Box,
  Chip,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  getUsersJoinedByInvitation,
  updateClientSpace,
} from "../../../../redux/ClientSpace/ClientSpaceSlice";
import { useParams } from "react-router-dom";
import { ColorPalette } from "../../../../constants/colorPalette";
import { toast } from "react-toastify";
import { ButtonComponent } from "../../../../components/Button/Button";

const EditClientSpaceModal = ({ modalOpen, setModalOpen, editData, rows }) => {
  const dispatch = useDispatch();
  const { accountingFirmId } = useParams();
  const users = useSelector(
    (state) => state.clientSpaces.membersJoinedByInvite
  );

  const [accountants, setAccountants] = useState(
    editData.users
      .filter((row) => row.role === "ACCOUNTANT")
      .map((row) => row.user)
  );

  const managerId = editData.users
    .filter((row) => row.role === "MANAGER")
    .map((row) => row.user)
    .map((row) => row._id);

  useEffect(() => {
    dispatch(getUsersJoinedByInvitation({ accountingFirmId }));
  }, [accountingFirmId, dispatch]);

  const handleAccountChange = (e) => {
    const selectedIds = e.target.value;
    const selectedAccounts = users.filter((user) =>
      selectedIds.includes(user._id)
    );
    setAccountants(selectedAccounts);
  };

  const handleChipDelete = (id) => {
    const updatedAccountants = accountants.filter((acc) => acc._id !== id);
    setAccountants(updatedAccountants);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleSubmit = (values, { setSubmitting }) => {
    const isNameChanged =
      editData.name.trim().toLowerCase() !==
      values.spaceName.trim().toLowerCase();

    const areAccountantsChanged =
      editData.accountant.length !== accountants.length ||
      !editData.accountant.every((acc) =>
        accountants.some(
          (user) => user.first_name + " " + user.last_name === acc
        )
      );

    if (!isNameChanged && !areAccountantsChanged) {
      toast.error("No changes detected in client space update modal.");
      setSubmitting(false);
      return;
    }
    const clientSpaceMembers = [
      {
        user: managerId[0],
        role: "MANAGER",
      },
      ...accountants.map((acc) => ({
        user: acc._id,
        role: "ACCOUNTANT",
      })),
    ];
    dispatch(
      updateClientSpace({
        accountingFirmId,
        id: editData._id,
        data: { clientSpaceName: values.spaceName, clientSpaceMembers },
      })
    );
    setSubmitting(false);
    handleModalClose();
  };
  return (
    <div>
      <CustomModal
        onClose={handleModalClose}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        overflow="hidden"
        maxWidth="md"
      >
        <Grid container flexDirection={"column"}>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: "15px",
            }}
          >
            <Typography fontSize={20} fontWeight="bold">
              Edit Clientspace Details
            </Typography>
            <IconButton
              id="button-addGroupCloseIcon"
              size="small"
              onClick={() => {
                handleModalClose();
              }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>

          <Divider sx={{ width: "100%", color: ColorPalette.danger }} />
        </Grid>
        <Formik
          initialValues={{
            spaceName: editData?.name || "",
          }}
          validate={(values) => {
            const errors = {};
            if (!values.spaceName) {
              errors.isSpaceNameEmpty = true;
              errors.spaceName = "Please enter clientspace name";
            } else if (
              rows
                .filter((row) => row.clientSpaceName !== editData.name)
                .some(
                  (row) =>
                    row.clientSpaceName.toLocaleLowerCase() ===
                    values.spaceName.toLocaleLowerCase().trim()
                )
            ) {
              errors.spaceName =
                "This clientspace name has already been used. Please use different name.";
            } else if (/[!@#$%^&*]/g.test(values.spaceName)) {
              errors.spaceName = "Special characters not allowed";
            }
            if (accountants.length === 0) {
              errors.accountant = "Please select at least one accountant.";
            }
            return errors;
          }}
          onSubmit={handleSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <Grid container p={2}>
                <Grid item xs={6} marginTop={2} marginBottom={2}>
                  <Typography
                    id="text-addGroupName"
                    fontSize={16}
                    color={ColorPalette.black}
                    className="mb-10"
                  >
                    Client Space Name
                  </Typography>
                  <TextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    name="spaceName"
                    value={values.spaceName}
                    error={!!errors.spaceName && !!touched.spaceName}
                    helperText={touched.spaceName && errors.spaceName}
                    defaultValue={editData?.name}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    id="text-addGroupManager"
                    fontSize={16}
                    color={ColorPalette.black}
                    className="mb-10"
                  >
                    Accountants
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Select
                        multiple
                        fullWidth
                        size="small"
                        labelId="accountant-user"
                        id="input-spaceAccountant"
                        name="accountant"
                        value={accountants.map((acc) => acc._id)}
                        onChange={handleAccountChange}
                        error={!!errors.accountant && accountants.length === 0}
                        renderValue={(selected) => (
                          <Box
                            sx={{
                              display: "flex",
                              flexWrap: "wrap",
                              gap: 0.5,
                            }}
                          >
                            {selected.map((value) => {
                              const selectedName = users.find(
                                (user) => value === user._id
                              );
                              return (
                                <Chip
                                  key={value}
                                  label={`${selectedName?.first_name} ${selectedName?.last_name}`}
                                  sx={{
                                    backgroundColor: "#e0e0e0",
                                  }}
                                  onMouseDown={(event) => {
                                    event.stopPropagation();
                                  }}
                                  onDelete={() => handleChipDelete(value)}
                                />
                              );
                            })}
                          </Box>
                        )}
                      >
                        {users
                          ?.filter(
                            (user) =>
                              !accountants.some(
                                (acc) => acc._id === user._id
                              ) && !managerId.includes(user._id)
                          )
                          ?.map((user, index) => {
                            return (
                              <MenuItem key={index} value={user._id}>
                                {user.first_name + " " + user.last_name}
                                <span
                                  style={{
                                    marginLeft: "5px",
                                    fontSize: "15px",
                                    color: ColorPalette.gray,
                                  }}
                                >
                                  {user.email}
                                </span>
                              </MenuItem>
                            );
                          })}
                      </Select>
                      {!!errors.accountant && accountants.length === 0 && (
                        <div
                          id="error-spaceRequiredField"
                          style={{
                            fontSize: 12,
                            color: ColorPalette.danger,
                            marginLeft: "15px",
                            paddingTop: "3px",
                          }}
                        >
                          {errors.accountant}
                        </div>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "right",
                    marginTop: "35px",
                    gap: "10px",
                  }}
                >
                  <ButtonComponent
                    onClick={() => setModalOpen(false)}
                    title={"Cancel"}
                  />
                  <ButtonComponent
                    type={"submit"}
                    title={"Save"}
                    contained={true}
                    disabled={
                      accountants.every((val) =>
                        editData.accountant.includes(
                          `${val.first_name} ${val.last_name}`
                        )
                      ) &&
                      editData.name.trim().toLowerCase() ===
                        values.spaceName.trim().toLowerCase()
                    }
                  />
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </CustomModal>
    </div>
  );
};

export default EditClientSpaceModal;

import React from "react";
import CustomModal from "../../../../../components/CustomModal/CustomModal";
import {
  Button,
  Grid,
  IconButton,
  Table,
  TableBody,
  Tooltip,
  Typography,
} from "@mui/material";
import { CloseOutlined, Info } from "@mui/icons-material";
import {
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { errorDescriptionTable } from "../../../../../helper/utils";
import { makeStyles } from "@mui/styles";
const useStyles = makeStyles({
  t: {
    background: "white",
    borderRadius: "10px",
  },
});
const BulkUploadErrorModal = ({ bulkUploadError, setBulkUploadError }) => {
  const modalOpen = () => {
    setBulkUploadError((prevState) => ({
      ...prevState,
      modalOpen: !prevState.modalOpen,
    }));
  };
  const classes = useStyles();
  return (
    <div>
      <CustomModal
        modalOpen={bulkUploadError.modalOpen}
        setModalOpen={modalOpen}
        onClose={modalOpen}
        maxWidth="md"
        overflow="auto"
      >
        <Grid container px={3} py={1}>
          <Grid
            container
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Grid item display={"flex"} alignItems={"center"}>
              <Typography variant="h5">Errors Table</Typography>
              <Tooltip
                classes={{ tooltip: classes.t }}
                className="ml-10"
                placement="right"
                title={
                  <p style={{ fontSize: "15px" }}>{errorDescriptionTable()}</p>
                }
              >
                <Info />
              </Tooltip>
            </Grid>
            <Grid item>
              <IconButton onClick={modalOpen}>
                <CloseOutlined />
              </IconButton>
            </Grid>
          </Grid>
          <TableContainer sx={{ height: "400px" }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ backgroundColor: "#6b6d70" }}>
                    Row No.
                  </TableCell>
                  <TableCell sx={{ backgroundColor: "#6b6d70" }}>
                    Column
                  </TableCell>
                  <TableCell sx={{ backgroundColor: "#6b6d70" }}>
                    Error Details
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {bulkUploadError.error?.map((row) => (
                  <TableRow>
                    <TableCell>{row.rowIndex + 2}</TableCell>
                    <TableCell>{row.key}</TableCell>
                    <TableCell>{row.message}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Grid
            container
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            mt={3}
          >
            <Button
              variant="contained"
              onClick={() =>
                setBulkUploadError((prevState) => ({
                  ...prevState,
                  modalOpen: !modalOpen,
                }))
              }
            >
              Close
            </Button>
          </Grid>
        </Grid>
      </CustomModal>
    </div>
  );
};

export default BulkUploadErrorModal;

import "./App.css";

import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { config } from "../config/config";
import Dashboard from "../features/pages/Dashboard/Dashboard";
import Profitability from "../features/pages/Profitability/Profitability";
import Login from "../features/authentication/Login/Login";
// import Register from "../features/authentication/Register/Register";
import PrivateRoute from "./PrivateRoute";
import ForgotPassword from "../features/authentication/ForgotPassword/ForgotPassword";
import ResetPassword from "../features/authentication/ResetPassword/ResetPassword";
import CustomLayout from "./CustomLayout";
import InviteUser from "../features/authentication/InviteUser/InviteUser";
import XeroAuthentication from "../features/xero_auth/XeroAuthentication/XeroAuthentication";
import XeroRedirect from "../features/xero_auth/XeroRedirect/XeroRedirect";
import ClientSpaces from "../features/pages/ClientSpaces/ClientSpaces";
import Team from "../features/pages/Team/Team";
import BudgetComparision from "../features/pages/BudgetComparision/BudgetComparision";
import Settings from "../features/pages/Settings/Settings";
import CashPosition from "../features/pages/CashPosition/CashPosition";
// import ClientSpace from "../features/pages/ClientSpace/ClientEntities";
import withGlobalToast from "../components/GlobalToast/GlobalToast";
// import ClientMembers from "../features/pages/ClientSpace/ClientMembers";
import ClientEntities from "../features/pages/ClientSpace/Entities/ClientEntities";
import Onboarding from "../features/pages/Onboarding/Onboarding";
import "@silevis/reactgrid/styles.css";
import SpreadSheet from "../features/pages/SpreadSheet/SpreadSheet";
import SpreadsheetList from "../features/pages/SpreadSheet/SpreadsheetList";
import ProfitAndLoss from "../features/pages/ProfitandLoss/ProfitandLoss";
import UserProfile from "../features/pages/UserProfile/UserProfile";
import FirmProfile from "../features/pages/FirmProfile/FirmProfile";
import XeroRegister from "../features/XeroRegister/XeroRegister";
import CustomReportPlayGround from "../features/pages/ProfitandLoss/CustomReportPlayground/CustomReportPlayGround";
import EntityUserDetails from "../features/pages/ClientSpace/Entities/EntityUserDetails/EntityUserDetails";

function App() {
  config();
  const role = localStorage.getItem("role");
  return (
    <div className="wrapper">
      <Routes>
        <Route
          exact
          path="/login"
          element={
            <PrivateRoute name="login">
              <Login />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/forgot-password"
          element={
            <PrivateRoute name="forgot-password">
              <ForgotPassword />
            </PrivateRoute>
          }
        />

        <Route
          exact
          path="/xero-signup"
          element={
            <PrivateRoute name="xero-signup">
              <XeroRegister />
            </PrivateRoute>
          }
        />
        <Route exact path="/invite-user" element={<InviteUser />} />

        <Route
          exact
          path="/reset-password"
          element={
            <PrivateRoute name="reset-password">
              <ResetPassword />
            </PrivateRoute>
          }
        />
        <Route exact path="/" element={<Navigate to="/login" />} />
        <Route element={<CustomLayout />}>
          <Route
            exact
            path="accountingFirm/:accountingFirmId/clientSpaces"
            element={
              <PrivateRoute name="groups">
                <ClientSpaces />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="accountingFirm/:accountingFirmId/team"
            element={
              <PrivateRoute name="team">
                <Team />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path={`accountingFirm/:accountingFirmId/userProfile`}
            element={
              <PrivateRoute name="userProfile">
                <UserProfile />
              </PrivateRoute>
            }
          />
          {role === "ADMIN" && (
            <Route
              exact
              path={`accountingFirm/:accountingFirmId/firmProfile`}
              element={
                <PrivateRoute name="userProfile">
                  <FirmProfile />
                </PrivateRoute>
              }
            />
          )}
          <Route
            path="accountingFirm/:accountingFirmId/clientSpace/:clientSpaceId/entities"
            element={
              <PrivateRoute name="entities">
                <ClientEntities />
              </PrivateRoute>
            }
          />
          <Route
            path="accountingFirm/:accountingFirmId/clientSpace/:clientSpaceId/entityUserDetails"
            element={
              <PrivateRoute name="entityUserDetails">
                <EntityUserDetails />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/accountingFirm/:accountingFirmId/clientSpace/:clientSpaceId/entity/:entityId/dashboard"
            element={
              <PrivateRoute name="dashboard">
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/accountingFirm/:accountingFirmId/clientSpace/:clientSpaceId/entity/:entityId/profitability"
            element={
              <PrivateRoute name="profitability">
                <Profitability />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/accountingFirm/:accountingFirmId/clientSpace/:clientSpaceId/entity/:entityId/budgetComparision"
            element={
              <PrivateRoute name="budgetcomparision">
                <BudgetComparision />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/accountingFirm/:accountingFirmId/clientSpace/:clientSpaceId/entity/:entityId/profitAndLoss"
            element={
              <PrivateRoute name="profitAndLoss">
                <ProfitAndLoss />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/accountingFirm/:accountingFirmId/clientSpace/:clientSpaceId/entity/:entityId/profitAndLoss/custom"
            element={
              <PrivateRoute name="customReport">
                <CustomReportPlayGround />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/accountingFirm/:accountingFirmId/clientSpace/:clientSpaceId/entity/:entityId/profitAndLoss/custom/:customReportId"
            element={
              <PrivateRoute name="customReport">
                <CustomReportPlayGround />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/accountingFirm/:accountingFirmId/clientSpace/:clientSpaceId/entity/:entityId/cashPosition"
            element={
              <PrivateRoute name="cashposition">
                <CashPosition />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="accountingFirm/:accountingFirmId/clientSpace/:clientSpaceId/entity/:entityId/onboarding"
            element={
              <PrivateRoute name="onboarding">
                <Onboarding />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="accountingFirm/:accountingFirmId/clientSpace/:clientSpaceId/entity/:entityId/settings"
            element={
              <PrivateRoute name="settings">
                <Settings />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/xeroAuthentication"
            element={
              <PrivateRoute name="xeroAuthentication">
                <XeroAuthentication />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/callback"
            element={
              <PrivateRoute name="xeroCallback">
                <XeroRedirect />
              </PrivateRoute>
            }
          />

          {/* <Route
            exact
            path="/:id/client-members"
            element={
              <PrivateRoute name="client-members">
                <ClientMembers />
              </PrivateRoute>
            }
          /> */}
          <Route
            exact
            path="accountingFirm/:accountingFirmId/clientSpace/:clientSpaceId/entity/:entityId/spreadSheets"
            element={
              <PrivateRoute name="spreadsheets">
                <SpreadsheetList />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="accountingFirm/:accountingFirmId/clientSpace/:clientSpaceId/entity/:entityId/spreadSheet/:spreadSheetId"
            element={
              <PrivateRoute name="spreadsheets">
                <SpreadSheet />
              </PrivateRoute>
            }
          />
        </Route>
      </Routes>
    </div>
  );
}

export default withGlobalToast(App);

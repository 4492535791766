import React, { useEffect, useState, useCallback } from "react";
import {
  Box,
  Button,
  Drawer,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import { getCustomProfitLossData } from "../../../../redux/ProfitandLoss/ProfitandLossSlice";
import {
  // Cell,
  // ColumnDef,
  // Header,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getExpandedRowModel,
} from "@tanstack/react-table";
import "./CustomReportPlayground.css";
import {
  Add,
  Close,
  Delete,
  DragIndicator,
  Edit,
  Info,
  KeyboardArrowDown,
  KeyboardArrowRight,
  Save,
  SwapHoriz,
  SwapVert,
} from "@mui/icons-material";
import {
  DndContext,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
  closestCenter,
  KeyboardSensor,
} from "@dnd-kit/core";
import { useSortable } from "@dnd-kit/sortable";
import {
  SortableContext,
  rectSortingStrategy,
  horizontalListSortingStrategy,
  arrayMove,
} from "@dnd-kit/sortable";
import SortableGrid from "../../SpreadSheet/components/SortableContainer/SortableGrid/SortableGrid";
import SortableItem from "../../SpreadSheet/components/SortableContainer/SortableItem/SortableItem";
import { restrictToHorizontalAxis } from "@dnd-kit/modifiers";
import { CSS } from "@dnd-kit/utilities";
import _, { uniqueId } from "lodash";
import { getFormulaSymbol } from "../../SpreadSheet/helper";
import { AntSwitch } from "../../SpreadSheet/Expense/Expense";
import Loading from "../../../../components/LoadingIndicator/Loading";
import { DEFAULT_KEYS, ROW_TYPE } from "../../SpreadSheet/defaultValues";
import { getSpreadSheets } from "../../../../redux/SpreadSheet/SpreadSheetSlice";
import { parse } from "mathjs";
import ObjectID from "bson-objectid";
import { ButtonComponent } from "../../../../components/Button/Button";
import CustomModal from "../../../../components/CustomModal/CustomModal";
import {
  addReport,
  getReport,
  updateReport,
} from "../../../../redux/CustomReport/CustomReportSlice";
import { Tooltip } from "@material-ui/core";
import ConfirmModal from "../../../../components/CustomModal/ConfirmModal";

const { RangePicker } = DatePicker;
const COLUMN_TYPES = { CURRENCY: "Currency", PERCENTAGE: "Percentage" };
const COLUMN_DATA_TYPES = {
  XERO: "Xero",
  SPREADSHEET: "Spreadsheet",
  FORMULA: "Formula",
};
const DATE_RANGE_TYPES = {
  MONTH: "Month",
  CUSTOM: "Custom",
};

let profitRows = ["Gross Profit", "Operating Profit", "Net Profit"];

const CALCULATION_TYPES = ["Add", "Subtract", "Multiply", "Divide"];
const DraggableTableHeader = ({
  header,
  columns,
  className,
  allowColumnReorder,
  handleEditColumn,
}) => {
  const { attributes, isDragging, listeners, setNodeRef, transform } =
    useSortable({
      id: header.column.id,
    });
  const style = {
    opacity: isDragging ? 0.8 : 1,
    position: "relative",
    transform: CSS.Translate.toString(transform), // translate instead of transform to avoid squishing
    transition: "width transform 0.2s ease-in-out",
    whiteSpace: "nowrap",
    width: header.column.getSize(),
    zIndex: isDragging ? 1 : 0,
    backgroundColor: "#F1F5F9",
    height: "50px",
  };
  return (
    <th
      colSpan={header.colSpan}
      ref={setNodeRef}
      style={{ ...style, paddingLeft: "20px", fontWeight: "500" }}
      className={className}
    >
      {header.isPlaceholder
        ? null
        : flexRender(header.column.columnDef.header, header.getContext())}
      {header.id !== "name" && !allowColumnReorder && (
        <IconButton
          {...{
            onClick: () => handleEditColumn(header.column.id),
          }}
        >
          <Edit fontSize="small" className="mt-neg-5" />
        </IconButton>
      )}
      {header.id !== "name" && columns?.length > 2 && (
        <Button {...attributes} {...listeners}>
          {allowColumnReorder && <DragIndicator />}
        </Button>
      )}
    </th>
  );
};
const DragAlongCell = ({ cell, className }) => {
  const { isDragging, setNodeRef, transform } = useSortable({
    id: cell.column.id,
  });

  const style = {
    opacity: isDragging ? 0.8 : 1,
    position: "relative",
    transform: CSS.Translate.toString(transform), // translate instead of transform to avoid squishing
    transition: "width transform 0.2s ease-in-out",
    width: cell.column.getSize(),
    zIndex: isDragging ? 1 : 0,
    textAlign: "right",
    paddingRight: "30px",
    paddingLeft: "20px",
  };
  return (
    <td style={style} ref={setNodeRef} className={className}>
      {flexRender(cell.column.columnDef.cell, cell.getContext())}
    </td>
  );
};

const initialSideNavData = {
  name: "",
  type: COLUMN_TYPES.CURRENCY,
  dataSource: COLUMN_DATA_TYPES.XERO,
  dateType: DATE_RANGE_TYPES.MONTH,
  date: { start: moment(), endDate: null },
  spreadsheetId: null,
  formulaList: null,
};

const CustomReportPlayGround = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { customReportId } = useParams();
  const { entityId, accountingFirmId, clientSpaceId } = useParams();
  const [reportData, setReportData] = useState({});
  const date = moment().subtract(1, "month");
  const [actionType, setActionType] = useState("add");
  const [addType, setAddType] = useState("row");
  const [sideNavOpen, setSideNavOpen] = useState(false);
  const [expanded, setExpanded] = React.useState({});
  const [columnOrder, setColumnOrder] = React.useState([]);
  const [hasError, setHasError] = useState(false);
  const [allowColumnReorder, setAllowColumnReorder] = useState(false);
  const [sideNavData, setSideNavData] = useState(
    _.cloneDeep(initialSideNavData)
  );
  const [collapsedMap, setCollapsedMap] = useState(null);
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));
  const sensors1 = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {})
  );
  const [allowSortOrderFormula, setAllowSortOrderFormula] = useState(false);
  const [formulaList, setFormulaList] = useState([]);
  const [formulaError, setFormulaError] = useState(false);
  const [saveReportModalOpen, setSaveReportModalOpen] = useState(false);
  const [newReportSaveData, setNewReportSaveData] = useState({
    name: "",
    description: "",
  });
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  let customReportData = useSelector((state) => state.report.report);
  useEffect(() => {
    if (customReportData && customReportId === undefined) {
      navigate(
        `/accountingFirm/${accountingFirmId}/clientSpace/${clientSpaceId}/entity/${entityId}/profitAndLoss`,
        { state: { tabValue: "3" } }
      );
    }
    // eslint-disable-next-line
  }, [customReportData]);
  useEffect(() => {
    if (customReportId === undefined) {
      dispatch(
        getCustomProfitLossData({
          entityId,
          accountingFirmId,
          start: date,
          end: date,
          loadType: "initial",
          source: COLUMN_DATA_TYPES.XERO,
          dateType: DATE_RANGE_TYPES.MONTH,
        })
      );
    } else {
      dispatch(
        getReport({ reportId: customReportId, entityId, accountingFirmId })
      );
    }

    dispatch(getSpreadSheets({ accountingFirmId, entityId, search: "" }));
    // eslint-disable-next-line
  }, []);

  const { customPlData: customData, isFetching: isFetchingCustom } =
    useSelector((state) => state.profitAndLoss);

  const { report, isFetching: isFetchingReport } = useSelector(
    (state) => state.report
  );

  let customPlData =
    customReportId !== undefined &&
    !["add", "initial"]?.includes(customData?.loadType)
      ? report
      : customData;
  let isFetching = isFetchingReport || isFetchingCustom;

  const { spreadsheets } = useSelector((state) => state.spreadsheet);
  const rowData = (rows) => {
    let arrangedData = [];

    rows?.forEach((row) => {
      if (
        row.rowType === "INITIAL" ||
        row.rowType === "FORMULA" ||
        row.rowType === "METRIC" ||
        row.rowType === "EBITDA_HEADER"
      ) {
        let subRows = [];
        if (row.rowType === "METRIC" || row.rowType === "EBITDA_HEADER") {
          let childrenRows = rows.filter((val) => val.parentId === row.id);
          childrenRows.forEach((child) => {
            if (
              (child.rowType === "ACCOUNT" ||
                child.rowType === "TOTAL" ||
                child.rowType === "EBITDA_ROW") &&
              !child.subParentId
            ) {
              subRows.push(child);
            }
            if (child.rowType === "GROUP") {
              let subChildrenRows = rows.filter(
                (val) => val.subParentId === child.id
              );
              subRows.push({ ...child, subRows: subChildrenRows });
            }
          });
        }
        arrangedData.push({ ...row, subRows });
      }
    });
    return arrangedData;
  };

  const handleEditColumn = (val) => {
    let currentColumnDetails = reportData.columnDetails.find(
      (col) => col.key === val
    );
    setAddType("column");
    setActionType("edit");
    setSideNavData(_.cloneDeep(currentColumnDetails));
    if (currentColumnDetails.dataSource === COLUMN_DATA_TYPES.FORMULA) {
      setFormulaList(_.cloneDeep(currentColumnDetails)?.formulaList);
    }
    setSideNavOpen(true);
  };

  // const handleAddColumns = () => {};

  const getColumnDetail = (columns, key) => {
    return key !== "name"
      ? {
          accessorKey: key,
          id: key,
          size: 150,
          value: columns?.[key],
          header: () => <span>{columns?.[key]}</span>,
        }
      : {
          size: 350,
          accessorKey: "name",
          header: ({ table }) => (
            <div style={{ display: "table", paddingTop: "5px" }}>
              Name
              <IconButton
                size="small"
                {...{
                  onClick: table?.getToggleAllRowsExpandedHandler(),
                }}
              >
                {table?.getIsAllRowsExpanded() ? (
                  <KeyboardArrowDown />
                ) : (
                  <KeyboardArrowRight />
                )}
              </IconButton>
            </div>
          ),
          cell: ({ row, getValue }) => (
            <div
              style={{
                paddingLeft:
                  row.original.rowType === "TOTAL"
                    ? "0rem"
                    : row.original.rowType === "GROUP_TOTAL"
                    ? `2rem`
                    : `${row.depth * 2}rem`,
                paddingTop: "3px",
              }}
            >
              <div style={{ display: "flex" }}>
                <div>{getValue()}</div>
                {row.getCanExpand() && (
                  <IconButton
                    className="mt-neg-7-5"
                    size="small"
                    {...{
                      onClick: row.getToggleExpandedHandler(),
                      style: { cursor: "pointer" },
                    }}
                  >
                    {row.getIsExpanded() ? (
                      <KeyboardArrowDown />
                    ) : (
                      <KeyboardArrowRight />
                    )}
                  </IconButton>
                )}
              </div>
            </div>
          ),
          footer: (props) => props.column.id,
        };
  };
  console.log(reportData);
  const getFilteredValues = (value) => {
    return value.filter((row) => {
      let allZeroValues = [];
      Object.keys(row).forEach((key) => {
        if (!DEFAULT_KEYS.includes(key)) {
          let val = parseFloat(row[key].replace(/[(),]/g, "") || "0") || 0;
          allZeroValues.push(val === 0);
        }
      });

      return row.rowType === "INITIAL" ||
        row.rowType === "FORMULA" ||
        row.rowType === "METRIC" ||
        row.rowType === "EBITDA_HEADER"
        ? true
        : row.name !== " " && row.name !== "" && allZeroValues.includes(false);
    });
  };
  useEffect(() => {
    if (customPlData) {
      if (
        (customPlData.loadType === "initial" || customReportId !== undefined) &&
        customPlData.loadType !== "add"
      ) {
        setReportData({
          columns: Object.keys(
            (customReportId !== undefined
              ? customPlData.columnValues
              : customPlData.columns) || {}
          ).map((key) => {
            return getColumnDetail(
              customReportId !== undefined
                ? customPlData.columnValues
                : customPlData.columns,
              key
            );
          }),
          rows:
            customReportId !== undefined
              ? customPlData.rows
              : rowData(getFilteredValues(customPlData.rows)),
          values:
            customReportId !== undefined
              ? customPlData.values
              : customPlData.rows?.filter(
                  (row) => row.name !== " " && row.name !== ""
                ),
          columnValues:
            customPlData.loadType === "initial"
              ? customPlData.columns
              : customPlData.columnValues,
          columnDetails: customPlData.columnDetails,
        });
        setColumnOrder(
          customReportId !== undefined
            ? customPlData?.columnOrder
            : Object.keys(customPlData.columns)?.map((key) => key)
        );
        if (customReportId !== undefined) {
          setExpanded(customPlData.expanded || {});
        }
      }
      if (customPlData.loadType === "add") {
        if (customPlData.actionType === "add") {
          setReportData({
            columns: [
              ...(reportData?.columns || []),
              ...Object.keys(customPlData.columns).map((key) => {
                return getColumnDetail(customPlData.columns, key);
              }),
            ],
            rows: rowData(
              getFilteredValues([
                ...reportData.values.map((value) => {
                  let currentRow = customPlData.rows?.find((rowData) => {
                    return (
                      rowData.id === value.id ||
                      (value.rowType === ROW_TYPE.TOTAL ||
                      value.rowType === ROW_TYPE.FORMULA
                        ? rowData.name === value.name
                        : false)
                    );
                  });
                  return { ...value, ...currentRow };
                }),
              ])
            ),
            values: [
              ...reportData.values.map((value) => {
                let currentRow = customPlData.rows?.find((rowData) => {
                  return (
                    rowData.id === value.id ||
                    (value.rowType === ROW_TYPE.TOTAL ||
                    value.rowType === ROW_TYPE.FORMULA
                      ? rowData.name === value.name
                      : false)
                  );
                });
                return { ...value, ...currentRow };
              }),
            ],
            columnValues: {
              ...reportData.columnValues,
              ...customPlData.columns,
            },
            columnDetails: [
              ...reportData.columnDetails,
              ...customPlData.columnDetails,
            ],
          });
          setColumnOrder([
            ...columnOrder,
            ...Object.keys(customPlData.columns)?.map((key) => key),
          ]);
        } else {
          setReportData({
            ...reportData,
            columns: _.cloneDeep(reportData.columns)?.map((col) => {
              let columnId = Object.keys(customPlData.columns)[0];
              if (col.id === columnId) {
                return getColumnDetail(customPlData.columns, columnId);
              } else {
                return col;
              }
            }),
            rows: rowData(
              getFilteredValues([
                ...(reportData.values || [])?.map((value) => {
                  let currentRow = customPlData.rows?.find((rowData) => {
                    return (
                      rowData.id === value.id ||
                      (value.rowType === ROW_TYPE.TOTAL ||
                      value.rowType === ROW_TYPE.FORMULA
                        ? rowData.name === value.name
                        : false)
                    );
                  });
                  return { ...value, ...currentRow };
                }),
              ])
            ),
            values: [
              ...(reportData.values || [])?.map((value) => {
                let currentRow = customPlData.rows?.find((rowData) => {
                  return (
                    rowData.id === value.id ||
                    (value.rowType === ROW_TYPE.TOTAL ||
                    value.rowType === ROW_TYPE.FORMULA
                      ? rowData.name === value.name
                      : false)
                  );
                });
                return { ...value, ...currentRow };
              }),
            ],
            columnValues: {
              ...reportData.columnValues,
              ...customPlData.columns,
            },
            columnDetails: _.cloneDeep(reportData.columnDetails)?.map(
              (detail) => {
                let currentDetail = customPlData.columnDetails.find(
                  (det) => det.key === detail.key
                );
                return currentDetail ? currentDetail : detail;
              }
            ),
          });
        }
      }
    }
    // eslint-disable-next-line
  }, [report, customData]);

  useEffect(() => {
    if (collapsedMap) {
      setReportData({
        ...reportData,
        rows: rowData(
          getFilteredValues(reportData?.values)?.map((val) => {
            if (expanded[collapsedMap[val.id]]) {
              let row = {};
              console.log(expanded);
              Object.keys(val)?.forEach((key) => {
                if (DEFAULT_KEYS.includes(key)) {
                  row[key] = val[key];
                } else {
                  row[key] = null;
                }
              });
              return row;
            } else {
              return val;
            }
          })
        ),
      });
    }
    // eslint-disable-next-line
  }, [collapsedMap]);

  const table = useReactTable({
    data: reportData.rows,
    columns: reportData.columns,
    state: {
      expanded,
      columnOrder,
    },
    onColumnOrderChange: setColumnOrder,
    onExpandedChange: (val) => {
      setExpanded(val);
      let collapsedMap = {};
      reportData.values.forEach((row) => {
        if (
          row.rowType === ROW_TYPE.METRIC ||
          row.rowType === "EBITDA_HEADER"
        ) {
          let index = reportData.rows.findIndex((val) => val.id === row.id);
          collapsedMap[row.id] = `${index}`;
        }
        if (row.rowType === ROW_TYPE.GROUP) {
          let childrenRows = reportData.values.filter(
            (val) => val.parentId === row.parentId
          );
          let parentIndex = reportData.values.findIndex(
            (val) => val.id === row.parentId
          );
          collapsedMap[row.id] = `${parentIndex}.${childrenRows.findIndex(
            (val) => val.id === row.id
          )}`;
        }
      });
      setCollapsedMap(collapsedMap);
    },
    getSubRows: (row) => row.subRows,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
  });

  const handleCloseSideNav = () => {
    setSideNavOpen(false);
    setFormulaList([]);
    setSideNavData(_.cloneDeep(initialSideNavData));
    setAllowSortOrderFormula(false);
    setHasError(false);
    setActionType("add");
  };

  const handleDragEnd = useCallback((event) => {
    const { active, over } = event;

    if (active.id !== over?.id) {
      if (over?.id) {
        setFormulaList((items) => {
          let oldIndex = items.findIndex((item) => item.id === active.id);
          let newIndex = items.findIndex((item) => item.id === over.id);
          return arrayMove(items, oldIndex, newIndex);
        });
      }
    }
  }, []);
  const handleDragEnd1 = useCallback((event) => {
    const { active, over } = event;
    if (over.id === "name") {
      return;
    }
    if (active && over && active.id !== over.id) {
      setColumnOrder((columnOrder) => {
        const oldIndex = columnOrder.indexOf(active.id);
        const newIndex = columnOrder.indexOf(over.id);
        return arrayMove(columnOrder, oldIndex, newIndex); //this is just a splice util
      });
    }
  }, []);
  const handleSideNavChanges = (type, value) => {
    switch (type) {
      case "name":
        setSideNavData({ ...sideNavData, name: value });
        break;
      case "type":
        setSideNavData({ ...sideNavData, type: value });
        break;
      case "dataSource":
        setSideNavData({ ...sideNavData, dataSource: value });
        break;
      case "dateType":
        setSideNavData({
          ...sideNavData,
          dateType: value,
          date: { ...initialSideNavData.date },
        });
        break;
      case "start":
        setSideNavData({ ...sideNavData, date: { start: value, end: null } });
        break;
      case "date":
        setSideNavData({
          ...sideNavData,
          date: { start: value.start, end: value.end },
        });
        break;
      case "spreadsheetId":
        setSideNavData({ ...sideNavData, spreadsheetId: value });
        break;
      default:
        break;
    }
  };
  const nameValidation = (name) => {
    return Object.values(
      _.omit(reportData.columnValues, sideNavData?.key)
    ).includes(name.trim());
  };
  const formatNumber = (number) => {
    const formattedNumber = Math.abs(number).toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return number < 0 ? `(${formattedNumber})` : formattedNumber;
  };

  const handleFormulaCalculation = () => {
    if (actionType === "add") {
      let key = new ObjectID().toString();
      let columnDetail = {
        name: sideNavData?.name,
        date: null,
        type: sideNavData?.type,
        dataSource: sideNavData?.dataSource,
        dateType: null,
        key,
        spreadsheetId: null,
        formulaList,
      };
      let valuesClone = _.cloneDeep(reportData.values).map((value) => {
        let parsedExpression = formulaList.map((opt) => {
          if (opt.type === "operand") {
            return parseFloat(
              value[opt.value]?.replace("(", "-")?.replace(/[),]/g, "") || "0"
            );
          } else {
            return opt.name;
          }
        });
        parsedExpression = parsedExpression.join("");
        try {
          console.log(formatNumber(parse(parsedExpression)?.evaluate()));
          value[key] = formatNumber(parse(parsedExpression)?.evaluate());
        } catch (err) {
          setFormulaError(true);
        }
        return value;
      });

      setReportData({
        ...reportData,
        columns: [
          ..._.cloneDeep(reportData.columns),
          getColumnDetail({ [key]: sideNavData?.name }, key),
        ],
        rows: rowData(getFilteredValues(valuesClone)),
        values: valuesClone,
        columnValues: {
          ..._.cloneDeep(reportData.columnValues),
          [key]: sideNavData?.name,
        },
        columnDetails: [..._.cloneDeep(reportData.columnDetails), columnDetail],
      });
      setColumnOrder([...columnOrder, ...[key]]);
    } else {
      let valuesClone = _.cloneDeep(reportData.values).map((value) => {
        let parsedExpression = formulaList.map((opt) => {
          if (opt.type === "operand") {
            return parseFloat(
              value[opt.value]?.replace("(", "-")?.replace(/[),]/g, "") || "0"
            );
          } else {
            return opt.name;
          }
        });
        parsedExpression = parsedExpression.join("");
        try {
          value[sideNavData?.key] = formatNumber(
            parse(parsedExpression)?.evaluate()
          );
        } catch (err) {}
        return value;
      });
      setReportData({
        ...reportData,
        columns: _.cloneDeep(reportData.columns).map((column) => {
          if (column.id === sideNavData?.key) {
            return getColumnDetail(
              { [sideNavData?.key]: sideNavData?.name },
              sideNavData?.key
            );
          } else {
            return column;
          }
        }),
        rows: rowData(valuesClone),
        columnValues: {
          ...reportData.columnValues,
          [sideNavData.key]: sideNavData.name,
        },
        values: valuesClone,
        columnDetails: _.cloneDeep(reportData.columnDetails).map((col) => {
          if (col.key === sideNavData.key) {
            return { ...sideNavData, formulaList };
          } else {
            return col;
          }
        }),
      });
    }
  };

  const handleSideNavSave = () => {
    if (sideNavData?.name?.trim() === "" || nameValidation(sideNavData?.name)) {
      setHasError(true);

      return;
    }
    if (sideNavData?.dataSource === COLUMN_DATA_TYPES.FORMULA) {
      handleFormulaCalculation();
    } else {
      let currentDetails =
        reportData?.columnDetails?.find(
          (detail) => detail.key === sideNavData?.key
        ) || {};
      let dataChanged = !_.isEqual(sideNavData, currentDetails);
      if (dataChanged) {
        dispatch(
          getCustomProfitLossData({
            entityId,
            accountingFirmId,
            start: sideNavData?.date?.start,
            end: sideNavData?.date?.end || sideNavData?.date?.start,
            loadType: "add",
            name: sideNavData?.name,
            source: sideNavData?.dataSource,
            spreadsheetId: sideNavData?.spreadsheetId,
            dateType: sideNavData?.dateType,
            key: actionType === "edit" ? sideNavData?.key : null,
          })
        );
      }
    }
    handleCloseSideNav();
  };
  const handleModalClose = () => {
    setSaveReportModalOpen(false);
    setNewReportSaveData({ name: "", description: "" });
  };

  const setNewReportData = (type, value) => {
    setNewReportSaveData({ ...newReportSaveData, [type]: value });
  };
  const handleSaveReport = () => {
    if (customReportId === undefined) {
      dispatch(
        addReport({
          payload: {
            ...newReportSaveData,
            ...reportData,
            columnOrder,
            expanded,
            createdBy: JSON.parse(localStorage.getItem("user_data"))?.userId,
          },
          accountingFirmId,
          entityId,
        })
      );
      handleModalClose();
    } else {
      dispatch(
        updateReport({
          payload: {
            ...reportData,
            columnOrder,
            expanded,
          },
          accountingFirmId,
          reportId: customReportId,
        })
      );
    }
  };

  const handleDeleteColumn = () => {
    let clone = _.cloneDeep(reportData);
    clone.columnDetails = clone.columnDetails.filter(
      (col) => col.key !== sideNavData.key
    );
    clone.columnValues = _.omit(clone.columnValues, sideNavData.key);
    clone.columns = clone.columns.filter((col) => col.id !== sideNavData.key);
    clone.values = clone.values.map((val) => {
      return _.omit(val, sideNavData.key);
    });
    clone.rows = rowData(getFilteredValues(clone.values));
    setReportData(clone);
    setDeleteModalOpen(false);
    setSideNavData(_.cloneDeep(initialSideNavData));
    setSideNavOpen(false);
  };

  return (
    <div className="p-10 mt-20 ">
      {deleteModalOpen && (
        <ConfirmModal
          modalOpen={deleteModalOpen}
          setModalOpen={setDeleteModalOpen}
          onClose={() => setDeleteModalOpen(false)}
          confirmFunction={handleDeleteColumn}
          declineFunction={() => setDeleteModalOpen(false)}
          message="Are you sure you want to delete the column?"
        />
      )}
      <CustomModal
        modalOpen={saveReportModalOpen}
        setModalOpen={setSaveReportModalOpen}
        onClose={handleModalClose}
        overflow={"auto"}
        maxWidth={"md"}
      >
        <Grid container>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={6}>
                <Typography
                  style={{ display: "flex" }}
                  fontSize={20}
                  fontWeight={"bold"}
                >
                  <p style={{ margin: 0 }} className="mr-5">
                    Save Report
                  </p>
                  <Tooltip
                    placement="right"
                    title={
                      <p style={{ fontSize: 16 }}>
                        On save completion you will be redirected to custom
                        reports list !
                      </p>
                    }
                  >
                    <Info className="mt-5" fontSize="small" />
                  </Tooltip>
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Grid container justifyContent={"right"}>
                  <IconButton onClick={handleModalClose}>
                    <Close />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={7} className="mt-10">
            <InputLabel
              sx={{ fontWeight: "bold" }}
              className="font-label mt-10 mb-10"
            >
              Report Name
            </InputLabel>
            <TextField
              value={newReportSaveData.name}
              fullWidth
              size="small"
              onChange={({ target: { value } }) => {
                setNewReportData("name", value);
              }}
            ></TextField>
          </Grid>
          <Grid item xs={7} className="mt-10">
            <InputLabel
              sx={{ fontWeight: "bold" }}
              className="font-label mt-10 mb-10"
            >
              Short Description
            </InputLabel>
            <TextField
              rows={3}
              maxRows={4}
              multiline
              fullWidth
              value={newReportSaveData.description}
              size="small"
              onChange={({ target: { value } }) => {
                setNewReportData("description", value);
              }}
            ></TextField>
          </Grid>
          <Grid item xs={12} className="'mt-10">
            <Grid container justifyContent={"right"}>
              <ButtonComponent
                className={"mr-10"}
                onClick={handleModalClose}
                title="Cancel"
              />
              <ButtonComponent
                disabled={
                  newReportSaveData.name === "" ||
                  newReportSaveData.description === ""
                }
                onClick={handleSaveReport}
                contained
                title="Save"
              />
            </Grid>
          </Grid>
        </Grid>
      </CustomModal>
      <div>
        <Drawer
          anchor={"right"}
          open={sideNavOpen}
          onClose={handleCloseSideNav}
        >
          <Box sx={{ width: "600px", paddingTop: "70px" }}>
            <Grid container className="p-10">
              <Grid item xs={12}>
                <Grid container justifyContent={"space-between"}>
                  <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                    {actionType === "add" ? "Add" : "Edit"} {addType}
                  </Typography>
                  {actionType !== "add" && (
                    <ButtonComponent
                      title={"Delete"}
                      disabled={reportData?.columns.length <= 2}
                      startIcon={<Delete />}
                      onClick={() => {
                        setDeleteModalOpen(true);
                      }}
                    />
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12} className="mt-10">
                <Grid container>
                  <Grid item xs={8}>
                    <InputLabel className=" font-label mb-10">
                      Name:{" "}
                    </InputLabel>
                    <TextField
                      fullWidth
                      size="small"
                      value={sideNavData?.name}
                      onChange={({ target }) => {
                        handleSideNavChanges("name", target.value);
                      }}
                      error={
                        hasError &&
                        (sideNavData?.name?.trim() === "" ||
                          nameValidation(sideNavData?.name))
                      }
                      helperText={
                        hasError && sideNavData?.name?.trim() === ""
                          ? "Name is required"
                          : hasError && nameValidation(sideNavData?.name)
                          ? "Name already used"
                          : ""
                      }
                    ></TextField>
                  </Grid>
                  <Grid item xs={8} className="mt-10">
                    <InputLabel className=" font-label mb-10" id="type-label">
                      Value Type:
                    </InputLabel>
                    <Select
                      fullWidth
                      size="small"
                      id="input-type"
                      value={sideNavData?.type}
                      labelId="type-label"
                      onChange={({ target }) => {
                        handleSideNavChanges("type", target.value);
                      }}
                    >
                      {Object.keys(COLUMN_TYPES)?.map((key) => {
                        return (
                          <MenuItem key={key} value={COLUMN_TYPES[key]}>
                            {COLUMN_TYPES[key]}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </Grid>
                  {addType === "column" && (
                    <Grid item xs={8} className="mt-10">
                      <InputLabel className=" font-label mb-10" id="type-label">
                        Data Source:
                      </InputLabel>
                      <Select
                        fullWidth
                        size="small"
                        id="input-type"
                        value={sideNavData?.dataSource}
                        labelId="data-source-label"
                        onChange={({ target }) => {
                          handleSideNavChanges("dataSource", target.value);
                        }}
                      >
                        {Object.keys(COLUMN_DATA_TYPES)?.map((key) => {
                          return (
                            <MenuItem key={key} value={COLUMN_DATA_TYPES[key]}>
                              {COLUMN_DATA_TYPES[key]}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </Grid>
                  )}

                  {addType === "column" &&
                    sideNavData?.dataSource ===
                      COLUMN_DATA_TYPES.SPREADSHEET && (
                      <Grid item xs={8} className="mt-10">
                        <InputLabel
                          className=" font-label mb-10"
                          id="type-label"
                        >
                          Spreadsheet:
                        </InputLabel>
                        <Select
                          fullWidth
                          size="small"
                          id="input-type"
                          value={sideNavData?.spreadsheetId}
                          labelId="data-source-label"
                          onChange={({ target }) => {
                            handleSideNavChanges("spreadsheetId", target.value);
                          }}
                        >
                          {spreadsheets?.map((spreadsheet) => {
                            return (
                              <MenuItem
                                key={spreadsheet?._id}
                                value={spreadsheet?._id}
                              >
                                {spreadsheet?.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </Grid>
                    )}
                  {addType === "row" && (
                    <Grid item xs={12} className="mt-10">
                      <InputLabel
                        className=" font-label mb-10"
                        id="operand-label"
                      >
                        Row Placement:
                      </InputLabel>
                      <Grid container>
                        <Grid item xs={4}>
                          <Grid
                            container
                            className="mt-10"
                            justifyContent={"flex-start"}
                          >
                            <Typography>Before</Typography>
                            <AntSwitch
                              onChange={() =>
                                handleSideNavChanges(
                                  "rowPlacement",
                                  !sideNavData?.gstApplicable
                                )
                              }
                            />
                            <Typography>After</Typography>
                          </Grid>
                        </Grid>
                        <Grid item xs={8}>
                          <Select
                            fullWidth
                            size="small"
                            id="input-operands"
                            labelId="operand-label"
                            value={null}
                            defaultValue={null}
                          >
                            {reportData.values?.map((item) => {
                              return (
                                <MenuItem key={item.id} value={item.name}>
                                  {item.name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                  {(sideNavData?.dataSource === COLUMN_DATA_TYPES.FORMULA ||
                    addType === "row") && (
                    <Grid
                      item
                      xs={12}
                      style={{
                        border: "1px grey solid",
                        borderRadius: "5px",
                        padding: 10,
                      }}
                      className="mt-20"
                    >
                      <Grid container>
                        {" "}
                        <Grid item xs={12} className="mt-20">
                          <Grid container justifyContent={"space-between"}>
                            <Grid item xs={6}>
                              <Typography
                                variant="body1"
                                sx={{ fontWeight: "bold" }}
                              >
                                Formula
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container spacing={2}>
                            <Grid item xs={5}>
                              {" "}
                              <InputLabel
                                className=" font-label mb-10"
                                id="operand-label"
                              >
                                Operands:
                              </InputLabel>
                              <Select
                                fullWidth
                                size="small"
                                id="input-operands"
                                labelId="operand-label"
                                value={null}
                                defaultValue={null}
                                onChange={({ target }) => {
                                  setFormulaList([
                                    ...formulaList,
                                    {
                                      id: uniqueId(),
                                      value: target.value.split("_")[0],
                                      name: target.value.split("_")[1],
                                      type: "operand",
                                    },
                                  ]);
                                }}
                              >
                                {(addType === "column"
                                  ? reportData?.columns
                                  : reportData.values
                                )
                                  ?.filter((item) =>
                                    addType === "column"
                                      ? item.accessorKey !== "name" &&
                                        item.accessorKey !== sideNavData?.key
                                      : true
                                  )
                                  ?.map((item) => {
                                    return (
                                      <MenuItem
                                        key={
                                          addType === "column"
                                            ? item.accessorKey
                                            : item.id
                                        }
                                        value={item.id + "_" + item.value}
                                      >
                                        {addType === "column"
                                          ? item.value
                                          : item.name}
                                      </MenuItem>
                                    );
                                  })}
                              </Select>
                            </Grid>
                            <Grid item xs={6}>
                              <InputLabel
                                className=" font-label mb-10"
                                id="operator-label"
                              >
                                Operators:
                              </InputLabel>
                              <Grid container spacing={2}>
                                {CALCULATION_TYPES?.map((item) => {
                                  return (
                                    <Grid item xs={3}>
                                      <Button
                                        onClick={() => {
                                          setFormulaList([
                                            ...formulaList,
                                            {
                                              id: uniqueId(),
                                              name: getFormulaSymbol(item),
                                              type: "operator",
                                            },
                                          ]);
                                        }}
                                        fullWidth
                                        variant="outlined"
                                        style={{
                                          fontSize: "16px",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        {getFormulaSymbol(item)}
                                      </Button>
                                    </Grid>
                                  );
                                })}
                              </Grid>
                            </Grid>
                            <Grid item xs={1}>
                              <IconButton
                                className="mt-30"
                                onClick={() => {
                                  setAllowSortOrderFormula(true);
                                }}
                              >
                                <SwapVert />
                              </IconButton>
                            </Grid>
                          </Grid>
                        </Grid>
                        {formulaList?.length !== 0 && (
                          <Grid
                            item
                            xs={12}
                            style={{
                              border: "1px grey solid",
                              borderRadius: "5px",
                              padding: 10,
                            }}
                            className="mt-20"
                          >
                            <DndContext
                              sensors={sensors}
                              collisionDetection={closestCenter}
                              onDragEnd={handleDragEnd}
                            >
                              <SortableContext
                                disabled={!allowSortOrderFormula}
                                items={formulaList}
                                strategy={rectSortingStrategy}
                              >
                                <SortableGrid columns={4}>
                                  {formulaList.map((formula) => {
                                    return (
                                      <SortableItem
                                        key={formula?.id}
                                        id={formula?.id}
                                        name={formula?.name}
                                        disabled={!allowSortOrderFormula}
                                        onDelete={() => {
                                          let index = formulaList
                                            .map((formula) => formula.id)
                                            .indexOf(formula.id);
                                          let val = _.cloneDeep(formulaList);
                                          val.splice(index, 1);
                                          setFormulaList(val);
                                        }}
                                      />
                                    );
                                  })}
                                </SortableGrid>
                              </SortableContext>
                            </DndContext>
                          </Grid>
                        )}
                        {allowSortOrderFormula && (
                          <Grid item xs={12} className="mt-20 p-10">
                            <Grid container justifyContent={"flex-end"}>
                              <ButtonComponent
                                size="small"
                                onClick={() => {
                                  setAllowSortOrderFormula(false);
                                }}
                                title={"Cancel"}
                              />

                              <ButtonComponent
                                contained
                                className="ml-10"
                                size="small"
                                title={"Save"}
                              />
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  )}
                  {sideNavData?.dataSource !== COLUMN_DATA_TYPES.FORMULA &&
                    addType === "column" && (
                      <Grid
                        item
                        xs={12}
                        style={{
                          border: formulaError
                            ? "1px red solid"
                            : "1px grey solid",
                          borderRadius: "5px",
                          padding: 10,
                        }}
                        className="mt-20"
                      >
                        <Grid container>
                          {" "}
                          <Grid item xs={12} className="mt-20">
                            <Grid container justifyContent={"space-between"}>
                              <Grid item xs={6}>
                                <Typography
                                  variant="body1"
                                  sx={{ fontWeight: "bold" }}
                                >
                                  Date Range
                                </Typography>
                                <Select
                                  className="mt-10"
                                  fullWidth
                                  size="small"
                                  value={sideNavData?.dateType}
                                  labelId="date-type-label"
                                  onChange={({ target }) => {
                                    handleSideNavChanges(
                                      "dateType",
                                      target.value
                                    );
                                  }}
                                >
                                  {Object.keys(DATE_RANGE_TYPES)?.map((key) => {
                                    return (
                                      <MenuItem
                                        key={key}
                                        value={DATE_RANGE_TYPES[key]}
                                      >
                                        {DATE_RANGE_TYPES[key]}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </Grid>
                            </Grid>
                          </Grid>
                          {sideNavData?.dateType === DATE_RANGE_TYPES.MONTH && (
                            <Grid item xs={6} className="mt-20">
                              <DatePicker
                                format={"MMM YYYY"}
                                style={{ width: "100%" }}
                                allowClear={false}
                                size="large"
                                defaultValue={dayjs(sideNavData?.date?.start)}
                                picker="month"
                                onChange={(date, dateString) => {
                                  handleSideNavChanges("start", dateString);
                                }}
                                getPopupContainer={(triggerNode) => {
                                  return triggerNode.parentNode;
                                }}
                              />
                            </Grid>
                          )}
                          {sideNavData?.dateType ===
                            DATE_RANGE_TYPES.CUSTOM && (
                            <Grid item xs={6} className="mt-20">
                              <RangePicker
                                size="large"
                                format={"MMM YY"}
                                picker="month"
                                defaultValue={[
                                  dayjs(sideNavData?.date?.start),
                                  dayjs(sideNavData?.date?.end),
                                ]}
                                onChange={(val) => {
                                  handleSideNavChanges("date", {
                                    start: val[0].format("YYYY-MM"),
                                    end: val[1].format("YYYY-MM"),
                                  });
                                }}
                                getPopupContainer={(triggerNode) => {
                                  return triggerNode.parentNode;
                                }}
                              ></RangePicker>
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} className="mt-20 p-10">
              <Grid container justifyContent={"flex-end"}>
                <ButtonComponent
                  onClick={handleCloseSideNav}
                  title={"Cancel"}
                />

                <ButtonComponent
                  disabled={allowSortOrderFormula}
                  contained
                  className="ml-10"
                  onClick={() => {
                    handleSideNavSave();
                  }}
                  title={"Save"}
                />
              </Grid>
            </Grid>
          </Box>
        </Drawer>
        <Grid
          container
          className="full-container"
          style={{ height: "90vh", padding: "20px" }}
        >
          <Grid item xs={6}>
            <Typography variant="h6" sx={{ fontWeight: "500" }}>
              Custom report
            </Typography>
          </Grid>
          <Grid item xs={6} className="mb-10">
            <Grid container justifyContent={"right"}>
              <Grid item>
                <Grid container justifyContent={"right"}>
                  <ButtonComponent
                    className={"mr-10"}
                    onClick={() => {
                      setAddType("column");
                      setSideNavOpen(true);
                    }}
                    startIcon={<Add />}
                    title={"Column"}
                  />
                  <ButtonComponent
                    className={"mr-10"}
                    disabled={reportData?.columns?.length <= 2}
                    onClick={() => {
                      setAllowColumnReorder(!allowColumnReorder);
                    }}
                    startIcon={
                      <SwapHoriz
                        color={allowColumnReorder ? "primary" : "default"}
                      />
                    }
                    title={"Column"}
                  />
                  <ButtonComponent
                    contained
                    onClick={() => {
                      customReportId === undefined
                        ? setSaveReportModalOpen(true)
                        : handleSaveReport();
                    }}
                    startIcon={<Save />}
                    title={"Report"}
                  />
                </Grid>
              </Grid>
              {/* <Grid item>
                <Button variant="contained">Save</Button>
              </Grid> */}
            </Grid>
          </Grid>

          {!isFetching &&
            reportData?.rows?.length !== 0 &&
            reportData?.columns?.length !== 0 &&
            reportData.rows && (
              <DndContext
                collisionDetection={closestCenter}
                modifiers={[restrictToHorizontalAxis]}
                onDragEnd={handleDragEnd1}
                sensors={sensors1}
              >
                <div>
                  <table
                    style={{
                      borderSpacing: "0px",
                      display: "inline-block",
                      overflow: "auto",
                      height: "80vh",
                      maxWidth: "90vw",
                    }}
                  >
                    <thead>
                      {table?.getHeaderGroups()?.map((headerGroup, index) => (
                        <tr
                          style={{
                            borderRadius: "10px",
                            clipPath: " xywh(0 0 100% 100% round 0.5em)",
                          }}
                          className="tr-sticky"
                          key={headerGroup.id}
                        >
                          <SortableContext
                            items={columnOrder}
                            strategy={horizontalListSortingStrategy}
                          >
                            {headerGroup.headers.map((header) => (
                              <DraggableTableHeader
                                key={header.id}
                                header={header}
                                columns={reportData.columns}
                                className={
                                  header.id === "name" ? "th-sticky" : ""
                                }
                                allowColumnReorder={allowColumnReorder}
                                handleEditColumn={handleEditColumn}
                              />
                            ))}
                          </SortableContext>
                        </tr>
                      ))}
                    </thead>
                    <tbody>
                      {table?.getRowModel()?.rows?.map((row, index) => (
                        <tr
                          key={row.id}
                          style={{
                            height: "50px",
                            backgroundColor: profitRows.includes(
                              row.original.name
                            )
                              ? "#F1F5F9"
                              : "white",
                            borderRadius: "10px",
                            fontWeight:
                              row.original.rowType === ROW_TYPE.METRIC ||
                              row.original.rowType === "EBITDA_HEADER"
                                ? "500"
                                : "300",
                            clipPath: " xywh(0 0 100% 100% round 0.5em)",
                          }}
                        >
                          {row.getVisibleCells().map((cell) => (
                            <SortableContext
                              key={cell.id}
                              items={columnOrder}
                              strategy={horizontalListSortingStrategy}
                            >
                              <DragAlongCell key={cell.id} cell={cell} />
                            </SortableContext>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </DndContext>
            )}
        </Grid>
      </div>

      {isFetching && <Loading wait />}
    </div>
  );
};

export default CustomReportPlayGround;

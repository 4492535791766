import React from "react";
import { Grid, Box } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { ColorPalette } from "../../constants/colorPalette";
import { ButtonComponent } from "../Button/Button";
// import { useSelector } from "react-redux";
// import { checkDataAvailibility } from "../../helper/utils";

export default function ClientSpaceCard({
  marginTop,
  title,
  subtitle,
  setModalOpen,
  setType,
  addTitle,
  children,
  buttonId,
  titleId,
}) {
  // const userData = useSelector((state) => state.user);
  // const stateData = checkDataAvailibility(userData);

  return (
    <Grid
      item
      borderRadius={4}
      xs={12}
      minWidth={"100%"}
      marginTop={marginTop || 0}
      className="container"
      paddingTop={5}
      paddingBottom={4}
      overflow="auto"
    >
      <Grid container>
        <Grid item xs={12}>
          <Grid container ml={1} mb={1} justifyContent={"space-between"}>
            <div>
              <label
                id={`text-${titleId}`}
                style={{
                  fontSize: "22px",
                  color: ColorPalette.text,
                  paddingLeft: 8,
                }}
              >
                {title}
              </label>
              {subtitle ? (
                <label
                  style={{
                    fontSize: "16px",
                    fontWeight: "lighter",
                    color: ColorPalette.primary,
                    paddingLeft: 8,
                  }}
                >
                  ({subtitle})
                </label>
              ) : null}
            </div>
            {addTitle && (
              // stateData.role === "ADMIN" &&
              <Box pr={4}>
                <ButtonComponent
                  title={addTitle}
                  startIcon={<AddIcon />}
                  onClick={() => {
                    setModalOpen(true);
                    // setType("add");
                  }}
                />
              </Box>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} marginTop={2}>
          {children}
        </Grid>
      </Grid>
    </Grid>
  );
}

import {
  Avatar,
  AvatarGroup,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Popper,
  Tooltip,
  Typography,
} from "@mui/material";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import "./ClientSpaces.css";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import {
  deleteClientSpace,
  getClientSpaces,
} from "../../../redux/ClientSpace/ClientSpaceSlice";
import { useNavigate, useParams } from "react-router-dom";
import TableListCard from "../../../components/TableListCard/TableListCard";
import { ColorPalette } from "../../../constants/colorPalette";
import NoDataView from "../../../components/NoDataView/NoDataView";
import Loading from "../../../components/LoadingIndicator/Loading";
import AddClientSpaceModal from "./AddClientSpaceModal/AddClientSpaceModal";
import EditClientSpaceModal from "./editClientSpace/EditClientSpaceModal";
import ConfirmModal from "../../../components/CustomModal/ConfirmModal";
import { checkDataAvailibility } from "../../../helper/utils";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import HistoryTableModal from "../../../components/HistoryTable/HistoryTableModal";
import { ButtonComponent } from "../../../components/Button/Button";
import { MoreVertOutlined } from "@mui/icons-material";

export default function ClientSpaces() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const { accountingFirmId } = useParams();
  const { clientSpaces, isFetching } = useSelector(
    (state) => state.clientSpaces
  );
  const userData = useSelector((state) => state.user);
  const stateData = checkDataAvailibility(userData);

  const [, setType] = useState("add");
  const [anchorEl, setAnchorEl] = useState(null);
  const [avatarAnchorEl, setAvatarAnchorEl] = useState(null);
  const [editSpaceModalOpen, setEditSpaceModalOpen] = useState(false);
  const [deleteSpaceModalOpen, setDeleteSpaceModalOpen] = useState(false);
  const [historyModalOpen, setHistoryModalOpen] = useState(false);
  const [editData, setEditData] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const [initialLoadingState, setInitialLoadingState] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setInitialLoadingState(false);
    }, 500);
  }, []);

  useEffect(() => {
    dispatch(getClientSpaces({ accountingFirmId }));
  }, [accountingFirmId, dispatch]);

  function createData(spaces) {
    let list = [];
    spaces?.forEach((space) => {
      const manager = space?.users.find((user) => user.role === "MANAGER");
      const accountants = space?.users.filter(
        (user) => user.role === "ACCOUNTANT"
      );

      const managerFullName =
        manager?.user?.first_name && manager?.user?.last_name
          ? `${manager?.user?.first_name} ${manager?.user?.last_name}`
          : "John Doe";

      const accountantFullNames = accountants.map((acc) => {
        const firstName = acc.user?.first_name;
        const lastName = acc.user?.last_name;

        return firstName && lastName ? `${firstName} ${lastName}` : "John Doe";
      });

      list.push({
        name: space?.clientSpaceName,
        id: space?._id,
        manager: managerFullName,
        accountant: accountantFullNames,
        ...space,
      });
    });
    return list;
  }

  const rows = createData(clientSpaces);

  const handleMenuItemClick = (event, row) => {
    setAnchorEl({ [row?.id]: event.currentTarget });
    setEditData(row);
    setDeleteId(row?.id);
  };

  const handleMenuItemClose = () => {
    setAnchorEl(null);
  };

  const handleEditModal = () => {
    setEditSpaceModalOpen(true);
    handleMenuItemClose();
  };

  const handleDeleteModal = () => {
    setDeleteSpaceModalOpen(true);
    handleMenuItemClose();
  };

  const handleDelete = () => {
    dispatch(deleteClientSpace({ accountingFirmId, id: deleteId }));
    setDeleteSpaceModalOpen(false);
  };

  return (
    <div
      style={{
        paddingRight: "2%",
        paddingLeft: "2%",
      }}
    >
      <Grid container>
        {modalOpen && (
          <AddClientSpaceModal
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            accountingFirmId={accountingFirmId}
            rows={clientSpaces}
            stateData={stateData}
          />
        )}
        {editSpaceModalOpen && (
          <EditClientSpaceModal
            modalOpen={editSpaceModalOpen}
            setModalOpen={setEditSpaceModalOpen}
            editData={editData}
            rows={clientSpaces}
          />
        )}
        {deleteSpaceModalOpen && (
          <ConfirmModal
            message="Are you sure you want to delete clientspace?"
            confirmText="Yes"
            declineText="No"
            declineFunction={() => setDeleteSpaceModalOpen(false)}
            confirmFunction={handleDelete}
            modalOpen={deleteSpaceModalOpen}
            setModalOpen={setDeleteSpaceModalOpen}
            title={"Delete Clientspace"}
            tooltipMessage={
              "Delete Impacts (Entities, Spreadsheets, Xero Data) "
            }
          />
        )}
        {historyModalOpen && (
          <HistoryTableModal
            modalOpen={historyModalOpen}
            setModalOpen={setHistoryModalOpen}
            pageType={"CLIENT_SPACE"}
            operations={["Add", "Update", "Delete"]}
          />
        )}
        <Grid container direction="column">
          <Typography
            id="text-welcomeBack"
            variant="h1"
            sx={{
              fontSize: { xs: 24, sm: 36 },
              fontWeight: "bold",
              // color: ColorPalette.secondaryDark,
              marginTop: "2rem",
            }}
          >
            Welcome Back, {checkDataAvailibility(userData)?.name?.split(" ")[0]}
          </Typography>
        </Grid>

        {!isFetching && rows.length === 0 ? (
          <NoDataView
            hintText="Sorry you haven't any client space"
            buttonText="Add Client Space"
            addFunction={setModalOpen}
            showButton={true}
          />
        ) : (
          <Fragment>
            {isFetching || initialLoadingState ? (
              <Loading />
            ) : (
              <Grid
                container
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"space-between"}
                height={"80vh"}
              >
                <Grid item>
                  {!isFetching && rows.length !== 0 && (
                    <TableListCard
                      title={"Client Spaces"}
                      addTitle={"Add Client Space"}
                      setModalOpen={setModalOpen}
                      marginTop={3}
                      setType={setType}
                      buttonId={"addSpace"}
                      titleId={"clientSpaces"}
                    >
                      <Grid container>
                        <Grid item xs={12} marginTop={1}>
                          <div>
                            <TableContainer
                              style={{
                                overflowY: "auto",
                                maxHeight: "53vh",
                                maxWidth: {
                                  xs: 260,
                                  sm: 290,
                                  md: 580,
                                  lg: "inherit",
                                },
                                border: `1px solid ${ColorPalette.borderColor}`,
                                borderRadius: "8px",
                              }}
                            >
                              <Table>
                                <TableHead
                                  style={{
                                    position: "sticky",
                                    top: "0",
                                    zIndex: 1,
                                    backgroundColor:
                                      ColorPalette.backgroundColor,
                                    border: "1",
                                  }}
                                >
                                  <TableRow>
                                    <TableCell>
                                      <Typography
                                        variant="body1"
                                        fontWeight={"bold"}
                                      >
                                        Client Space Name
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography
                                        variant="body1"
                                        fontWeight={"bold"}
                                      >
                                        Created At
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography
                                        variant="body1"
                                        fontWeight={"bold"}
                                      >
                                        Manager
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography
                                        variant="body1"
                                        fontWeight={"bold"}
                                      >
                                        Accountant
                                      </Typography>
                                    </TableCell>
                                    <TableCell className="table-header"></TableCell>
                                  </TableRow>
                                </TableHead>

                                <TableBody>
                                  {rows.map((row, index) => (
                                    <TableRow
                                      key={row.name + index}
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: 0,
                                        },
                                      }}
                                    >
                                      <TableCell component="th" scope="row">
                                        {row.name}
                                      </TableCell>
                                      <TableCell>
                                        {row?.createdAt?.split("T")[0]}
                                      </TableCell>
                                      <TableCell>
                                        <CardHeader
                                          style={{ padding: "0px" }}
                                          avatar={
                                            <Avatar
                                              style={{
                                                backgroundColor: `#0${row.manager.charCodeAt(
                                                  0
                                                )}`,
                                                color: "white",
                                              }}
                                            >
                                              {row.manager &&
                                                row.manager
                                                  .charAt(0)
                                                  .toUpperCase()}
                                            </Avatar>
                                          }
                                          title={row.manager}
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <CardHeader
                                          style={{ padding: "0px" }}
                                          avatar={
                                            <AvatarGroup
                                              max={3}
                                              slotProps={{
                                                additionalAvatar: {
                                                  sx: { cursor: "pointer" },
                                                  onMouseEnter: (event) => {
                                                    setAvatarAnchorEl(
                                                      event.currentTarget
                                                    );
                                                  },
                                                  onMouseLeave: () =>
                                                    setAvatarAnchorEl(null),
                                                },
                                              }}
                                            >
                                              {row.accountant?.map(
                                                (accountant, index) => (
                                                  <Tooltip
                                                    key={index}
                                                    title={accountant}
                                                  >
                                                    <Avatar
                                                      style={{
                                                        backgroundColor: `#0${accountant.charAt(
                                                          0
                                                        )}`,
                                                        color: "white",
                                                      }}
                                                    >
                                                      {accountant
                                                        .charAt(0)
                                                        .toUpperCase()}
                                                    </Avatar>
                                                  </Tooltip>
                                                )
                                              )}
                                            </AvatarGroup>
                                          }
                                        />
                                        {/* Add Popover for remaining users */}

                                        <Popper
                                          open={Boolean(avatarAnchorEl)}
                                          anchorEl={avatarAnchorEl}
                                          placement="bottom-start"
                                          sx={{
                                            zIndex: 1,
                                            marginTop: "8px",
                                          }}
                                        >
                                          <Paper
                                            sx={{
                                              boxShadow:
                                                "0px 4px 12px rgba(0, 0, 0, 0.15)",
                                              border: "1px solid #e0e0e0",
                                              borderRadius: "8px",
                                              overflow: "hidden",
                                              paddingBottom: "8px",
                                            }}
                                          >
                                            {row.accountant
                                              ?.slice(2)
                                              .map((name, index, array) => (
                                                <Typography
                                                  key={index}
                                                  style={{
                                                    padding: "8px 8px 0px 8px",
                                                  }}
                                                >
                                                  {name}
                                                  {index !==
                                                    array.length - 1 && (
                                                    <Divider
                                                      sx={{ mt: 1, mb: 0 }}
                                                    />
                                                  )}
                                                </Typography>
                                              ))}
                                          </Paper>
                                        </Popper>
                                      </TableCell>
                                      <TableCell align="right" width={"20%"}>
                                        <Grid
                                          display={"flex"}
                                          alignItems={"center"}
                                          justifyContent={"right"}
                                        >
                                          <ButtonComponent
                                            size="small"
                                            title={"View"}
                                            onClick={() => {
                                              navigate(
                                                `/accountingFirm/${accountingFirmId}/clientSpace/${row.id}/entities`
                                              );
                                            }}
                                          />
                                          {
                                            // stateData.role === "ADMIN" &&
                                            <Fragment>
                                              <IconButton
                                                id="button-clientEntityView"
                                                sx={{
                                                  height: 40,
                                                  width: 40,
                                                }}
                                                color="inherit"
                                              >
                                                <MoreVertOutlined
                                                  style={{
                                                    color: "grey",
                                                    fontSize: 20,
                                                  }}
                                                  onClick={(event) =>
                                                    handleMenuItemClick(
                                                      event,
                                                      row
                                                    )
                                                  }
                                                />
                                              </IconButton>
                                              <Menu
                                                anchorEl={anchorEl?.[row?.id]}
                                                open={Boolean(
                                                  anchorEl?.[row?.id]
                                                )}
                                                onClose={handleMenuItemClose}
                                              >
                                                <MenuItem
                                                  onClick={handleEditModal}
                                                >
                                                  <Typography className="menuItemTypography">
                                                    <EditOutlinedIcon className="mr-10" />
                                                    Edit
                                                  </Typography>
                                                </MenuItem>
                                                <MenuItem
                                                  onClick={handleDeleteModal}
                                                >
                                                  <Typography
                                                    style={{ color: "#D33030" }}
                                                    className="menuItemTypography"
                                                  >
                                                    <DeleteOutlineOutlinedIcon className="mr-10" />
                                                    Delete
                                                  </Typography>
                                                </MenuItem>
                                              </Menu>
                                            </Fragment>
                                          }
                                        </Grid>
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </div>
                        </Grid>
                      </Grid>
                    </TableListCard>
                  )}
                </Grid>

                {
                  // stateData.role === "ADMIN" &&
                  <Grid
                    sx={{
                      display: "flex",
                      justifyContent: "right",
                    }}
                  >
                    <ButtonComponent
                      title={"Activity Log"}
                      startIcon={<VisibilityOutlinedIcon />}
                      onClick={() => setHistoryModalOpen(true)}
                    />
                  </Grid>
                }
              </Grid>
            )}
          </Fragment>
        )}
      </Grid>
    </div>
  );
}

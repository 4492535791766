import React, { useEffect, useState } from "react";
import {
  Divider,
  Typography,
  Grid,
  IconButton,
  TextField,
  Button,
  InputLabel,
  Drawer,
  Box,
  Checkbox,
  FormControlLabel,
  Tooltip,
} from "@mui/material";
import { Close, Info, Visibility, VisibilityOff } from "@mui/icons-material";
import { DEFAULT_KEYS, ROW_TYPE } from "../defaultValues";
import SpreadSheetTable from "../SpreadSheetTable";
import { getRowClass, getRowStyle } from "../SpreadSheet";
import { useDispatch } from "react-redux";
import { getEntity } from "../../../../redux/ClientSpace/ClientSpaceSlice";
import {
  setRefreshing,
  setSpreadsheetDataReference,
  updateSpreadsheetKey,
} from "../../../../redux/SpreadSheet/SpreadSheetSlice";
import _ from "lodash";
import dayjs from "dayjs";

import {
  getTimingRange,
  handleReferenceUpdate,
  monthName,
  tabType,
} from "../helper";
import ConfirmModal from "../../../../components/CustomModal/ConfirmModal";
import CustomModal from "../../../../components/CustomModal/CustomModal";
import { ColorPalette } from "../../../../constants/colorPalette";
import moment from "moment";
import { TabContext } from "@mui/lab";
import { ButtonComponent } from "../../../../components/Button/Button";
var ObjectID = require("bson-objectid");

export default function Cash({
  cashData,
  spreadSheetId,
  accountingFirmId,
  entityId,
  durationStart,
  durationEnd,
  refreshData,
  setTabSwitch,
  spreadSheet,
  durationData,
  currentEntity,
  handleSettings,
  settingsOpen,
}) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      getEntity({
        entityId,
        accountingFirmId,
      })
    );
    // eslint-disable-next-line
  }, []);
  const [reload, setReload] = useState(false);

  const [openAddAccountModal, setOpenAddAccountModal] = useState(false);
  const [addAccountNameData, setAddAccountNameData] = useState({});
  // const { currentEntity } = useSelector((state) => state.clientSpaces);
  const [sideNavOpen, setSideNavOpen] = useState(false);
  const [editId, setEditId] = useState(null);
  const [sideNavData, setSideNavData] = useState(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [uniqueNameModal, setUniqueNameModal] = useState(false);
  const [resetManualKeys, setResetManualKeys] = useState([]);
  const [selectedResetKeys, setSelectedResetKeys] = useState([]);
  const [resetManualModalOpen, setResetManualModalOpen] = useState(false);

  const [spreadsheetData, setSpreadsheetData] = useState({
    rows: [],
    columns: [],
    headers: {},
    unCollapsedRows: [],
    value: [],
  });

  useEffect(() => {
    setReload(!reload);
    // eslint-disable-next-line
  }, [spreadsheetData]);

  const handleCellChange = (val) => {
    if (val[0].type === "collapsible") {
      let index = spreadsheetData.unCollapsedRows.indexOf(val[0].newCell.id);
      if (index >= 0) {
        let data = _.cloneDeep(spreadsheetData.unCollapsedRows);
        data.splice(index, 1);
        setSpreadsheetData({
          ...spreadsheetData,
          unCollapsedRows: data,
          rows: getRows(spreadsheetData.value, spreadsheetData.headers, data),
        });
        handleUpdate(
          cashData,
          spreadsheetData.value,
          spreadsheetData.columns,
          spreadsheetData.headers,
          data,
          false,
          true
        );
      } else {
        let data = [...spreadsheetData.unCollapsedRows, val[0].newCell.id];
        setSpreadsheetData({
          ...spreadsheetData,
          unCollapsedRows: data,
          rows: getRows(spreadsheetData.value, spreadsheetData.headers, data),
        });
        handleUpdate(
          cashData,
          spreadsheetData.value,
          spreadsheetData.columns,
          spreadsheetData.headers,
          data,
          false,
          true
        );
      }
    } else {
      let row = spreadsheetData?.rows.find((row) => row.rowId === val[0].rowId);
      let clone = _.cloneDeep(spreadsheetData?.value);
      let index = spreadsheetData?.value
        .map((value) => value.id)
        .indexOf(row.dataId);
      clone[index][val[0].columnId].value =
        isNaN(val[0].newCell.value) ||
        val[0].newCell.value === undefined ||
        typeof val[0].newCell.value !== "number"
          ? 0
          : val[0].newCell.value;
      clone[index][val[0].columnId].manualInput = true;

      let { tabData: referenceSyncedData } = handleReferenceUpdate(
        spreadSheet,
        clone,
        "cash",
        durationData,
        dispatch,
        setSpreadsheetDataReference,
        currentEntity,
        tabType.cash
      );
      setSpreadsheetData({
        ...spreadsheetData,
        rows: getRows(
          referenceSyncedData,
          spreadsheetData.headers,
          spreadsheetData.unCollapsedRows
        ),
      });
      handleUpdate(
        cashData,
        referenceSyncedData,
        spreadsheetData.columns,
        spreadsheetData.headers,
        spreadsheetData.unCollapsedRows
      );
    }
  };

  useEffect(
    () => {
      if (cashData && refreshData) {
        let updatedCashData = _.cloneDeep(cashData);
        let clone = _.cloneDeep(updatedCashData.rows);
        let rowData = getRows(
          clone,
          updatedCashData?.headers,
          updatedCashData?.unCollapsedRows
        );
        setSpreadsheetData({
          columns: updatedCashData?.columns,
          headers: updatedCashData?.headers,
          rows: rowData,
          value: clone,
          unCollapsedRows: updatedCashData?.unCollapsedRows,
        });
        setTabSwitch(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cashData]
  );

  const handleUpdate = (
    data,
    rows,
    columns,
    headers,
    unCollapsedRows,
    refreshData = true,
    disableNotification = false
  ) => {
    if (refreshData) {
      dispatch(setRefreshing());
    }
    dispatch(
      updateSpreadsheetKey({
        payload: {
          type: "cash",
          value: {
            ...data,
            rows,
            columns,
            headers,
            unCollapsedRows,
          },
          refreshData,
          entityId,
        },
        spreadSheetId,
        accountingFirmId,
        disableNotification,
      })
    );
  };

  useEffect(() => {
    if (editId) {
      setSideNavOpen(true);
      setSideNavData(spreadsheetData.value.find((val) => val.id === editId));
    }
    // eslint-disable-next-line
  }, [editId]);

  useEffect(() => {
    if (cashData) {
      let row = cashData.rows.find(
        (val) => val.metricName === "Opening Bank Balance"
      );
      let resetKeys = [];
      Object.keys(row).forEach((key) => {
        if (!DEFAULT_KEYS.includes(key) && row[key]["manualInput"]) {
          resetKeys.push(key);
        }
      });
      if (resetKeys.length === 0) {
        setResetManualModalOpen(false);
      }
      setResetManualKeys(resetKeys);
    }
  }, [resetManualModalOpen, cashData]);

  const handleColumnResize = (ci, width) => {
    const updatedColumns = spreadsheetData.columns.map((column) => {
      if (column.columnId === ci) {
        return { ...column, width };
      }
      return column;
    });
    setSpreadsheetData((prevData) => ({
      ...prevData,
      columns: updatedColumns,
    }));
    handleUpdate(
      cashData,
      spreadsheetData.value,
      updatedColumns,
      spreadsheetData.headers,
      spreadsheetData?.unCollapsedRows,
      true,
      true
    );
  };

  const handleAddNewAccountType = (id) => {
    setAddAccountNameData({ id, initialValue: 0 });
    setOpenAddAccountModal(true);
  };

  const handleEditCashItem = (id) => {
    setEditId(id);
  };

  const handleCloseSideNav = () => {
    setEditId(null);
    setSideNavOpen(false);
    setSideNavData(null);
  };

  const handleUndoManual = () => {
    setResetManualModalOpen(true);
  };

  const getRows = (value, headerRow, unCollapsedRows) => {
    let index = 0;
    let editableRows = [
      "Income tax instalment",
      "FBT tax instalment",
      "Opening Bank Balance",
      "Income tax payment/refund",
    ];
    value.forEach((val) => {
      if (val?.subParentId?.includes("other_items")) {
        editableRows.push(val?.metricName);
      }
    });
    return [
      {
        ...headerRow,
        cells: headerRow.cells.map((val) => {
          return {
            ...val,
            style: moment(val.text).isValid()
              ? { justifyContent: "flex-end", fontWeight: "600" }
              : { fontWeight: "600" },
          };
        }),
      },
      ...value
        .filter(
          (data) =>
            data?.visible !== false &&
            (data.rowType === ROW_TYPE.CUSTOM_METRIC ||
            data?.rowType === ROW_TYPE.TOTAL_ROW ||
            data?.rowType === ROW_TYPE.TITLE_ROW ||
            data?.rowType === ROW_TYPE.CUSTOM_METRIC
              ? true
              : unCollapsedRows?.includes(data?.parentId) ||
                (unCollapsedRows?.includes(data.subParentId) &&
                  (data?.groupParentId
                    ? unCollapsedRows?.includes(data?.groupParentId) &&
                      unCollapsedRows?.includes(data?.subParentId)
                    : true)))
        )
        .map((row, idx) => {
          let data = _.cloneDeep(row);
          if (
            (row.rowType === ROW_TYPE.ACCOUNT_GROUP ||
              row.rowType === ROW_TYPE.CUSTOM_METRIC) &&
            !unCollapsedRows.includes(row.id)
          ) {
            let totalRow = _.cloneDeep(value).find((val) =>
              row.rowType === ROW_TYPE.CUSTOM_METRIC
                ? val.subParentId === row.id &&
                  !val.groupParentId &&
                  val.rowType === ROW_TYPE.SUB_TOTAL_ROW
                : val.groupParentId === row.id &&
                  val.rowType === ROW_TYPE.SUB_TOTAL_ROW
            );
            if (totalRow) {
              Object.keys(row).forEach((key) => {
                if (DEFAULT_KEYS.includes(key)) {
                  totalRow[key] = row[key];
                }
              });
              data = totalRow;
            }
          }
          index++;
          let style = getRowStyle(
            data.rowType,
            data.rowType === ROW_TYPE.METRIC
              ? data.metricType === "Input"
              : data.rowType === ROW_TYPE.ACCOUNT_GROUP
              ? unCollapsedRows.includes(data.id)
                ? true
                : false
              : data.rowType === ROW_TYPE.TOTAL_ROW &&
                data.metricName === "GST on Income"
              ? false
              : data.rowType === ROW_TYPE.ACCOUNT
              ? false
              : true
          );
          let className = getRowClass(data.rowType, true);
          let rowData = {
            rowId: idx,
            height: 40,
            dataId: value.find((row) => row.id === data.id).id,
            cells: [
              {
                type: "text",
                text: `${index}`,
                nonEditable: true,
                style: {
                  paddingLeft: "12px",
                  ...style,
                  fontSize: "14px",
                  fontWeight: "600",
                },
              },
              {
                type: "collapsible",
                text: data?.metricName.toString(),
                isCustomMetric: data.rowType === ROW_TYPE.CUSTOM_METRIC,
                hasChildren:
                  value.filter((val) => val?.parentId === data.id).length !==
                    0 ||
                  value.filter((val) => val?.subParentId === data.id).length !==
                    0 ||
                  value.filter((val) => val?.metricParentId === data.id)
                    .length !== 0 ||
                  value.filter((val) => val?.groupParentId === data.id)
                    .length !== 0,
                columnType: "cash",
                isExpanded: unCollapsedRows?.indexOf(data?.id) !== -1,
                totalRow: data.rowType === ROW_TYPE.TOTAL_ROW,
                subTotalRow: data.rowType === ROW_TYPE.SUB_TOTAL_ROW,
                titleRow: data.rowType === ROW_TYPE.TITLE_ROW,
                rowType: data.rowType,
                metricType: data.metricType,
                metricName: data.metricName,
                showAdd:
                  data?.metricName === "Cash Inflows" ||
                  data?.metricName === "Cash Outflows",
                showEdit:
                  data.rowType === ROW_TYPE.ACCOUNT &&
                  data?.subParentId?.includes("other_items"),
                id: data.id,
                style,
                className,
                parentId: data.parentId,
                subParentId: data.subParentId,
                groupParentId: data.groupParentId,
                metricParentId: data.metricParentId,
                handleAddItem: (id) => handleAddNewAccountType(id),
                handleUndoManual: handleUndoManual,
                handleEditItem: (id) => handleEditCashItem(id),
              },
            ],
          };
          let dateColumns = cashData?.columns?.reduce((acc, column) => {
            if (!DEFAULT_KEYS.includes(column.columnId)) {
              acc.push(column?.columnId);
            }
            return acc;
          }, []);
          dateColumns.forEach((date) => {
            rowData.cells.push({
              type: "number",
              value: Math.round(data[date]?.value) || 0,
              // parseFloat(data[date]?.value) < 0
              //   ? `(${Math.round(
              //       Math.abs(data[date]?.value)
              //     ).toLocaleString()})`
              //   :
              nonEditable:
                !editableRows.includes(data?.metricName) ||
                data.rowType === ROW_TYPE.SUB_TOTAL_ROW,
              style: {
                ...style,
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                background: data[date]?.manualInput
                  ? data[date]["value"] === 0 &&
                    row.metricName !== "Opening Bank Balance"
                    ? "#FFFFFF"
                    : "#B4D4FF"
                  : style.background,
              },
            });
          });
          return rowData;
        }),
    ].filter((row) => row !== null);
  };

  const handleModalClose = () => {
    setAddAccountNameData({});
    setOpenAddAccountModal(false);
  };

  const handleAddNewAccountName = () => {
    const clone = _.cloneDeep(spreadsheetData?.value);
    const { id, name, initialValue } = addAccountNameData;
    if (clone.find((val) => val.metricName.trim() === name.trim())) {
      setUniqueNameModal(true);
      return;
    }
    const accountGroup = clone.find(
      (row) => row?.metricName === "Other Items" && row?.parentId === id
    );
    const allMonths = getTimingRange(
      dayjs(durationStart).format("YYYY-MM"),
      dayjs(durationEnd).format("YYYY-MM")
    );
    if (!accountGroup) {
      let otherItemsId =
        id + "_" + "Other Items".toLowerCase().replace(/\s+/g, "_");
      const otherItemsAccountGroup = {
        metricName: "Other Items",
        id: otherItemsId,
        parentId: id,
        subParentId: "cash_null",
        groupParentId: "",
        rowType: ROW_TYPE.CUSTOM_METRIC,
      };

      allMonths.forEach((month) => {
        otherItemsAccountGroup[month] = {
          value: "0",
          disabled: true,
          manualInput: false,
        };
      });
      let lastIndex = -1;
      for (let i = clone.length - 1; i >= 0; i--) {
        if (clone[i]?.parentId === id) {
          lastIndex = i + (id.includes("inflows") ? 1 : 0);
          break;
        }
      }
      clone.splice(
        lastIndex === -1 ? clone.length : lastIndex,
        0,
        otherItemsAccountGroup
      );
    }
    const otherItemsGroup = clone.find(
      (row) => row?.metricName === "Other Items" && row?.parentId === id
    );
    const newAccountName = {
      metricName: name,
      id: new ObjectID().toString(),
      subParentId: otherItemsGroup?.id,
      parentId: id,
      rowType: ROW_TYPE.ACCOUNT,
      visible: true,
      initialValue,
    };
    allMonths.forEach((month) => {
      newAccountName[month] = {
        value: initialValue,
        disabled: false,
        manualInput: false,
      };
    });
    let lastIndex = -1;
    for (let i = clone.length - 1; i >= 0; i--) {
      if (
        clone[i]?.subParentId === otherItemsGroup?.id &&
        clone[i].rowType !== ROW_TYPE.SUB_TOTAL_ROW
      ) {
        lastIndex = i + 1;
        break;
      }
    }
    if (lastIndex === -1) {
      lastIndex =
        clone.findIndex((item) => item?.id === otherItemsGroup?.id) + 1;
    }
    clone.splice(lastIndex, 0, newAccountName);
    let { tabData: referenceSyncedData } = handleReferenceUpdate(
      spreadSheet,
      clone,
      "cash",
      durationData,
      dispatch,
      setSpreadsheetDataReference,
      currentEntity,
      tabType.cash
    );
    setSpreadsheetData({
      ...spreadsheetData,
      value: referenceSyncedData,
      rows: getRows(
        referenceSyncedData,
        spreadsheetData.headers,
        spreadsheetData.unCollapsedRows
      ),
    });
    handleUpdate(
      cashData,
      referenceSyncedData,
      spreadsheetData.columns,
      spreadsheetData.headers,
      spreadsheetData.unCollapsedRows
    );

    handleModalClose();
  };

  const handleDeleteItem = () => {
    let clone = _.cloneDeep(spreadsheetData.value);
    let index = clone.findIndex((val) => val.id === sideNavData.id);
    clone.splice(index, 1);
    let { tabData: referenceSyncedData } = handleReferenceUpdate(
      spreadSheet,
      clone,
      "cash",
      durationData,
      dispatch,
      setSpreadsheetDataReference,
      currentEntity,
      tabType.cash
    );
    setSpreadsheetData({
      ...spreadsheetData,
      value: referenceSyncedData,
      rows: getRows(
        referenceSyncedData,
        spreadsheetData.headers,
        spreadsheetData.unCollapsedRows
      ),
    });
    handleUpdate(
      cashData,
      referenceSyncedData,
      spreadsheetData.columns,
      spreadsheetData.headers,
      spreadsheetData.unCollapsedRows
    );
  };

  const handleSideNavChange = (key, value) => {
    let updatedData = _.cloneDeep(sideNavData);
    updatedData[key] = value;
    setSideNavData(updatedData);
  };

  const handleSideNavSave = () => {
    let clone = _.cloneDeep(spreadsheetData.value);
    let currentIndex = clone.findIndex((val) => val.id === sideNavData.id);
    clone[currentIndex] = sideNavData;
    let { tabData: referenceSyncedData } = handleReferenceUpdate(
      spreadSheet,
      clone,
      "cash",
      durationData,
      dispatch,
      setSpreadsheetDataReference,
      currentEntity,
      tabType.cash
    );
    setSpreadsheetData({
      ...spreadsheetData,
      rows: getRows(
        referenceSyncedData,
        spreadsheetData.headers,
        spreadsheetData.unCollapsedRows
      ),
    });
    handleUpdate(
      cashData,
      referenceSyncedData,
      spreadsheetData.columns,
      spreadsheetData.headers,
      spreadsheetData.unCollapsedRows
    );
    handleCloseSideNav();
  };

  const handleModalCloseResetManual = () => {
    setResetManualModalOpen(false);
    setSelectedResetKeys([]);
    setResetManualKeys([]);
  };

  const resetManualInputs = () => {
    let clone = _.cloneDeep(spreadsheetData.value);
    Object.keys(clone[0]).forEach((key) => {
      if (!DEFAULT_KEYS.includes(key) && selectedResetKeys.includes(key)) {
        clone[0][key]["manualInput"] = false;
        clone[0][key]["value"] = 0;
      }
    });
    let { tabData: referenceSyncedData } = handleReferenceUpdate(
      spreadSheet,
      clone,
      "cash",
      durationData,
      dispatch,
      setSpreadsheetDataReference,
      currentEntity,
      tabType.cash
    );
    setSpreadsheetData({
      ...spreadsheetData,
      rows: getRows(
        referenceSyncedData,
        spreadsheetData.headers,
        spreadsheetData.unCollapsedRows
      ),
    });
    handleUpdate(
      cashData,
      referenceSyncedData,
      spreadsheetData.columns,
      spreadsheetData.headers,
      spreadsheetData.unCollapsedRows
    );
    handleModalCloseResetManual();
  };
  const handleSelectResetKey = (key) => {
    let clone = _.cloneDeep(selectedResetKeys);
    if (clone.includes(key)) {
      let index = clone.findIndex((val) => val === key);
      clone.splice(index, 1);
      setSelectedResetKeys(clone);
    } else {
      setSelectedResetKeys([...selectedResetKeys, key]);
    }
  };
  const getNameFormKey = (key) => {
    let date = moment(key);
    return monthName[date.month() + 1] + " " + date.year();
  };

  return (
    <div>
      <CustomModal
        modalOpen={openAddAccountModal}
        setModalOpen={handleModalClose}
        onClose={handleModalClose}
        overflow={"auto"}
        maxWidth={"md"}
      >
        <Grid container>
          <Grid
            item
            xs={12}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Typography fontSize={20} fontWeight={"bold"}>
              Add New Account
            </Typography>

            <IconButton onClick={handleModalClose}>
              <Close />
            </IconButton>
          </Grid>
          <Divider sx={{ width: "100%", color: ColorPalette.danger }} />
        </Grid>
        <Grid container p={2}>
          <Grid item xs={12}>
            <InputLabel
              sx={{ fontWeight: "bold" }}
              className="font-label mt-10 mb-10"
            >
              Account Name
            </InputLabel>
            <TextField
              fullWidth
              size="small"
              value={addAccountNameData.name}
              onChange={(e) => {
                setAddAccountNameData((prev) => ({
                  ...prev,
                  name: e.target.value,
                }));
              }}
            ></TextField>
          </Grid>

          <Grid item xs={12} mt={2}>
            <InputLabel
              sx={{ fontWeight: "bold", display: "flex" }}
              className="font-label mb-10"
            >
              <p style={{ margin: 0 }} className="mr-5">
                Monthly Value
              </p>
              <Tooltip
                placement="right"
                title={
                  <p style={{ fontSize: 16 }}>
                    Leave as 0 if entering manually each month
                  </p>
                }
              >
                <Info fontSize="small" />
              </Tooltip>
            </InputLabel>

            <TextField
              fullWidth
              type="number"
              size="small"
              value={parseFloat(addAccountNameData.initialValue).toString()}
              onKeyDown={(event) => {
                if (
                  event?.key === "e" ||
                  event?.key === "+" ||
                  event?.key === "-"
                ) {
                  event.preventDefault();
                }
              }}
              onChange={({ target }) => {
                if (
                  parseFloat(parseFloat(target.value || 0).toFixed(3)) <
                  1000000000000
                ) {
                  setAddAccountNameData((prev) => ({
                    ...prev,
                    initialValue: parseFloat(
                      parseFloat(target.value || 0).toFixed(3)
                    ),
                  }));
                }
              }}
            ></TextField>
          </Grid>
          <Grid container mt={3} justifyContent={"flex-end"}>
            <ButtonComponent
              onClick={handleModalClose}
              className="ml-10"
              title={"Cancel"}
            />

            <ButtonComponent
              disabled={
                !addAccountNameData?.name ||
                /^\s*$/.test(addAccountNameData.name) ||
                addAccountNameData.initialValue === null
              }
              onClick={handleAddNewAccountName}
              contained
              className="ml-10"
              title={"Add"}
            />
          </Grid>
        </Grid>
      </CustomModal>
      <CustomModal
        modalOpen={resetManualModalOpen && resetManualKeys.length !== 0}
        setModalOpen={setResetManualModalOpen}
        onClose={handleModalCloseResetManual}
        overflow={"auto"}
        maxWidth={"md"}
      >
        <Grid container>
          <Grid
            item
            xs={12}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Typography fontSize={20} fontWeight={"bold"}>
              Reset Manual Inputs
            </Typography>

            <IconButton onClick={handleModalCloseResetManual}>
              <Close />
            </IconButton>
          </Grid>
          <Divider sx={{ width: "100%", color: ColorPalette.danger }} />
        </Grid>
        <Grid container justifyContent={"right"} className="mt-10">
          <Grid item xs={6}>
            <Grid container justifyContent={"right"}>
              <Button
                variant="outlined"
                className="mr-10"
                onClick={() => {
                  setSelectedResetKeys(_.cloneDeep(resetManualKeys));
                }}
              >
                Select All
              </Button>
              <Button
                variant="outlined"
                onClick={() => {
                  setSelectedResetKeys([]);
                }}
              >
                Deselect All
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          sx={{ maxHeight: "300px", overflow: "auto" }}
          className="mt-20"
        >
          {resetManualKeys.map((key) => {
            return (
              <Grid item xs={3}>
                <FormControlLabel
                  key={key}
                  label={getNameFormKey(key)}
                  control={
                    <Checkbox
                      name={key}
                      checked={selectedResetKeys?.includes(key)}
                      onChange={() => handleSelectResetKey(key)}
                    ></Checkbox>
                  }
                ></FormControlLabel>
              </Grid>
            );
          })}
        </Grid>
        <Grid container p={2}>
          <Grid container mt={3} justifyContent={"flex-end"}>
            <Button
              onClick={handleModalCloseResetManual}
              variant="contained"
              color="inherit"
              className="ml-10"
            >
              Cancel
            </Button>
            <Button
              disabled={selectedResetKeys.length === 0}
              onClick={resetManualInputs}
              variant="contained"
              color="primary"
              className="ml-10"
            >
              Reset
            </Button>
          </Grid>
        </Grid>
      </CustomModal>
      <ConfirmModal
        modalOpen={deleteModalOpen}
        message={
          "Are you sure you want to delete the item? This change is irreversible!"
        }
        confirmText={"Yes"}
        declineText={"No"}
        setModalOpen={setDeleteModalOpen}
        modalClose={() => {
          setDeleteModalOpen(false);
        }}
        confirmFunction={() => {
          handleDeleteItem();
          setDeleteModalOpen(false);
          setSideNavOpen(false);
          setSideNavData(null);
        }}
        declineFunction={() => {
          setDeleteModalOpen(false);
        }}
      />
      <ConfirmModal
        modalOpen={uniqueNameModal}
        message={"Item names must be unique"}
        confirmText={"Ok"}
        singleButton={true}
        setModalOpen={setUniqueNameModal}
        modalClose={() => {
          setUniqueNameModal(false);
        }}
        confirmFunction={() => {
          setUniqueNameModal(false);
        }}
      />
      <Drawer anchor={"right"} open={sideNavOpen} onClose={handleCloseSideNav}>
        <TabContext value={0}>
          <Box sx={{ width: "600px", paddingTop: "75px" }}>
            <Grid container className="p-10">
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={10}>
                    <Typography variant="h5" className="font-bold">
                      Edit {sideNavData?.metricName}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Grid container justifyContent={"flex-end"}>
                      <IconButton onClick={handleCloseSideNav}>
                        <Close />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} className="p-10">
                <Grid container justifyContent={"flex-end"}>
                  <Button
                    onClick={() => setDeleteModalOpen(true)}
                    variant="outlined"
                    color="error"
                  >
                    Delete
                  </Button>
                </Grid>
              </Grid>
              <Grid item xs={12} className="p-10">
                <InputLabel className="font-label">Account Name</InputLabel>
                <TextField
                  fullWidth
                  className="mt-5"
                  size="small"
                  value={sideNavData?.metricName}
                  onChange={({ target }) => {
                    handleSideNavChange("metricName", target.value);
                  }}
                ></TextField>
              </Grid>
              <Grid item xs={12} className="p-10">
                <InputLabel className="font-label mt-10">
                  Monthly Value
                </InputLabel>
                <TextField
                  fullWidth
                  type="number"
                  className="mt-5"
                  size="small"
                  value={parseFloat(sideNavData?.initialValue).toString()}
                  onKeyDown={(event) => {
                    if (
                      event?.key === "e" ||
                      event?.key === "+" ||
                      event?.key === "-"
                    ) {
                      event.preventDefault();
                    }
                  }}
                  onChange={({ target }) => {
                    if (
                      parseFloat(parseFloat(target.value || 0).toFixed(3)) <
                      1000000000000
                    ) {
                      handleSideNavChange(
                        "initialValue",
                        parseFloat(parseFloat(target.value || 0).toFixed(3))
                      );
                    }
                  }}
                ></TextField>
              </Grid>

              <Grid item xs={12} className="mt-20 ">
                <Grid container justifyContent={"flex-end"}>
                  <ButtonComponent
                    onClick={handleCloseSideNav}
                    title={"Cancel"}
                  />

                  <ButtonComponent
                    disabled={
                      !sideNavData?.metricName ||
                      /^\s*$/.test(sideNavData.metricName) ||
                      sideNavData.initialValue === null
                    }
                    onClick={() => {
                      handleSideNavSave();
                    }}
                    contained
                    className="ml-10"
                    title={"Save"}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </TabContext>
      </Drawer>
      <Grid container className="mb-10">
        <Grid container>
          <Grid item xs={6}>
            <Typography color={"#3A3A3A"} variant="body1" fontWeight={"bold"}>
              Cash Details
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Grid container justifyContent={"flex-end"}>
              <ButtonComponent
                className={"mr-10"}
                onClick={handleSettings}
                startIcon={
                  settingsOpen ? (
                    <VisibilityOff className="mr-10" />
                  ) : (
                    <Visibility className="mr-10" />
                  )
                }
                title="Duration Filter"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {spreadsheetData?.columns?.length !== 0 &&
        spreadsheetData?.headers !== null &&
        spreadsheetData?.value?.length > 0 && (
          <SpreadSheetTable
            rows={_.cloneDeep(spreadsheetData.rows) || []}
            columns={spreadsheetData.columns || []}
            handleCellChange={handleCellChange}
            handleColumnResize={handleColumnResize}
            type="cash"
          />
        )}
    </div>
  );
}

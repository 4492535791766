import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomModal from "../../../../components/CustomModal/CustomModal";
import {
  addClientSpace,
  getUsersJoinedByInvitation,
} from "../../../../redux/ClientSpace/ClientSpaceSlice";
import { toTitleCase } from "../../../../helper/titleCaseConverter";
import { v4 as uuidv4 } from "uuid";

import {
  Box,
  Chip,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from "@mui/material";
import { ColorPalette } from "../../../../constants/colorPalette";
import { Formik } from "formik";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { ButtonComponent } from "../../../../components/Button/Button";
import { DeleteOutline } from "@mui/icons-material";

const AddClientSpaceModal = ({
  modalOpen,
  setModalOpen,
  accountingFirmId,
  rows,
}) => {
  const dispatch = useDispatch();
  const users = useSelector(
    (state) => state.clientSpaces.membersJoinedByInvite
  );

  const [activeStep, setActiveStep] = useState(0);
  const steps = ["Add Client Space and Manager", "Entity Name and Type"];
  const entityTypes = ["COMPANY", "TRUST", "SOLE TRADER", "PARTNERSHIP"];
  const [accountants, setAccountants] = useState([]);
  const [entities, setEntities] = useState([
    {
      id: uuidv4(),
      entityName: "",
      entityType: "",
      error: {
        name: null,
        type: null,
      },
    },
  ]);

  const [hasEmptyEntity, setHasEmptyEntity] = useState(true);

  useEffect(() => {
    dispatch(getUsersJoinedByInvitation({ accountingFirmId }));
  }, [accountingFirmId, dispatch]);

  useEffect(() => {
    setHasEmptyEntity(
      entities.some(
        ({ entityName, entityType }) => !entityName.trim() || !entityType.trim()
      )
    );
  }, [entities, hasEmptyEntity]);

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleAddAnother = () => {
    setEntities([
      ...entities,
      {
        id: uuidv4(),
        entityName: "",
        entityType: "",
        error: { name: null, type: null },
      },
    ]);
  };

  const handleDelete = (id) => {
    const updatedEntities = entities.filter((entity) => entity.id !== id);
    setEntities(updatedEntities);
  };

  const handleAddEntityChange = (id, fieldName, fieldValue) => {
    const updatedEntities = entities.map((entity) => {
      if (entity.id === id) {
        return {
          ...entity,
          [fieldName]: fieldValue,
          error: {
            ...entity.error,
            [fieldName === "entityName" ? "name" : "type"]: null,
          },
        };
      }
      return entity;
    });
    setEntities(updatedEntities);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleSubmit = (data) => {
    let hasError = false;
    const payload = {
      clientSpaceName: data.spaceName,
      clientSpaceMembers: [
        {
          user: data.manager,
          role: "MANAGER",
        },
        ...accountants.map((acc) => ({
          user: acc._id,
          role: "ACCOUNTANT",
        })),
      ],
      clientSpaceEntities: entities,
    };

    //check for empty fields
    const updatedEntities = entities.map((entity) => {
      const error = { name: null, type: null };

      if (!entity.entityName.trim()) {
        error.name = "Entity name is empty";
        hasError = true;
      }
      if (!entity.entityType.trim()) {
        error.type = "Entity type is empty";
        hasError = true;
      }
      return { ...entity, error };
    });

    //check for duplicate names
    const entityNames = updatedEntities.map((entity) =>
      entity.entityName.trim().toLocaleLowerCase()
    );
    const duplicates = entityNames.filter(
      (name, index) => entityNames.indexOf(name) !== index
    );
    if (duplicates.length > 0) {
      updatedEntities.forEach((entity) => {
        if (duplicates.includes(entity.entityName.trim().toLocaleLowerCase())) {
          entity.error.name = "Duplicate entity name";
          hasError = true;
        }
      });
    }

    setEntities(updatedEntities);

    if (hasError) {
      return;
    }

    handleModalClose();
    dispatch(addClientSpace({ payload, accountingFirmId }));
    setModalOpen(false);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setActiveStep(0);
    setEntities([{ entityName: "", entityType: "" }]);
    setAccountants([]);
  };

  const handleAccountChange = (e, form) => {
    const selectedIds = e.target.value;
    const selectedAccounts = users.filter((user) =>
      selectedIds.includes(user._id)
    );

    // Set accountants
    setAccountants(selectedAccounts);

    // Manually mark the accountant field as touched and validate
    form.setFieldTouched("accountant", true, true);
    form.validateField("accountant");
  };

  const handleChipDelete = (id) => {
    const updatedAccountants = accountants.filter((acc) => acc._id !== id);
    setAccountants(updatedAccountants);
  };

  return (
    <div>
      <CustomModal
        onClose={handleModalClose}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        overflow="hidden"
      >
        <Grid container flexDirection="column">
          <Grid
            container
            justifyContent={"space-between"}
            alignItems={"center"}
            mb={2}
          >
            <Typography fontSize={18} fontWeight="bold">
              {activeStep === 0 ? "Add Client Space" : "Entity Details"}
            </Typography>
            <IconButton
              id="button-addGroupCloseIcon"
              style={{ borderRadius: 0 }}
              size="small"
              onClick={() => {
                handleModalClose();
              }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
          <Divider sx={{ width: "100%", color: ColorPalette.danger }} />
          <Formik
            initialValues={{
              spaceName: "",
              manager: "",
              accountant: [],
            }}
            validate={(values) => {
              const errors = {};

              if (!values.spaceName) {
                errors.isSpaceNameEmpty = true;
                errors.spaceName = "Please enter clientspace name";
              } else if (/[!@#$%^&*]/g.test(values.spaceName)) {
                errors.spaceName = "Special characters not allowed";
              }
              if (
                rows.some(
                  (row) =>
                    row.clientSpaceName.trim() === values.spaceName.trim()
                )
              ) {
                errors.spaceName =
                  "This client name has already been used. Please use different name.";
              }

              if (!values.manager) {
                errors.isManagerEmpty = true;
                errors.manager = "Please select manager for client space.";
              }

              // Validate accountants field
              // if (accountants.length === 0) {
              //   errors.accountant = "Please select at least one accountant";
              // }

              return errors;
            }}
            onSubmit={(data, { setSubmitting }) => {
              if (activeStep === steps.length - 1) {
                handleSubmit(data, setSubmitting);
              } else {
                handleNext();
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit,
              setTouched,
            }) => (
              <form onSubmit={handleSubmit}>
                <Grid
                  item
                  xs={12}
                  style={{
                    overflowY: "auto",
                    overflowX: "auto",
                    width: "100%",
                  }}
                >
                  <Grid p={2} container>
                    <Grid item xs={12} marginTop={2}>
                      {activeStep === 0 ? (
                        <Grid justifyContent="center" container spacing={2}>
                          <Grid item xs={12} marginBottom={2}>
                            <Typography
                              id="text-addGroupName"
                              fontSize={16}
                              className="mb-10"
                            >
                              Name
                            </Typography>
                            <TextField
                              id="input-spaceName"
                              fullWidth
                              variant="outlined"
                              size="small"
                              name="spaceName"
                              value={values.spaceName}
                              error={!!errors.spaceName && !!touched.spaceName}
                              onChange={handleChange}
                            />
                            <Typography
                              id="error-spaceRequiredField"
                              variant="caption"
                              color={ColorPalette.danger}
                              pl={1}
                            >
                              {!!errors.spaceName &&
                                !!touched.spaceName &&
                                errors.spaceName}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} marginBottom={1}>
                            <Typography
                              id="text-addGroupManager"
                              fontSize={16}
                              color={ColorPalette.black}
                              className="mb-10"
                            >
                              Manager
                            </Typography>
                            <Grid container spacing={2}>
                              <Grid item xs={12}>
                                <Select
                                  fullWidth
                                  size="small"
                                  labelId="manager-user"
                                  id="input-spaceManager"
                                  name="manager"
                                  value={values.manager}
                                  onChange={handleChange}
                                  error={!!errors.manager && !!touched.manager}
                                >
                                  {users
                                    ?.filter(
                                      (user) =>
                                        !accountants.some(
                                          (acc) => acc._id === user._id
                                        )
                                    )
                                    ?.map((user, index) => {
                                      return (
                                        <MenuItem key={index} value={user._id}>
                                          {user.first_name +
                                            " " +
                                            user.last_name}
                                          <span
                                            style={{
                                              marginLeft: "5px",
                                              fontSize: "15px",
                                              color: ColorPalette.gray,
                                            }}
                                          >
                                            {user.email}
                                          </span>
                                        </MenuItem>
                                      );
                                    })}
                                </Select>
                                {!!errors.manager && !!touched.manager && (
                                  <div
                                    id="error-spaceRequiredField"
                                    style={{
                                      fontSize: 12,
                                      color: ColorPalette.danger,
                                      marginLeft: "15px",
                                      paddingTop: "3px",
                                    }}
                                  >
                                    {errors.manager}
                                  </div>
                                )}
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography
                              id="text-addGroupManager"
                              fontSize={16}
                              color={ColorPalette.black}
                              className="mb-10"
                            >
                              Accountants
                            </Typography>
                            <Grid container spacing={2}>
                              <Grid item xs={12}>
                                <Select
                                  multiple
                                  fullWidth
                                  size="small"
                                  labelId="accountant-user"
                                  id="input-spaceAccountant"
                                  name="accountant"
                                  value={accountants.map((acc) => acc._id)}
                                  onChange={(event) =>
                                    handleAccountChange(event, {
                                      setFieldTouched: setTouched,
                                      validateField: () => {},
                                    })
                                  }
                                  error={
                                    errors.accountant && touched.accountant
                                  }
                                  renderValue={(selected) => (
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexWrap: "wrap",
                                        gap: 0.5,
                                      }}
                                    >
                                      {selected.map((value) => {
                                        const selectedName = users.find(
                                          (user) => value === user._id
                                        );
                                        return (
                                          <Chip
                                            key={value}
                                            label={`${selectedName.first_name} ${selectedName.last_name}`}
                                            sx={{
                                              backgroundColor: "#e0e0e0",
                                            }}
                                            onMouseDown={(event) => {
                                              event.stopPropagation();
                                            }}
                                            onDelete={() =>
                                              handleChipDelete(value)
                                            }
                                          />
                                        );
                                      })}
                                    </Box>
                                  )}
                                >
                                  {users
                                    ?.filter(
                                      (user) =>
                                        !accountants.some(
                                          (acc) => acc._id === user._id
                                        ) && user._id !== values.manager
                                    )
                                    ?.map((user, index) => {
                                      return (
                                        <MenuItem key={index} value={user._id}>
                                          {user.first_name +
                                            " " +
                                            user.last_name}
                                          <span
                                            style={{
                                              marginLeft: "5px",
                                              fontSize: "15px",
                                              color: ColorPalette.gray,
                                            }}
                                          >
                                            {user.email}
                                          </span>
                                        </MenuItem>
                                      );
                                    })}
                                </Select>
                                {errors.accountant && touched.accountant && (
                                  <div
                                    id="error-spaceRequiredField"
                                    style={{
                                      fontSize: 12,
                                      color: ColorPalette.danger,
                                      marginLeft: "15px",
                                      paddingTop: "3px",
                                    }}
                                  >
                                    {errors.accountant}
                                  </div>
                                )}
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      ) : (
                        <Grid px={2}>
                          <Grid
                            style={{
                              maxHeight: "20vh",
                              overflowY: "auto",
                            }}
                          >
                            {entities.map((entity, index) => (
                              <Grid
                                display="flex"
                                container
                                spacing={2}
                                alignItems="flex-start"
                                justifyContent="space-between"
                                mt={0.5}
                              >
                                {/* Entity Name */}
                                <Grid item xs={5.5}>
                                  <Typography
                                    id="text-addEntity"
                                    className="mb-10"
                                    color={ColorPalette.black}
                                  >
                                    Entity
                                  </Typography>
                                  <TextField
                                    size="small"
                                    fullWidth
                                    id="input-entityName"
                                    name="entityName"
                                    error={
                                      entity.error.name && !!entity.error.name
                                    }
                                    variant="outlined"
                                    value={entity?.entityName}
                                    onChange={(e) => {
                                      handleAddEntityChange(
                                        entity.id,
                                        "entityName",
                                        e.target.value
                                      );
                                    }}
                                  />
                                  {entity.error.name && (
                                    <Typography
                                      color={"error"}
                                      fontSize={"12px"}
                                      my={1}
                                    >
                                      {entity.error.name}
                                    </Typography>
                                  )}
                                </Grid>

                                {/* Entity Type */}
                                <Grid
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                  item
                                  xs={5.5}
                                >
                                  <Grid items xs={12}>
                                    <Typography
                                      id="text-addEntityType"
                                      color={ColorPalette.black}
                                      className="mb-10"
                                    >
                                      Type
                                    </Typography>
                                    <Select
                                      size="small"
                                      labelId="entity-type"
                                      id="input-entityType"
                                      value={entity?.entityType}
                                      name="entityType"
                                      fullWidth
                                      error={
                                        entity.error.type && !!entity.error.type
                                      }
                                      onChange={(e) => {
                                        handleAddEntityChange(
                                          entity.id,
                                          "entityType",
                                          e.target.value
                                        );
                                      }}
                                      variant="outlined"
                                    >
                                      {entityTypes.map((type, index) => (
                                        <MenuItem key={index} value={type}>
                                          {toTitleCase(type)}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                    {entity.error.type && (
                                      <Typography
                                        color={"error"}
                                        fontSize={"12px"}
                                        my={1}
                                      >
                                        {entity.error.type}
                                      </Typography>
                                    )}
                                  </Grid>

                                  {/* Delete Button */}
                                  {entities.length > 1 && (
                                    <IconButton
                                      id="button-deleteEntityDetails"
                                      style={{
                                        marginTop: "auto",
                                        color: ColorPalette.danger,
                                      }}
                                      size="mediium"
                                      onClick={() => handleDelete(entity.id)}
                                    >
                                      <DeleteOutline />
                                    </IconButton>
                                  )}
                                </Grid>
                              </Grid>
                            ))}
                          </Grid>

                          <ButtonComponent
                            startIcon={<AddIcon />}
                            styles={{ marginTop: "20px" }}
                            title={"Add Another"}
                            onClick={handleAddAnother}
                            disabled={hasEmptyEntity}
                          />
                        </Grid>
                      )}
                    </Grid>
                    <Grid
                      container
                      sx={{
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Stepper
                        sx={{
                          marginTop: 5,
                          width: "70%",
                        }}
                        activeStep={activeStep}
                        alternativeLabel
                      >
                        {steps.map((label) => (
                          <Step
                            key={label}
                            sx={{
                              "& .MuiStepLabel-root .Mui-completed": {
                                color: ColorPalette.primary,
                              },
                              "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel":
                                {
                                  color: ColorPalette.black,
                                },
                              "& .MuiStepLabel-root .Mui-active": {
                                color: ColorPalette.primary,
                              },
                              "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel":
                                {
                                  color: ColorPalette.black,
                                },
                            }}
                          >
                            <StepLabel>{label}</StepLabel>
                          </Step>
                        ))}
                      </Stepper>
                      <Grid
                        container
                        sx={{ justifyContent: "space-between", mt: 3 }}
                      >
                        <ButtonComponent
                          title={"Back"}
                          onClick={handleBack}
                          disabled={activeStep === 0}
                        />
                        <ButtonComponent
                          title={
                            activeStep === steps.length - 1 ? "Finish" : "Next"
                          }
                          onClick={handleSubmit}
                          contained={true}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </Grid>
      </CustomModal>
    </div>
  );
};

export default AddClientSpaceModal;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { handleRequest } from "../../helper/utils";

export const getHistory = createAsyncThunk(
  "getHistory",
  async (
    { accountingFirmId, page, pageSize, searchQuery, pageType, filterQuery },
    thunkAPI
  ) => {
    const endpoint = `/accountingFirm/${accountingFirmId}/auditTrail/getAuditTrails/?page=${page}&pageSize=${pageSize}&pageType=${pageType}&searchQuery=${searchQuery}&filterQuery=${filterQuery}`;
    return handleRequest(endpoint, null, "getHistory", thunkAPI, null, "GET");
  }
);

const initialState = {
  history: [],
  isFetching: false,
  isSuccess: false,
  isError: false,
  successMessage: "",
  errorMessage: "",
};

export const auditTrailSlice = createSlice({
  name: "auditTrail",
  initialState,
  reducers: {
    reset: () => initialState,
    resetFlags: (state) => {
      state.isFetching = false;
      state.isError = false;
      state.isSuccess = false;
      state.successMessage = "";
      state.errorMessage = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getHistory.fulfilled, (state, { payload }) => {
        state.history = payload;
        state.isFetching = false;
        state.isSuccess = true;
        return state;
      })
      .addCase(getHistory.rejected, (state, { payload }) => {
        state.isError = true;
        state.isFetching = false;
        state.isSuccess = false;
        state.successMessage = "";
        state.errorMessage = payload || "Failed";
        return state;
      })
      .addCase(getHistory.pending, (state) => {
        state.isFetching = true;
        state.isError = false;
        state.isSuccess = false;
        state.successMessage = "";
        state.errorMessage = "";
        return state;
      });
  },
});

export default auditTrailSlice.reducer;
